<template>
	<a-modal :open="openImportFileModal" title="Import Pair(s) to Pair Group" @cancel="closeImportFileModal" width="100%" :style="{ maxWidth: '650px' }" :footer="null">
		<a-form :model="formData" :label-col="labelCol" :wrapper-col="wrapperCol" @finish="onSave">
			<a-form-item label="Add to Pair Group" name="groupName" :rules="[{ required: true, message: 'Please enter group name!' }]">
				<a-input v-model:value="formData.groupName" :style="{ width: '200px' }"></a-input>
			</a-form-item>
			<a-form-item name="file" label="Import excel pair file " :rules="[{ required: true, message: 'Please upload one excel file!' }]">
				<a-upload :file-list="fileList" @remove="handleRemove" :before-upload="beforeUpload" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel">
					<a-button v-if="fileList.length < 1">
						<UploadOutlined />
						Select File
					</a-button>
				</a-upload>
			</a-form-item>
			<div class="footerClass">
				<a-button :style="{ width: '20%' }" @click="closeImportFileModal">Cancel</a-button>
				<a-button type="primary" html-type="submit" :style="{ width: '20%', marginLeft: '10px' }">Save</a-button>
			</div>
		</a-form>
	</a-modal>
</template>

<script setup lang="ts">
	import { addPair, addGroupApi } from "@/services/api/user";
	import { openNotificationWithIcon } from "@/utils";
	import { UploadOutlined } from "@ant-design/icons-vue";
	import * as XLSX from "xlsx";
	import { reactive, ref } from "vue";

	const { openImportFileModal, closeImportFileModal, fetchData } = defineProps(["openImportFileModal", "closeImportFileModal", "fetchData"]);
	const labelCol = { style: { width: "150px" } };
	const wrapperCol = { span: 14 };

	const fileList: any = ref([]);

	const formData: any = reactive<any>({
		groupName: "",
		file: "",
	});

	const handleRemove = (file: any) => {
		const index = fileList.value.indexOf(file);
		const newFileList = fileList.value.slice();
		newFileList.splice(index, 1);
		fileList.value = newFileList;
		formData.file = "";
	};

	const beforeUpload = (file: any) => {
		fileList.value = [...fileList.value, file];
		formData.file = "file";
		return false;
	};

	const onSave = async () => {
		try {
			const groupData = await addGroupApi({ name: formData.groupName });
			formData.groupName = "";
			fetchData();
			if (groupData) {
				const selectedFile = fileList.value[0];

				const pairArray: any = [];
				const reader = new FileReader();
				reader.onload = async (e: any) => {
					const data = new Uint8Array(e.target.result);
					const workbook = XLSX.read(data, { type: "array" });

					const jsonData = {
						pairs: XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]),
					};
					for (let i = 0; i < jsonData.pairs.length; i++) {
						const element: any = jsonData.pairs[i];
						if (!element.name1 || !element.gender1 || !element.dt1 || !element.loc1 || !element.lat1 || !element.lng1 || !element.name2 || !element.gender2 || !element.dt2 || !element.loc2 || !element.lat2 || !element.lng2) {
							openNotificationWithIcon("error", `Please check this user Details ${element?.name1}, ${element?.name2}`);
							continue;
						}
						await pairArray.push({
							groupId: groupData?.data?._id,
							userA: {
								name: element.name1,
								gender: element.gender1,
								dob: element.dt1,
								pob: element.loc1,
								geoCordinate: `${element.lat1} ${element.lng1}`,
							},
							userB: {
								name: element.name2,
								gender: element.gender2,
								dob: element.dt2,
								pob: element.loc2,
								geoCordinate: `${element.lat2} ${element.lng2}`,
							},
						});
					}
					const pairRes = await addPair(pairArray);
					pairArray.length > 0 && openNotificationWithIcon("success", pairRes?.message);
					closeImportFileModal();
				};
				reader.readAsArrayBuffer(selectedFile);
			}
		} catch (error) {
			console.log(error);
		}
	};
</script>

<style scoped>
	p {
		margin: 0px;
	}

	.main-border {
		border-radius: 10px;
		border: 1px solid black;
		background-color: #f4f4f4;
		padding: 25px;
		margin: 15px;
	}

	.border {
		border-radius: 10px;
		border: 1px solid black;
		background-color: #f4f4f4;
		padding: 12px 15px 0px 15px;
	}
	.dynamic-delete-button {
		cursor: pointer;
		position: absolute;
		top: -20px;
		right: -20px;
		font-size: 24px;
		color: black;
	}
	.dynamic-delete-button:hover {
		color: #777;
	}

	.footerClass {
		display: flex;
		justify-content: end;
		margin-top: 20px;
	}

	.plus-btn {
		display: flex;
		justify-content: center;
		place-items: center;
		width: 100%;
	}
</style>
