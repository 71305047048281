<template>
	<div class="styleDiv">
		<a-form :model="formState" class="form">
			<div>
				<img src="../assets/fy-logo.svg" alt="Vue logo" class="image" />
				<h1 style="text-align: center; margin: 20px; font-size: 23px; color: gray; font-weight: 600">LOGIN</h1>
			</div>
			<a-form-item class="formItem">
				<a-input class="input" v-model:value="formState.email" placeholder="Email" />
			</a-form-item>
			<a-form-item class="formItem" name="password">
				<a-input-password placeholder="Password" v-model:value="formState.password" />
			</a-form-item>
			<a-button type="primary" size="large" class="btn" @click="onSubmit">Login</a-button>
		</a-form>
	</div>
</template>

<script lang="ts" setup>
	import { loginApi } from "@/services/api/auth";
	import { UnwrapRef, reactive, toRaw } from "vue";
	import { openNotificationWithIcon } from "../utils/index";
	import router from "@/router";

	interface FormState {
		email: string;
		password: string;
	}

	const formState: UnwrapRef<FormState> = reactive({
		email: "hiren.godhasara@gmail.com",
		password: "Hiren@123",
	});

	const onSubmit = async () => {
		try {
			if (!formState.email) {
				return openNotificationWithIcon("warning", "Please enter email addres");
			}
			if (!formState.password) {
				return openNotificationWithIcon("warning", "Please enter password");
			} else {
				const apiRes: any = await loginApi(formState);
				if (!apiRes.roles || (!apiRes.roles.includes("admin") && !apiRes.roles.includes("superadmin"))) {
					return openNotificationWithIcon("warning", "You don't have permission to access website");
				}
				apiRes.adminLoginTime = new Date().getTime();
				localStorage.setItem("user", JSON.stringify(apiRes));
				openNotificationWithIcon("success", "Welcome back! Login successful.");
				router.push("/");
			}
		} catch (error) {
			console.log(error);
		}
	};
</script>

<style scoped>
	.styleDiv {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.form {
		background-color: #fff;
		width: 360px;
		padding: 1rem 2rem;
		box-shadow: 0 10px 25px rgba(92, 99, 105, 0.2);
		.ant-form-item-label {
			position: relative;
			height: 48px;
		}
	}

	.input {
		text-align: left;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 1px solid #dadce0;
		outline: none;
		padding: 1rem;
		background: none;
		z-index: 1;
	}

	.image {
		width: 70%;
		height: auto;
		margin: auto;
		display: block;
	}

	.formItem {
		position: relative;
		height: 48px;
	}

	.btn {
		width: 100%;
	}
</style>
