<template>
	<table class="synastry-table">
		<thead>
			<tr>
				<th>Index</th>
				<th>Rules</th>
				<th>Communication</th>
				<th>Connection</th>
				<th>Energy</th>
				<th>Stability</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="(i, e) in pairArray" :key="i">
				<td>{{ e + 1 }}</td>
				<td>
					<a
						@click="
							() => {
								(modalOpen = true), openSinglePairDetails(i.userADetails, i.userBDetails, { abs2: i.abs2, abs: i.abs, rules: i.rules, userALng: i.userALng, userBLng: i.userBLng, userA2Lng: i.userA2Lng, userB2Lng: i.userB2Lng, userAPlanet: i.userAPlanet, userBPlanet: i.userBPlanet });
							}
						"
					>
						{{ i.rules }}
					</a>
				</td>
				<td>{{ i.communication }}</td>
				<td>{{ i.connection }}</td>
				<td>{{ i.energy }}</td>
				<td>{{ i.stability }}</td>
			</tr>
		</tbody>
	</table>
	<TestSinglePairDetailsModal v-if="userDetails" :modalOpen="modalOpen" :closeSinglePairDetails="closeSinglePairDetails" :userDetails="userDetails" />
</template>

<script setup lang="ts">
	import { ref } from "vue";
	import TestSinglePairDetailsModal from "./TestSinglePairDetailsModal.vue";
	import { convertTimestamp, getCurrentTime } from "@/utils";

	const { pairArray } = defineProps(["pairArray"]);
	const modalOpen = ref(false);
	const userDetails = ref();

	const openSinglePairDetails = (userA: any, userB: any, other: any) => {
		const otherDetail = {
			...other,
		};
		const userADetail = {
			name: userA.name,
			gender: userA.gender == "m" ? "Male" : "Female",
			dob: userA.dob && convertTimestamp(userA.dob, true),
			tob: getCurrentTime(userA.dob),
			pob: userA.pob,
			geoCordinate: userA.geoCordinate,
			utc: userA.utc && convertTimestamp(userA.utc),
		};
		const userBDetail = {
			name: userB.name,
			gender: userB.gender == "m" ? "Male" : "Female",
			dob: userB.dob && convertTimestamp(userB.dob, true),
			tob: getCurrentTime(userB.dob),
			pob: userB.pob,
			geoCordinate: userB.geoCordinate,
			utc: userB.utc && convertTimestamp(userB.utc),
		};
		userDetails.value = { userADetail, userBDetail, otherDetail };
		console.log(userDetails.value);

		modalOpen.value = true;
	};

	const closeSinglePairDetails = () => {
		modalOpen.value = false;
	};
</script>

<style scoped>
	.synastry-table tbody tr td {
		height: 45px;
		padding: 5px 0px;
		text-align: center;
	}

	.synastry-table thead tr {
		position: sticky;
		top: 0;
		background-color: white;
		z-index: 1;
		height: 50px;
	}

	.synastry-table {
		width: 50%;
		height: 22%;
	}
</style>
