<template>
	<div class="mainDiv">
		<a-form class="header" layout="inline">
			<a-breadcrumb class="heading" separator="|">
				<a-breadcrumb-item :style="{ color: '#44142a', cursor: 'pointer', fontSize: '16px', fontWeight: '700' }">Synastry Test of Multiple Pairs</a-breadcrumb-item>
			</a-breadcrumb>
			<a-form-item label="Rule/Score set ">
				<a-select v-model:value="multiplePairList.protocolId" :style="{ width: '200px' }" :options="rulsItems" @select="protocolSelect">
					<template v-slot:notFoundContent>
						<Spin size="small" v-if="loading" />
						<Empty :image="Empty.PRESENTED_IMAGE_SIMPLE" v-else />
					</template>
				</a-select>
			</a-form-item>
			<a-form-item label="Select Pair Group ">
				<a-select v-model:value="multiplePairList.groupId" :style="{ width: '200px' }" :options="GroupItems" @select="groupSelect">
					<template v-slot:notFoundContent>
						<Spin size="small" v-if="loading" />
						<Empty :image="Empty.PRESENTED_IMAGE_SIMPLE" v-else />
					</template>
				</a-select>
			</a-form-item>
			<ImportModal :closeImportModal="() => (openImportModal = false)" :openImportModal="openImportModal" :fetchData="fetchData" />
			<a-button @click="openImportModal = true">Import</a-button>
			<ImportFileModal :closeImportFileModal="() => (openImportFileModal = false)" :openImportFileModal="openImportFileModal" :items="GroupItems" :fetchData="fetchData" />
			<a-button @click="openImportFileModal = true">Import File</a-button>
			<a-button @click="handleDelete" type="primary" :disabled="!multiplePairList.groupId"><DeleteOutlined /></a-button>
			<CloseCircleOutlined :style="{ fontSize: '25px', marginRight: '10px' }" @click="router.back()" />
		</a-form>
	</div>
	<div :style="{ position: 'absolute', width: '88%', zIndex: '1', height: '100%', backgroundColor: '#ffffff8a' }" v-if="loading">
		<Spin
			:style="{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100%',
				width: '100%',
			}"
		></Spin>
	</div>
	<div :style="{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', width: '100%', columnGap: '50px', marginTop: '20px', padding: '50px' }" v-else>
		<TestMultipleRulesScore class="t_register" :singlePairDetails="singlePairDetails" :pairArray="pairArray" :fetchMultilePairData="fetchMultilePairData" :multiplePairList="multiplePairList" :pagination="pagination" :loading="tableLoading" />
		<TestMultipleTotalCount :synastryCategoryArray="synastryCategoryArray" :heartLogoArray="heartLogoArray" :synastryVsGmArray="synastryVsGmArray" :heartLogoValue="heartLogoValue" :synastryCategoryValue="synastryCategoryValue" />
	</div>
</template>

<script setup lang="ts">
	import { getAstroSettingList, getMultiPairList, getGroupListApi, deleteMultiPairAndGroupList } from "@/services/api/user";
	import { CloseCircleOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
	import { Empty, Modal, Spin } from "ant-design-vue";
	import { ref, createVNode } from "vue";
	import TestMultipleRulesScore from "@/components/AstroSetting/TestMultiplePair/TestMultipleRulesScore.vue";
	import TestMultipleTotalCount from "@/components/AstroSetting/TestMultiplePair/TestMultipleTotalCount.vue";
	import ImportModal from "@/components/AstroSetting/TestMultiplePair/ImportModal.vue";
	import ImportFileModal from "@/components/AstroSetting/TestMultiplePair/ImportFileModal.vue";
	import router from "@/router";

	const GroupItems: any = ref([]);
	const rulsItems: any = ref([]);
	const loading = ref(false);
	const synastryCategoryArray: any = ref([]);
	const synastryCategoryValue: any = ref([
		{
			label: "Very high",
			com: 0,
			con: 0,
			ene: 0,
			stab: 0,
			gm: 0,
		},
		{
			label: "High",
			com: 0,
			con: 0,
			ene: 0,
			stab: 0,
			gm: 0,
		},
		{
			label: "Medium",
			com: 0,
			con: 0,
			ene: 0,
			stab: 0,
			gm: 0,
		},
		{
			label: "Low",
			com: 0,
			con: 0,
			ene: 0,
			stab: 0,
			gm: 0,
		},
		{
			label: "Very Low",
			com: 0,
			con: 0,
			ene: 0,
			stab: 0,
			gm: 0,
		},
	]);
	const heartLogoArray: any = ref([]);
	const heartLogoValue: any = ref([
		{
			label: "Very high",
			value: 0,
			perValue: 0,
		},
		{
			label: "High",
			value: 0,
			perValue: 0,
		},
		{
			label: "Medium",
			value: 0,
			perValue: 0,
		},
		{
			label: "Very Low",
			value: 0,
			perValue: 0,
		},
	]);
	const synastryVsGmArray: any = ref({});
	const openImportModal: any = ref(false);
	const openImportFileModal = ref(false);
	const pairArray: any = ref([]);
	const tableLoading = ref(false);

	const multiplePairList = ref({
		pageNo: 1,
		pageLimit: 20,
		protocolId: "",
		groupId: "",
	});

	const singlePairDetails: any = ref({
		protocolName: "",
		groupName: "",
	});
	const pagination = ref({ current: 0, pageSize: 20, total: 0 });

	const fetchData = async () => {
		rulsItems.value = [];
		GroupItems.value = [];
		loading.value = true;
		const rulesList = await getAstroSettingList();

		rulesList?.map((e: any) => {
			rulsItems.value.push({ key: e?._id, value: e?._id, label: e?.name, data: e });
		});

		const groupList = await getGroupListApi();
		groupList?.data.map((e: any) => {
			GroupItems.value.push({ label: e?.name, value: e?._id, key: e?._id });
		});

		loading.value = false;
	};
	fetchData();

	const fetchMultilePairData = async (protocolId: any, groupId: any, pageNo: any, pageLimit: any) => {
		try {
			tableLoading.value = true;
			pairArray.value = [];
			multiplePairList.value.protocolId = protocolId;
			multiplePairList.value.groupId = groupId;
			const pairList = await getMultiPairList({ protocolId, groupId, pageNo, pageLimit });
			pagination.value.current = Number(pairList?.pageNo);
			pagination.value.pageSize = Number(pairList?.pageLimit);
			pagination.value.total = Number(pairList?.TotalFilteredPair);

			for (let i = 0; i < pairList?.items?.length; i++) {
				const pair = pairList?.items[i];

				pairArray.value.push({
					_id: pair.pair._id,
					userA: pair.pair.userA,
					userB: pair.pair.userB,
					communication: pair.comPer,
					connection: pair.conPer,
					energy: pair.enePer,
					stability: pair.staPer,
					guna: pair.gunaPer,
					score: pair.synPer,
				});
			}

			tableLoading.value = false;

			const heartLogo = pairList?.heartLogoCount;
			heartLogoValue.value = [
				{
					label: "Very high",
					value: heartLogo.veryHigh,
					perValue: (heartLogo.veryHigh * 100) / pairList.items.length,
				},
				{
					label: "High",
					value: heartLogo.high,
					perValue: (heartLogo.high * 100) / pairList.items.length,
				},
				{
					label: "Medium",
					value: heartLogo.medium,
					perValue: (heartLogo.medium * 100) / pairList.items.length,
				},
				{
					label: "Very Low",
					value: heartLogo.veryLow,
					perValue: (heartLogo.veryLow * 100) / pairList.items.length,
				},
			];

			const synastryCategory = pairList?.synastryCategory;
			synastryCategoryValue.value = [
				{
					label: "Very high",
					com: synastryCategory?.textDisCountCom.veryHigh,
					con: synastryCategory?.textDisCountCon.veryHigh,
					ene: synastryCategory?.textDisCountEne.veryHigh,
					stab: synastryCategory?.textDisCountStab.veryHigh,
					gm: synastryCategory?.textDisCountGM.veryHigh,
				},
				{
					label: "High",
					com: synastryCategory?.textDisCountCom.high,
					con: synastryCategory?.textDisCountCon.high,
					ene: synastryCategory?.textDisCountEne.high,
					stab: synastryCategory?.textDisCountStab.high,
					gm: synastryCategory?.textDisCountGM.high,
				},
				{
					label: "Medium",
					com: synastryCategory?.textDisCountCom.medium,
					con: synastryCategory?.textDisCountCon.medium,
					ene: synastryCategory?.textDisCountEne.medium,
					stab: synastryCategory?.textDisCountStab.medium,
					gm: synastryCategory?.textDisCountGM.medium,
				},
				{
					label: "Low",
					com: synastryCategory?.textDisCountCom.low,
					con: synastryCategory?.textDisCountCon.low,
					ene: synastryCategory?.textDisCountEne.low,
					stab: synastryCategory?.textDisCountStab.low,
					gm: synastryCategory?.textDisCountGM.low,
				},
				{
					label: "Very Low",
					com: synastryCategory?.textDisCountCom.veryLow,
					con: synastryCategory?.textDisCountCon.veryLow,
					ene: synastryCategory?.textDisCountEne.veryLow,
					stab: synastryCategory?.textDisCountStab.veryLow,
					gm: synastryCategory?.textDisCountGM.veryLow,
				},
			];
		} catch (error) {
			console.log(error);
			tableLoading.value = false;
		}
	};

	const singlePairDataFromLocal: any = localStorage.getItem("multiPairList");
	if (singlePairDataFromLocal) {
		const singlePair = JSON.parse(singlePairDataFromLocal && singlePairDataFromLocal);
		singlePair.protocolName ? (singlePairDetails.value.protocolName = singlePair.protocolName) : "";
		singlePair.groupName ? (singlePairDetails.value.groupName = singlePair.groupName) : "";
		if (singlePair.groupId && singlePair.protocolId) {
			fetchMultilePairData(singlePair.protocolId, singlePair.groupId, singlePair.pageNo, singlePair.pageLimit);
		}
	}

	const protocolSelect = async (index: any, record: any) => {
		singlePairDetails.value.protocolName = record.label;
		pairArray.value = [];
		multiplePairList.value.protocolId && multiplePairList.value.groupId && fetchMultilePairData(multiplePairList.value.protocolId, multiplePairList.value.groupId, multiplePairList.value.pageNo, multiplePairList.value.pageLimit);
	};

	const groupSelect = async (index: any, record: any) => {
		singlePairDetails.value.groupName = record.label;
		pairArray.value = [];
		multiplePairList.value.protocolId && multiplePairList.value.groupId && fetchMultilePairData(multiplePairList.value.protocolId, multiplePairList.value.groupId, multiplePairList.value.pageNo, multiplePairList.value.pageLimit);
	};

	const handleDelete = () => {
		Modal.confirm({
			title: "Delete protocol",
			icon: createVNode(ExclamationCircleOutlined),
			content: `Are you sure you want to delete this ${singlePairDetails.value.groupName} group?`,
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			async onOk() {
				await deleteMultiPairAndGroupList(multiplePairList.value.groupId);
				localStorage.removeItem("singlePair");
				multiplePairList.value.groupId = "";
				fetchData();
			},
		});
	};
</script>

<style scoped>
	.t_register {
		flex: 1 1 auto;
		position: relative;
	}
	.heading {
		font-weight: 700;
		font-size: 30px;
		margin: 20px auto 20px 20px;
	}
	.mainDiv {
		display: grid;
		position: relative;
	}

	.header {
		display: flex;
		justify-content: flex-end;
		place-items: center;
		gap: 10px;
		flex-wrap: wrap;
	}
</style>
