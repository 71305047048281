<template>
	<div class="profile_container">
		<div class="heading_container" separator="/">
			<div class="heading">
				<div>
					<p class="heading_text"><ProfileOutlined /> Profiles</p>
				</div>
				<div :style="{ display: 'flex' }">
					<div :style="{ marginRight: '10px' }">
						<a-input-group compact>
							<a-select v-model:value="searchBy">
								<a-select-option value="name" label="Name">Name</a-select-option>
								<a-select-option value="identifier" label="Email">Email</a-select-option>
								<a-select-option value="userId" label="UserID">UserID</a-select-option>
							</a-select>
							<a-input style="width: 68%" v-model:value="search" @keyup="handleSearch" />
						</a-input-group>
					</div>
					<!-- <div :style="{ marginRight: '10px' }">
						<a-label>Name :</a-label>
						<span class="rating-select">
							<a-input class="filter-dropdown" v-model:value="searchName" @keyup="handleSearch" />
						</span>
					</div> -->
					<div :style="{ marginRight: '10px' }">
						<a-label>Gender :</a-label>
						<a-select class="rating-select" id="gender-select" v-model:value="selectedGender" @change="handleGenderChange">
							<a-select-option class="filter-dropdown" value="m">Male</a-select-option>
							<a-select-option class="filter-dropdown" value="f">Female</a-select-option>
						</a-select>
					</div>
					<div>
						<a-label>Rating :</a-label>
						<a-select id="rating-select" class="rating-select" v-model:value="selectedRating" @change="filterProfiles">
							<a-select-option class="filter-dropdown" value="all">All</a-select-option>
							<a-select-option class="filter-dropdown" value="unrated">Unrated</a-select-option>
							<a-select-option class="filter-dropdown" value="face">Face</a-select-option>
							<a-select-option class="filter-dropdown" value="obscure">Obscure</a-select-option>
							<a-select-option class="filter-dropdown" value="distorted">Distorted</a-select-option>
							<a-select-option class="filter-dropdown" value="rotate">Rotate</a-select-option>
							<a-select-option class="filter-dropdown" value="no-face">No Face</a-select-option>
							<a-select-option class="filter-dropdown" value="many">Many</a-select-option>
							<a-select-option class="filter-dropdown" value="gender">Gender</a-select-option>
							<a-select-option class="filter-dropdown" value="celeb">Celeb</a-select-option>
							<a-select-option class="filter-dropdown" value="age">Age</a-select-option>
							<a-select-option class="filter-dropdown" value="faith">Faith</a-select-option>
							<a-select-option class="filter-dropdown" value="vulgar">Vulgar</a-select-option>
							<a-select-option class="filter-dropdown" value="advert">Advert</a-select-option>
							<a-select-option class="filter-dropdown" value="mis-match">Mis Match</a-select-option>
						</a-select>
					</div>
					<div :style="{ marginRight: '10px' }">
						<a-form-item label="Start Date : " :style="{ marginBottom: '10px' }">
							<a-space direction="vertical" :size="12">
								<a-range-picker format="DD-MMM-YYYY" v-model="dateRange" @change="handleDateChange" />
							</a-space>
						</a-form-item>
					</div>

					<a-button type="primary" class="btns" @click="clearFilters">Clear filters</a-button>
				</div>
			</div>
			<div class="pagination-wrapper">
				<a-pagination show-size-changer v-model:current="pagination.current" v-model:pageSize="pagination.pageSize" :total="pagination.total" @change="handlePageChange" :position="['topRight']" :showTotal="showTotal" />
			</div>
		</div>
		<div class="custom-row">
			<div v-for="(item, index) in cards" :key="index" class="card">
				<div class="card-container">
					<div class="card-items-wrapper">
						<p>
							<router-link :to="`/profile/${item._id}`" class="name-heading">{{ item.nickName || "-" }}</router-link>
						</p>

						<p><UserOutlined> </UserOutlined> {{ item.gender === "m" ? "Male" : item.gender === "f" ? "Female" : "-" }} | {{ calculateAge(item.dob) }} Years</p>
						<p>
							Wants to meet :
							<b>{{ item.genders && item.genders.includes("b") ? "Both" : item.genders && item.genders.includes("f") ? "Female" : item.genders && item.genders.includes("m") ? "Male" : " - " }}</b>
						</p>
						<p>
							Orientation: <b>{{ item.orientation && item.orientation === "s" ? "Straight" : item.orientation && item.orientation === "g" ? "Gay" : item.orientation && item.orientation === "b" ? "Bi-sexual" : item.orientation && item.orientation === "o" ? "Other" : " - " }}</b>
						</p>
						<p>
							Modified date: <b>{{ item.modifiedAt ? dayjs.utc(item.modifiedAt).format("DD-MMM-YYYY HH:mm") : "-" }} </b>
						</p>
					</div>
					<div class="radio-btn-container">
						<a-radio-group v-model:value="item.rating" class="input-field" @change="handleRatingChange(item._id, item.rating, item.originalRating)">
							<div class="radio-group-1">
								<a-radio class="radio-btn" value="face">Face</a-radio>
								<a-radio class="radio-btn" value="obscure">Obscure</a-radio>
								<a-radio class="radio-btn" value="distorted">Distorted</a-radio>
								<a-radio class="radio-btn" value="rotate">Rotate</a-radio>
								<a-radio class="radio-btn" value="no-face">No-face</a-radio>
								<a-radio class="radio-btn" value="many">Many</a-radio>
								<a-radio class="radio-btn" value="gender">Gender</a-radio>
							</div>
							<div :style="{ display: 'flex', alignItems: 'center', justifyContent: 'center' }">
								<a-radio class="radio-btn" value="celeb">Celeb</a-radio>
								<a-radio class="radio-btn" value="age">Age</a-radio>
								<a-radio class="radio-btn" value="faith">Faith</a-radio>
								<a-radio class="radio-btn" value="vulgar">Vulgar</a-radio>
								<a-radio class="radio-btn" value="advert">Advert</a-radio>
								<a-radio class="radio-btn" value="mis-match">Mis-match</a-radio>
							</div>
						</a-radio-group>
					</div>

					<div class="other-images-grid-container">
						<div class="other-images-grid">
							<div v-for="index in 6" :key="index">
								<img v-if="item.profiles[0]?.mediaItems[index - 1]" :src="getImageUrl(item.profiles[0]?.mediaItems[index - 1].filename)" alt="Profile Image" :style="{ height: '120px' }" @click="openImageModal(getImageUrl(item.profiles[0]?.mediaItems[index - 1].filename))" />
								<img v-if="!item.profiles[0]?.mediaItems[index - 1]" src="../../public/No-Image-Placeholder.png" alt="Profile Image" class="no_image" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<a-modal v-model:visible="isModalVisible" @cancel="handleCancel" :footer="null" :closable="false">
			<img :src="selectedImage" alt="Selected Image" style="width: 100%; height: auto" />
		</a-modal>
	</div>
</template>

<script lang="ts" setup>
	import { ref } from "vue";
	import { getProfilesList, updateProfileRating } from "@/services/api/user";
	import { ProfileOutlined, UserOutlined } from "@ant-design/icons-vue";
	import { BASE_URL_FOR_MEDIA } from "@/.config";
	import { removeNullFromObj } from "@/utils";
	import dayjs from "dayjs";
	import "dayjs/plugin/utc";

	interface ProfileItem {
		_id: string;
		nickName: string;
		gender: string;
		dob: string | null;
		modifiedAt: string;
		genders: Array<string>;
		orientation: string;
		rating: string;
		profiles: Array<{
			type: string;
			text: string;
			_id: string;
			mediaItems: Array<{
				variants: string[];
				_id: string;
				filename: string;
				mime: string;
				type: string;
				source: string;
				size: number;
				title: string;
				attributes: {
					_id: string;
					height: number;
					orientation: number;
					width: number;
				};
			}>;
			createdAt: string;
			modifiedAt: string;
		}>;
		originalRating: string;
	}

	const userListPagination = ref({ pageNo: 1, pageLimit: 20 });
	const pagination = ref({
		total: 0,
		current: 1,
		pageSize: 20,
		totalPages: 0,
	});
	const cards = ref<ProfileItem[]>([]);
	const loading = ref(false);
	let observer: IntersectionObserver | null = null;
	const selectedRating = ref("unrated");
	const dateRange = ref([null, null]);
	const selectedGender = ref("");
	const isModalVisible = ref(false);
	const selectedImage = ref("");
	const ratedUserCount = ref();
	const totalUser = ref("");
	const changedRatings = ref(new Set<string>());
	const saveStatus = ref<Record<string, boolean>>({});
	const search = ref("");
	const searchBy = ref<string>("name");
	const storeIds = async (apiRes: any) => {
		const ids = apiRes.item.map((a: any) => a._id);
		localStorage.setItem("profileIds", JSON.stringify(ids));
	};

	const getProfiles = async () => {
		try {
			loading.value = true;
			const payload = {
				...userListPagination.value,
				rating: selectedRating.value,
				startDate: dateRange.value[0] ? dayjs(dateRange.value[0]).format("YYYY-MM-DDT00:00:00.SSS[Z]") : null,
				endDate: dateRange.value[1] ? dayjs(dateRange.value[1]).format("YYYY-MM-DDT00:00:00.SSS[Z]") : null,
				gender: selectedGender.value || null,
				searchBy: searchBy.value,
				search: search.value,
			};
			await removeNullFromObj(payload);
			const apiRes = await getProfilesList(payload);
			storeIds(apiRes);
			const profilesWithRating = apiRes.item.map((profile: ProfileItem) => ({
				...profile,
				rating: profile.rating || "",
				originalRating: profile.rating || "",
			}));

			cards.value = profilesWithRating;
			profilesWithRating.forEach((profile: ProfileItem) => {
				saveStatus.value[profile._id] = false; // Default to false
			});

			totalUser.value = apiRes.totalUser;
			ratedUserCount.value = apiRes.totalRatedUser;
			pagination.value.total = apiRes.pagination.total;
			pagination.value.current = apiRes.pagination.current;
			pagination.value.totalPages = apiRes.pagination.totalPages;
			loading.value = false;
		} catch (error) {
			loading.value = false;
			console.error(error);
		}
	};
	getProfiles();

	const getImageUrl = (filename: string) => {
		return `${BASE_URL_FOR_MEDIA}/${filename}`;
	};

	const calculateAge = (birthdate: string | null): string => {
		if (!birthdate) return "-";
		const birthDate = new Date(birthdate);
		const today = new Date();
		let age = today.getFullYear() - birthDate.getFullYear();
		const monthDifference = today.getMonth() - birthDate.getMonth();
		if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age.toString();
	};

	const handleRatingChange = async (profileId: string, rating: string, originalRating: string) => {
		saveRatingChange(profileId, rating);
	};

	const filterProfiles = () => {
		userListPagination.value.pageNo = 1;
		cards.value = [];
		getProfiles();
	};

	const clearFilters = () => {
		selectedRating.value = "unrated";
		userListPagination.value.pageNo = 1;
		dateRange.value = [null, null];
		selectedGender.value = "";
		search.value = "";
		searchBy.value = "name";
		cards.value = [];
		getProfiles();
	};

	const handleDateChange = (dates: [any, any]) => {
		if (!dates || (!dates[0] && !dates[1])) {
			dateRange.value = [null, null];
			selectedRating.value = "all";
			selectedGender.value = "";
		} else {
			dateRange.value = dates;
		}
		filterProfiles();
	};

	const handleGenderChange = (value: string) => {
		if (!value) {
			clearFilters();
		} else {
			selectedGender.value = value;
		}
		selectedGender.value = value;
		filterProfiles();
	};

	const openImageModal = (image: any) => {
		selectedImage.value = image;
		isModalVisible.value = true;
	};

	const handleCancel = () => {
		isModalVisible.value = false;
	};

	const handlePageChange = (current: number, pageSize: number) => {
		userListPagination.value.pageNo = current;
		userListPagination.value.pageLimit = pageSize;
		getProfiles();
	};

	const saveRatingChange = async (profileId: string, rating: string) => {
		try {
			const profile = cards.value.find((p) => p._id === profileId);
			if (!profile) return;

			const wasUnrated = !profile.originalRating;
			const isNowRated = rating !== "";
			await updateProfileRating(profileId, { rating });
			saveStatus.value[profileId] = false;
			if (wasUnrated && isNowRated) {
				ratedUserCount.value += 1;
			} else if (!isNowRated && profile.originalRating) {
				ratedUserCount.value -= 1;
			}
			profile.originalRating = rating;
			changedRatings.value.delete(profileId);
		} catch (error) {
			console.error("Error saving rating:", error);
		}
	};

	const showTotal = (total: number, range: [number, number]) => {
		const start = range && range[0] ? range[0] : 0;
		const end = range && range[1] ? range[1] : 0;
		let ratingText = "";
		ratingText = selectedRating.value === "all" || selectedRating.value === "unrated" ? "Rated" : selectedRating.value === "face" ? "Face Rated" : selectedRating.value === "obscure" ? "Obscure Rated" : selectedRating.value === "no-face" ? "No Face Rated" : selectedRating.value === "mis-match" ? "Mis Match Rated" : selectedRating.value === "advert" ? "Advert Rated" : selectedRating.value === "vulgar" ? "Vulgar Rated" : "Rated";
		if (selectedRating.value === "all" || selectedRating.value === "unrated") {
			return `${ratedUserCount.value} of ${totalUser.value} ${ratingText} | 📃 ${start}-${end} of ${total}`;
		} else {
			return `${pagination.value.total} of ${totalUser.value} ${ratingText} | 📃 ${start}-${end} of ${total}`;
		}
	};

	const handleSearch = (event: KeyboardEvent) => {
		if (event.key === "Enter") {
			userListPagination.value.pageNo = 1;
			cards.value = [];
			getProfiles();
		}
	};
</script>

<style scoped>
	.profile_container {
		padding: 0 25px 25px 25px;
	}
	.heading_container {
		position: sticky;
		z-index: 1000;
		background-color: #fff;
		top: 0;
		padding-top: 1rem;
		padding-bottom: 10px;
		/* padding: 25px 25px 10px 25px; */
		width: 100%;
	}
	.name-heading {
		color: rgb(68, 20, 42);
		cursor: pointer;
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 20px;
	}
	.name-heading:hover {
		border-bottom: 1px solid rgb(68, 20, 42);
	}
	.heading {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}
	.heading_text {
		color: rgb(68, 20, 42);
		cursor: pointer;
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 20px;
	}
	.load-more-trigger {
		height: 1px;
		background-color: transparent;
	}
	.main_image-container {
		height: 8rem;
		margin-bottom: 1rem;
	}
	.main_image {
		padding: 8px;
		border-radius: 6px;
		height: 200px;
		border-radius: 15px;
	}

	.other-images-grid img {
		border-radius: 6px;
	}
	.other-images-grid {
		display: flex;
		column-gap: 10px;
	}
	.card-items-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.card-container {
		margin-bottom: 8px;
		border: 2px solid rgb(190, 190, 190);
		border-radius: 11px;
		display: flex;
		padding: 10px;
		justify-content: space-between;
	}
	.radio-btn {
		border: 1px solid black;
		padding: 6px;
		border-radius: 5px;
	}
	.radio-btn-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.radio-group-1 {
		display: flex;
		margin-bottom: 10px;
	}
	.rating-select {
		margin-right: 10px;
		width: 9rem;
		margin-left: 3px;
	}
	.filter-dropdown {
		width: 250px;
	}
	.no_image {
		opacity: 0.4;
		height: 120px;
	}
	.other-images-grid-container {
		width: 630px;
	}
	.pagination-wrapper {
		display: flex;
		justify-content: end;
		margin-bottom: 16px;
	}
</style>
