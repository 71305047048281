<template>
	<div class="mainDiv">
		<a-form class="header" layout="inline">
			<a-breadcrumb class="heading" separator="|">
				<a-breadcrumb-item :style="{ color: '#44142a', cursor: 'pointer', fontSize: '16px', fontWeight: '700' }">Synastry Test of a Single Pair</a-breadcrumb-item>
			</a-breadcrumb>
			<a-form-item label="Rule/Score set :">
				<a-input :style="{ width: '200px' }" :value="multiPairList.protocolName" :disabled="true"></a-input>
			</a-form-item>
			<a-form-item label="Pair Group :">
				<a-input :style="{ width: '200px' }" :value="multiPairList.groupName" :disabled="true"></a-input>
			</a-form-item>
			<CloseCircleOutlined :style="{ fontSize: '25px', marginRight: '10px' }" @click="() => router.back()" />
		</a-form>
		<div :style="{ position: 'absolute', width: '88%', zIndex: '1', height: '100%', backgroundColor: '#ffffff8a' }" v-if="loading">
			<Spin
				:style="{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100%',
					width: '100%',
				}"
			></Spin>
		</div>
		<div :style="{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', width: '100%', marginTop: '20px', columnGap: '100px' }" v-else>
			<TestSingleRulesScore class="t_register" :pairArray="pairArray" />
			<div :style="{ marginTop: '20px' }">
				<TestSingleTotalCount :singlePairData="singlePairData" />
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { CloseCircleOutlined } from "@ant-design/icons-vue";
	import { ref } from "vue";
	import TestSingleRulesScore from "@/components/AstroSetting/TestSinglePair/TestSingleRulesScore.vue";
	import TestSingleTotalCount from "@/components/AstroSetting/TestSinglePair/TestSingleTotalCount.vue";
	import router from "@/router";
	import { Spin } from "ant-design-vue";
	import { getMultiPairList } from "@/services/api/user";

	const loading = ref(false);
	const pairArray: any = ref([]);
	const multiPairList: any = ref({
		groupName: "",
		protocolName: "",
		protocolId: "",
		groupId: "",
	});
	const singlePairData: any = ref([]);

	const singlePairDataFromLocal: any = localStorage.getItem("multiPairList");
	if (singlePairDataFromLocal) {
		multiPairList.value = JSON.parse(singlePairDataFromLocal);
	}

	const grahasData = async () => {
		loading.value = true;
		const id: any = router.currentRoute.value.params.id;
		pairArray.value = [];
		const pairList = await getMultiPairList({ protocolId: multiPairList.value.protocolId, groupId: multiPairList.value.groupId, pairId: id });

		pairList?.items[0]?.outRules?.map((outRules: any) => {
			if (outRules.isValid) {
				pairArray.value.push({
					abs: outRules.abs,
					abs2: outRules.abs2,
					userADetails: pairList?.items[0]?.pair?.userA,
					userBDetails: pairList?.items[0]?.pair?.userB,
					rules: outRules.rules,
					communication: outRules.communication,
					connection: outRules.connection,
					energy: outRules.energy,
					stability: outRules.stability,
					userALng: outRules.userALng,
					userBLng: outRules.userBLng,
					userA2Lng: outRules.userA2Lng,
					userB2Lng: outRules.userB2Lng,
					userAPlanet: outRules.userAPlanet,
					userBPlanet: outRules.userBPlanet,
				});
			}
		});

		singlePairData.value = {
			synastryCategory: pairList?.synastryCategory,
			heartLogo: pairList?.heartLogoCount,
			score: pairList?.items[0]?.score,
			comPer: pairList?.items[0]?.comPer,
			conPer: pairList?.items[0]?.conPer,
			enePer: pairList?.items[0]?.enePer,
			staPer: pairList?.items[0]?.staPer,
			outRules: pairList?.items[0]?.outRules,
			synPer: pairList?.items[0]?.synPer,
			gunaPer: pairList?.items[0]?.gunaPer,
			gunaTotal: pairList?.items[0]?.gunaTotal,
		};

		loading.value = false;
	};
	grahasData();
</script>

<style scoped>
	.t_register {
		flex: 1 1 auto;
		position: relative;
	}
	.heading {
		font-weight: 700;
		font-size: 30px;
		margin: 20px auto 20px 20px;
	}
	.mainDiv {
		display: grid;
		position: relative;
	}

	.header {
		display: flex;
		justify-content: flex-end;
		place-items: center;
		gap: 10px;
		flex-wrap: wrap;
	}
</style>
