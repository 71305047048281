<template>
	<div class="mainDiv">
		<div class="subDiv">Planets</div>
		<a-checkbox-group v-model:value="planets" :style="{ display: 'grid' }" @change="props.planetsChanged">
			<div :style="{ display: 'flex', padding: '4px', flexWrap: 'wrap' }" v-for="e in checkBoxPlanetsArray">
				<a-checkbox :value="e.value" class="checkBox">
					<img :src="e.icon" class="col-icon" />
				</a-checkbox>
				<h4 :style="{ marginLeft: 'auto' }">{{ e.planet }}</h4>
			</div>
		</a-checkbox-group>
	</div>
	<div class="mainDiv" :style="{ marginLeft: '20px' }">
		<div class="subDiv">Orb settings</div>
		<a-checkbox-group v-model:value="orbCheckboxArray" :style="{ display: 'grid' }" @change="(user: string)=>orbChanged(user)">
			<div :style="{ display: 'flex', flexWrap: 'wrap', padding: '4px', justifyContent: 'space-between' }" v-for="e in orbValueArray">
				<a-checkbox :value="e.value" class="checkBox">
					<img :src="e.icon" class="col-icon" />
				</a-checkbox>
				<a-input v-model:value="e.inputValue" :disabled="!e.inputValueExist" :style="{ width: '75px', textAlign: 'center', marginRight: '20px' }"></a-input>
				<h4 :style="{ marginLeft: 'auto', color: e.inputValueExist ? 'black' : '#d9d9d9' }">{{ e.cel }}</h4>
				<h4 :style="{ width: '150px', marginLeft: '20px', color: e.inputValueExist ? 'black' : '#d9d9d9' }">{{ e.name }}</h4>
			</div>
		</a-checkbox-group>
	</div>
</template>

<script setup lang="ts">
	import { checkBoxPlanetsArray } from "@/services/api/user";
	import { ref, toRefs } from "vue";
	const props: any = defineProps(["orbCheckboxArray", "orbValueArray", "planets", "planetsChanged", "orbChanged", "orbSettingCheckbox"]);
	const { orbValueArray, orbCheckboxArray, planets } = toRefs(props);
</script>

<style scoped>
	.title {
		font-weight: 700;
		font-size: 20px;
	}
	.col-icon {
		width: 15px;
		margin: 5px;
	}

	.mainDiv {
		border: 2px solid rgb(190, 190, 190);
		position: relative;
		border-radius: 8px;
		padding: 30px;
	}

	.subDiv {
		display: flex;
		position: absolute;
		top: -12px;
		background: #ffff;
		margin-left: 20px;
		padding: 0 5px;
		font-weight: 700;
	}
</style>
