<template>
	<div class="synastryTable">
		<div class="mainDiv" :style="{ marginBottom: '30px' }">
			<div class="subDiv">Heart Logo</div>
			<table class="synastry-table">
				<thead>
					<tr>
						<th></th>
						<th>#</th>
						<th>%</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="i of heartLogoValue">
						<td>{{ i.label }}</td>
						<td>{{ i.value.toFixed(2) }}</td>
						<td>{{ i.perValue.toFixed(2) }}%</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="mainDiv">
			<div class="subDiv">Synastry & Guna Milan Text Distribution</div>
			<table class="synastry-table">
				<thead>
					<tr>
						<th></th>
						<th>Com</th>
						<th>Con</th>
						<th>Ene</th>
						<th>Stab</th>
						<th>GM</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="i of synastryCategoryValue">
						<td>{{ i.label }}</td>
						<td>{{ i.com }}</td>
						<td>{{ i.con }}</td>
						<td>{{ i.ene }}</td>
						<td>{{ i.stab }}</td>
						<td>{{ i.gm }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { toRefs } from "vue";

	const props: any = defineProps(["heartLogoValue", "synastryCategoryValue"]);
	const { heartLogoValue, synastryCategoryValue } = toRefs(props);
</script>

<style scoped>
	.synastry-table tbody tr td {
		padding: 5px 30px;
	}

	.synastry-table tbody tr td:first-child {
		border: 0px;
		text-align: end;
	}

	.synastry-table tbody tr td {
		border: 1px solid rgb(190, 190, 190);
		text-align: end;
	}
	.synastry-table {
		margin-left: auto;
	}
	.mainDiv {
		border: 2px solid rgb(190, 190, 190);
		position: relative;
		border-radius: 8px;
		padding: 30px;
	}

	.subDiv {
		display: flex;
		position: absolute;
		top: -12px;
		background: #ffff;
		margin-left: 20px;
		padding: 0 5px;
		font-weight: 700;
	}

	.heading {
		font-weight: 700;
		text-align: center;
	}
</style>
