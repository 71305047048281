<template>
	<a-config-provider
		style="min-height: 100vh"
		:theme="{
			token: {
				colorPrimary: '#44142a',
				colorPrimaryBg: '#fcdfec',
			},
		}"
		><div v-if="$route.name == 'login'">
			<router-view />
		</div>
		<div v-if="$route.name != 'login'">
			<div style="z-index: 100; width: 100%; height: 50px">
				<Header></Header>
			</div>
			<Sider />
		</div>
	</a-config-provider>
</template>

<script lang="ts" setup>
	import { defineComponent, ref } from "vue";
	import Header from "./components/Header.vue";
	import Sider from "./components/Sider.vue";
	defineComponent({ name: "HeaderView", components: { Headers, Sider } });
</script>

<style scoped>
	#components-layout-demo-side .logo {
		height: 32px;
		margin: 16px;
		background: rgba(255, 255, 255, 0.3);
	}
	.imgLogo {
		width: 100%;
	}

	.site-layout .site-layout-background {
		background: #fff;
	}
</style>
