<template>
	<div :style="{ position: 'relative', marginLeft: '10px' }">
		<div :style="{ width: '100%' }" class="mainDiv">
			<div class="subDiv">Birth Details</div>
			<div :style="{ padding: '15px' }">
				<div :style="{ marginTop: '10px' }">
					<a-form-item label="Date of birth" name="selectDob" :style="{ marginBottom: '10px' }" :rules="[{ required: true, message: 'Please select Date of Birth!' }]">
						<a-date-picker style="width: 100%" v-model:value="formData.selectDob" format="DD-MMM-YYYY" :disabled-date="disabledDate" />
					</a-form-item>
					<a-form-item label="Time of birth" name="tob" :style="{ marginBottom: '10px' }" :rules="[{ required: true, message: 'Please select Time of birth!' }]">
						<a-time-picker style="width: 100%" v-model:value="formData.tob" />
					</a-form-item>
					<a-form-item label="Place of birth" name="pob" :style="{ marginBottom: '10px' }" :rules="[{ required: true, message: 'Please select Place of birth!' }]">
						<a-select v-model:value="formData.pob" allow-clear show-search placeholder="Search a place" @select="select" :options="locationItems" @search="search">
							<template v-slot:notFoundContent>
								<Spin size="small" v-if="loading" />
								<Empty :image="Empty.PRESENTED_IMAGE_SIMPLE" v-else />
							</template>
						</a-select>
					</a-form-item>
					<a-form-item label="Co-ordinates" name="geoCordinate" :style="{ marginBottom: '10px' }" :rules="[{ required: true, message: 'Please select Co-ordinates!' }]">
						<a-input v-model:value="formData.geoCordinate" />
					</a-form-item>
					<a-form-item label="Profile" name="image" :rules="[{ required: true, message: 'Please upload image!' }]">
						<ImageUploadDrawer :deleteFile="deleteFile" :formData="formData" :newUploads="newUploads" />
					</a-form-item>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
	import { ref } from "vue";
	import dayjs, { Dayjs } from "dayjs";
	import { Empty, Spin } from "ant-design-vue";
	import { getGeoCordApi, getLocationApi } from "@/services/api/auth";
	import ImageUploadDrawer from "../ImageUploadDrawer.vue";
	import type { Rule } from "ant-design-vue/es/form";

	const { formData, newUploads, deleteFile } = defineProps(["formData", "newUploads", "deleteFile"]);
	// const dateFormat = "YYYY-MM-DDTHH:mm:ss.SSSZ";
	// const dob: any = formData.dob ? ref<Dayjs>(dayjs(formData.dob, dateFormat)) : ref<Dayjs>();
	// const tob = ref<Dayjs>(dayjs(formData.tob, "HH:mm:ss"));
	const loading = ref(false);
	const locationItems: any = ref([]);

	// const setDob = () => {
	// 	formData.dob = dob.value && `${dob.value.format("YYYY-MM-DD")}T${tob.value ? tob.value.format("HH:mm:ss.SSS[Z]") : "00:00:00.000Z"}`;
	// };
	// const setTob = () => {
	// 	formData.dob = dob.value && `${dob.value.format("YYYY-MM-DD")}T${tob.value ? tob.value.format("HH:mm:ss.SSS[Z]") : "00:00:00.000Z"}`;
	// };

	const select = async (index: number, record: any) => {
		try {
			const res = await getGeoCordApi(record.label);
			const firstResult = res?.data?.results[0];
			const latitude = firstResult.geometry.location.lat;
			const longitude = firstResult.geometry.location.lng;
			formData.geoCordinate = `${latitude.toFixed(4)} ${longitude.toFixed(4)}`;
			const geo = formData.geoCordinate.split(" ");
			formData.geo = { lat: geo[0], lng: geo[1] };
		} catch (error) {
			console.log(error);
		}
	};

	const locationList = async (input: string) => {
		try {
			if (input) {
				const res = await getLocationApi(input);
				const locationData = res?.data?.predictions;
				locationItems.value = locationData.map((e: any, i: number) => ({
					label: e.description,
					value: e.description,
					key: i,
				}));
				loading.value = false;
			}
		} catch (error) {
			loading.value = false;
			console.log(error);
		}
	};

	const search = (input: string) => {
		loading.value = true;
		setTimeout(() => {
			locationList(input);
		}, 1000);
	};

	const disabledDate = (current: any) => {
		const eighteenYearsAgo = new Date();
		eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

		return current && (current > eighteenYearsAgo || current > new Date());
	};
</script>
<style scoped>
	.mainDiv {
		border: 2px solid rgb(190, 190, 190);
		position: relative;
		border-radius: 8px;
		.ant-form-item {
			margin-bottom: 8px !important;
		}
	}
	.subDiv {
		display: flex;
		position: absolute;
		top: -12px;
		background: #ffff;
		margin-left: 20px;
		padding: 0 5px;
		font-weight: 700;
	}
</style>
