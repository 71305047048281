<template>
	<div :style="{ padding: '20px 20px 0px 20px' }">
		<div class="table-operations">
			<a-breadcrumb class="heading" separator="/">
				<a-breadcrumb-item :style="{ color: '#44142a' }"><CommentOutlined /> Users Feedback</a-breadcrumb-item>
			</a-breadcrumb>
			<a-button type="primary"
				><template #icon><DownloadOutlined /></template
			></a-button>
		</div>

		<a-table
			size="middle"
			bordered
			@change="handleChange"
			:columns="columns"
			:data-source="data"
			:pagination="{
				current: pagination.current,
				pageSize: pagination.pageSize,
				total: pagination.total,
				position: ['topRight'],
				size: 'small',
				showTotal: (total:any, range:any) => `${range[0]}-${range[1]} of ${total}`,
				showSizeChanger: false, 
			}"
			:loading="loading"
			:scroll="{ x: 1, y: height - 245 }"
			class="tableBody"
		>
			<template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
				<div style="padding: 8px">
					<a-input
						:placeholder="`Search ${column.title}`"
						:value="selectedKeys[0]"
						class="filterInput"
						@change="(user:any) => setSelectedKeys(user.target.value ? [user.target.value] : [])
			"
						@pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
					/>
					<a-button :style="{ width: '90px', marginRight: '8px' }" @click="handleReset(clearFilters, confirm, column.dataIndex)"> Reset </a-button>
					<a-button type="primary" @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
						<template #icon><SearchOutlined /></template>
						Search
					</a-button>
				</div>
			</template>
			<template #bodyCell="{ text, column, record }">
				<template v-if="text === 'bug_report'">
					<a-button width="100%" @click="viewProfile(record)">View </a-button>
				</template>
				<template v-if="column.dataIndex === 'text' && text.length > 17">
					<div @click="showModal(text)">
						{{ truncateText(text, 30) }}
					</div>
				</template>
				<template v-if="column.dataIndex !== 'text' && column.dataIndex !== 'attachments' && text">
					<div>
						{{ truncateText(text, 40) }}
					</div>
				</template>
			</template>
		</a-table>
		<!-- <a-modal :open="openTextModal" @cancel="() => (openTextModal = false)">{{ reportText }} </a-modal> -->
		<a-modal :open="openTextModal" @cancel="() => (openTextModal = false)">
			{{ reportText }}
			<template #footer>
				<a-button @click="() => (openTextModal = false)">Cancel</a-button>
			</template>
		</a-modal>
		<a-modal v-model:open="modalOpen" title="View" :footer="false">
			<a-image :width="200" :src="src" />
		</a-modal>
	</div>
</template>
<script lang="ts" setup>
	import { DownloadOutlined, CommentOutlined } from "@ant-design/icons-vue";
	import { Ref, ref } from "vue";
	import type { TableProps } from "ant-design-vue";
	import { SearchOutlined } from "@ant-design/icons-vue";
	import { ColumnsData } from "./../interface/Members.interface";
	import { getFeedbackList, feedbackMapData } from "../services/api/user";
	import { BACKEND_BASE_URL } from "@/.config";
	import { truncateText, useScreenSize } from "@/utils";

	const filteredInfo = ref();
	const loading = ref();
	const userList = ref<any[]>([]);
	const data: any = ref([]);
	const userListFilter: any = ref({
		pageNo: 1,
		pageLimit: 20,
		search: null,
		type: "admin",
		key: "bug_report",
	});
	const pagination = ref({ pageSize: 20, total: 0, current: 0 });
	const userListPagination = ref({ pageNo: 1, pageLimit: 20 });
	const { height } = useScreenSize();

	const columnsData: ColumnsData[] = [
		{
			title: "Name",
			dataIndex: "nickName",
			key: "nickName",
			width: 250,
			customFilterDropdown: true,
		},
		{
			title: "Email Id",
			dataIndex: "email",
			key: "email",
			width: 300,
		},
		{
			title: "Subject",
			dataIndex: "reason",
			key: "reason",
			align: "center",
			width: 300,
		},
		{
			title: "Last Updated Dt",
			dataIndex: "modifiedAt",
			key: "modifiedAt",
			align: "center",
			width: 150,
		},
		{
			title: "Feedback Dt",
			dataIndex: "createdAt",
			key: "createdAt",
			align: "center",
			width: 150,
		},
		{
			title: "Message from User",
			dataIndex: "text",
			key: "text",
			align: "center",
			width: 300,
		},
		{
			title: "Attachments",
			dataIndex: "attachments",
			key: "attachments",
			align: "center",
			width: 100,
		},
	];

	const modalOpen = ref(false);
	const columns: Ref<ColumnsData[]> = ref<ColumnsData[]>(columnsData);
	const src = ref();
	const openTextModal = ref(false);
	const reportText = ref();

	const handleChange: TableProps["onChange"] = (pagination, filters, sorter: any) => {
		filteredInfo.value = filters;
		userListPagination.value.pageNo = Number(pagination.current);
		userListPagination.value.pageLimit = Number(pagination.pageSize);
		fetchData();
	};

	const clearFilters = () => {
		filteredInfo.value = null;
	};

	const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string) => {
		confirm();
		userListFilter.value.search = selectedKeys[0];
		setTimeout(() => {
			fetchData();
		}, 1000);
	};

	const handleReset = (clearFilters: (arg0: { confirm: boolean }) => void, confirm: () => void, dataIndex: string) => {
		confirm();
		clearFilters({ confirm: true });
		userListFilter.value.search = "";
		setTimeout(() => {
			fetchData();
		}, 1000);
	};

	const viewProfile = (record: any) => {
		modalOpen.value = true;
		const mediaItem = record.mediaItems[0];
		let user: any = localStorage.getItem("user");
		if (!user) {
			return;
		}
		user = JSON.parse(user);

		src.value = `${BACKEND_BASE_URL}/api/feedback/view-file/${mediaItem.filename}/${user?._id}`;
	};

	const showModal = (text: string) => {
		openTextModal.value = true;
		reportText.value = text;
	};

	const fetchData = async () => {
		try {
			loading.value = true;
			const apiRes: any = await getFeedbackList({ ...userListPagination.value }, userListFilter.value);
			userList.value = apiRes?.items;
			data.value = await feedbackMapData(userList.value);
			pagination.value.total = apiRes?.total;
			pagination.value.current = Number(userListPagination.value.pageNo);
			loading.value = false;
		} catch (error) {
			loading.value = false;
			console.error("Error fetching data:", error);
		}
	};

	fetchData();
</script>
<style scoped>
	.table-operations {
		display: flex;
		justify-content: flex-end;
		gap: 8px;
	}

	.table-operations > button {
		margin-left: 8px;
	}

	.heading {
		font-size: 16px;
		font-weight: 600;
		margin-right: auto;
		color: #44142a !important;
	}

	.filterInput {
		margin-bottom: 8px;
		display: block;
	}
</style>
