<template>
	<div>
		<a-upload class="clearfix" v-model:file-list="fileList" list-type="picture-card" :remove="handleRemove" :before-upload="beforeUpload" @preview="handlePreview" accept="image/*">
			<div v-if="fileList && fileList.length < 1">
				<plus-outlined />
				<div style="margin-top: 8px">Select File</div>
			</div>
		</a-upload>
		<a-modal :open="previewVisible" :title="previewTitle" :footer="null" @cancel="handleCancel">
			<img alt="example" style="width: 100%" :src="previewImage" />
		</a-modal>
	</div>
</template>

<script lang="ts" setup>
	import { PlusOutlined } from "@ant-design/icons-vue";
	import { ref } from "vue";
	import { BASE_URL_FOR_MEDIA } from "@/.config";
	const { formData, newUploads, deleteFile } = defineProps(["formData", "newUploads", "deleteFile"]);

	function getBase64(file: File) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}
	const previewVisible = ref(false);
	const previewImage = ref("");
	const previewTitle = ref("");
	const fileList: any = ref([]);

	formData?.profiles &&
		formData?.profiles.map((e: any) => {
			e?.mediaItems.map((element: any, index: number) => {
				fileList.value.push({ uid: index, name: element.filename, url: `${BASE_URL_FOR_MEDIA}${element.filename}` });
			});
		});

	const handleCancel = () => {
		previewVisible.value = false;
		previewTitle.value = "";
	};
	const handlePreview = async (file: any["fileList"][number]) => {
		if (!file.url && !file.preview) {
			file.preview = (await getBase64(file.originFileObj)) as string;
		}
		previewImage.value = file.url || file.preview;
		previewVisible.value = true;
		previewTitle.value = file.name || file.url.substring(file.url.lastIndexOf("/") + 1);
	};

	const handleRemove = (file: any) => {
		formData.image = fileList.length < 0 && "";
		deleteFile.push(file.name);
		const index = fileList.value.indexOf(file);
		const newFileList = fileList.value.slice();
		newFileList.splice(index, 1);
		fileList.value = newFileList;
	};

	const beforeUpload = async (file: any) => {
		newUploads.pop(newUploads.length);
		formData.image = "img";
		fileList.value = [...fileList.value, file];
		newUploads.push(file);
		return false;
	};
</script>
