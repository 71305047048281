<template>
	<div :style="{ position: 'relative', width: '100%' }">
		<div class="mainDiv">
			<div class="subDiv">User Profile</div>
			<div :style="{ padding: '15px' }">
				<div :style="{ marginTop: '15px' }">
					<a-form-item label="Display Name" :style="{ marginBottom: '8px' }">
						<a-input v-model:value="formData.nickName" />
					</a-form-item>
					<a-form-item label="Email Id">
						<a-input v-model:value="formData.identifier" />
					</a-form-item>
					<a-form-item label="Gender">
						<a-radio-group v-model:value="formData.gender">
							<a-radio value="m">Male</a-radio>
							<a-radio value="f">Female</a-radio>
						</a-radio-group>
					</a-form-item>
					<a-form-item label="User Type">
						<a-radio-group v-model:value="radioValue">
							<a-radio value="free">Free</a-radio>
							<a-radio value="pearl">Pearl</a-radio>
							<a-radio value="ruby">Ruby</a-radio>
						</a-radio-group>
					</a-form-item>
					<a-form-item label="Height">
						<div style="display: flex; gap: 10px">
							<a-input type="number" v-model:value="formData.height" step="0" addon-after="cm" style="width: 122px" />
							<a-input style="width: 122px" disabled v-model:value="formData.heightInFeet" />
						</div>
					</a-form-item>
					<a-form-item label="Diet Options">
						<a-select v-model:value="formData.diet_options">
							<a-select-option value="vegetarian">Vegetarian</a-select-option>
							<a-select-option value="vegan">Vegan</a-select-option>
							<a-select-option value="omnivore">Meat based diet</a-select-option>
							<a-select-option value="pescatarian">Pescatarian</a-select-option>
							<a-select-option value="other">Other</a-select-option>
							<a-select-option value="not_answer">I'd rather not answer</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="Religion">
						<a-select v-model:value="formData.religiosity">
							<a-select-option value="not">Not at all</a-select-option>
							<a-select-option value="very">Yes, I'm religious</a-select-option>
							<a-select-option value="believer">Believer, but not religious</a-select-option>
							<a-select-option value="spiritual">Spiritual, rather than religious</a-select-option>
							<a-select-option value="not_answer">I'd rather not answer</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="Drinking">
						<a-select v-model:value="formData.drinking">
							<a-select-option value="never">Not for me</a-select-option>
							<a-select-option value="occasional">Occasionally</a-select-option>
							<a-select-option value="special">On special occasions</a-select-option>
							<a-select-option value="social">Socially on weekends</a-select-option>
							<a-select-option value="often">Often</a-select-option>
							<a-select-option value="most_nights">Most nights</a-select-option>
							<a-select-option value="not_answer">I'd rather not answer</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="Smoking">
						<a-select v-model:value="formData.smoking">
							<a-select-option value="never">Non-Smoker</a-select-option>
							<a-select-option value="customary">Smoker</a-select-option>
							<a-select-option value="social">Social Smoker</a-select-option>
							<a-select-option value="occasional">Occasionally</a-select-option>
							<a-select-option value="not_answer">I'd rather not answer</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="Workout">
						<a-select v-model:value="formData.workout">
							<a-select-option value="everyday">Everyday</a-select-option>
							<a-select-option value="sometimes">Sometimes</a-select-option>
							<a-select-option value="often">Often</a-select-option>
							<a-select-option value="rarely">Rarely</a-select-option>
							<a-select-option value="never">Never</a-select-option>
							<a-select-option value="not_answer">I'd rather not answer</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="Birth-chart type">
						<a-select v-model:value="formData.birth_chart_type">
							<a-select-option value="ei">East Indian</a-select-option>
							<a-select-option value="si">South Indian</a-select-option>
							<a-select-option value="ni"> North Indian</a-select-option>
							<a-select-option value="w"> Western Astrology</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="Birth-chart symbol">
						<a-select v-model:value="formData.birth_chart_symbol">
							<a-select-option value="ws"> Symbols</a-select-option>
							<a-select-option value="hi"> Devanagari</a-select-option>
							<a-select-option value="gu"> Gujarati</a-select-option>
							<a-select-option value="pa"> Punjabi</a-select-option>
							<a-select-option value="bn"> Bengali</a-select-option>
							<a-select-option value="ta"> Tamil</a-select-option>
							<a-select-option value="ml"> Malyalam</a-select-option>
							<a-select-option value="kn"> Kannada</a-select-option>
							<a-select-option value="te"> Telugu</a-select-option>
							<a-select-option value="bo"> Tibetian</a-select-option>
							<a-select-option value="zh"> Chinese</a-select-option>
							<a-select-option value="en"> English</a-select-option>
							<a-select-option value="ia"> IAST</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="Sidereal/Tropical">
						<a-select v-model:value="formData.birth_chart_sign">
							<a-select-option value="s"> Sidereal</a-select-option>
							<a-select-option value="t"> Tropical</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="Profile Images">
						<a-button :style="{ width: '150px' }" @click="openDrawer" :disabled="false">View Images</a-button>
						<ImageUploadDrawer :openDrawerValue="openDrawerValue" :closeDrawer="closeDrawer" :formData="formData" />
					</a-form-item>

					<a-form-item label="short Intro">
						<a-textarea v-model:value="formData.publicProfileText" :rows="4" :disabled="disable" />
					</a-form-item>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
	import { convertTimestamp } from "@/utils";
	import { ref } from "vue";
	import ImageUploadDrawer from "./ImageUploadDrawer.vue";
	const radioValue = ref("free");
	const disable = ref(false);
	const { formData, openDrawer, closeDrawer, openDrawerValue } = defineProps(["formData", "openDrawer", "closeDrawer", "openDrawerValue"]);
</script>
<style scoped>
	.mainDiv {
		border: 2px solid rgb(190, 190, 190);
		position: relative;
		border-radius: 8px;
		.ant-form-item {
			margin-bottom: 8px !important;
		}
	}

	.subDiv {
		display: flex;
		position: absolute;
		top: -12px;
		background: #ffff;
		margin-left: 20px;
		padding: 0 5px;
		font-weight: 700;
	}
</style>
