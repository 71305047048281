<template>
	<div :style="{ position: 'relative', marginLeft: '10px', width: '100%' }">
		<div class="mainDiv">
			<div class="subDiv">About & Settings</div>
			<div :style="{ padding: '15px' }">
				<div :style="{ marginTop: '25px' }">
					<a-form-item label="Age range">
						<div style="display: flex; gap: 10px">
							<a-input type="number" :value="formData.age_range?.[0]" @input="(value :any) => formData.age_range && (formData.age_range[0] = value)" step="0" addon-before="from" style="width: 122px" />
							<a-input type="number" :value="formData.age_range?.[1]" @input="(value :any) => formData.age_range && (formData.age_range[1] = value)" step="0" addon-before="to" style="width: 122px" />
							<!-- <a-input type="number" v-model:value="formData.age_range?.[0]" step="0" addon-before="from" style="width: 122px" />
							<a-input type="number" v-model:value="formData.age_range?.[1]" step="0" addon-before="to" style="width: 122px" /> -->
						</div>
					</a-form-item>
					<div style="display: flex; justify-content: space-between">
						<div style="width: 65%">
							<a-form-item label="Orientation">
								<a-select v-model:value="formData.orientation">
									<a-select-option value="s">Straight</a-select-option>
									<a-select-option value="g">gay</a-select-option>
									<a-select-option value="b">Bi-sexual</a-select-option>
									<a-select-option value="o">Other</a-select-option>
								</a-select>
							</a-form-item>
						</div>
						<div style="width: 33%">
							<a-form-item>
								<a-select v-model:value="formData.orientation_public_element">
									<a-select-option value="public">Public</a-select-option>
									<a-select-option value="private">Private</a-select-option>
								</a-select>
							</a-form-item>
						</div>
					</div>

					<div style="display: flex; justify-content: space-between">
						<div style="width: 65%">
							<a-form-item label="Rel. Status">
								<a-select v-model:value="formData.relationship_status">
									<a-select-option value="single">Single</a-select-option>
									<a-select-option value="engaged">Engaged</a-select-option>
									<a-select-option value="married">Married</a-select-option>
									<a-select-option value="separated">Separated</a-select-option>
									<a-select-option value="divorced">Divorced</a-select-option>
								</a-select>
							</a-form-item>
						</div>
						<div style="width: 33%">
							<a-form-item>
								<a-select v-model:value="formData.relationship_public_element">
									<a-select-option value="public">Public</a-select-option>
									<a-select-option value="private">Private</a-select-option>
								</a-select>
							</a-form-item>
						</div>
					</div>

					<div style="display: flex; justify-content: space-between">
						<div style="width: 65%">
							<a-form-item label="Residence">
								<a-input v-model:value="formData.residence" :disabled="disable" />
							</a-form-item>
						</div>
						<div style="width: 33%">
							<a-form-item>
								<a-select v-model:value="formData.residence_public_element" :disabled="disable">
									<a-select-option value="public">Public</a-select-option>
									<a-select-option value="private">Private</a-select-option>
								</a-select>
							</a-form-item>
						</div>
					</div>

					<div style="display: flex; justify-content: space-between">
						<div style="width: 65%">
							<a-form-item label="Profession">
								<a-input v-model:value="formData.profession" :disabled="disable" />
							</a-form-item>
						</div>
						<div style="width: 33%">
							<a-form-item>
								<a-select v-model:value="formData.profession_public_element" :disabled="disable">
									<a-select-option value="public">Public</a-select-option>
									<a-select-option value="private">Private</a-select-option>
								</a-select>
							</a-form-item>
						</div>
					</div>

					<div style="display: flex; justify-content: space-between">
						<div style="width: 65%">
							<a-form-item label="Max. Dist.">
								<a-input v-model:value="formData.max_distance" />
							</a-form-item>
						</div>
						<div style="width: 33%">
							<a-form-item>
								<a-select v-model:value="formData.distance_type">
									<a-select-option value="KM">KM</a-select-option>
									<a-select-option value="Miles">Miles</a-select-option>
								</a-select>
							</a-form-item>
						</div>
					</div>

					<a-form-item label="Want to meet">
						<a-select v-model:value="formData.genders">
							<a-select-option value="m">Male</a-select-option>
							<a-select-option value="f">Female</a-select-option>
							<a-select-option value="b">Both</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="Purpose">
						<a-select v-model:value="formData.purpose">
							<a-select-option value="casual_dating">Casual dating</a-select-option>
							<a-select-option value="long_term_dating">Long term dating</a-select-option>
							<a-select-option value="friendship">Friendship</a-select-option>
							<a-select-option value="networking">Networking</a-select-option>
							<a-select-option value="other">Other</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="Jungian Type">
						<a-select v-model:value="formData.jungian_type">
							<a-select-option value="ISFJ">ISFJ</a-select-option>
							<a-select-option value="INFJ">INFJ</a-select-option>
							<a-select-option value="INTJ">INTJ</a-select-option>
							<a-select-option value="ISTP">ISTP</a-select-option>
							<a-select-option value="ISFP">ISFP</a-select-option>
							<a-select-option value="INFP">INFP</a-select-option>
							<a-select-option value="INTP">INTP</a-select-option>
							<a-select-option value="ESTJ">ESTJ</a-select-option>
							<a-select-option value="ESFJ">ESFJ</a-select-option>
							<a-select-option value="ENFJ">ENFJ</a-select-option>
							<a-select-option value="ENTJ">ENTJ</a-select-option>
							<a-select-option value="ESTP">ESTP</a-select-option>
							<a-select-option value="ESFP">ESFP</a-select-option>
							<a-select-option value="ENFP">ENFP</a-select-option>
							<a-select-option value="ENTP">ENTP</a-select-option>
						</a-select>
					</a-form-item>

					<div :style="{ display: 'flex' }">
						<a-form-item label="Hide From Explore">
							<a-switch v-model:checked="formData.hide_from_explore" />
						</a-form-item>
					</div>
					<div>
						<a-form-item label="Show when Active">
							<a-switch v-model:checked="formData.show_online_status" />
						</a-form-item>
					</div>
				</div>
			</div>
		</div>
		<div :style="{ marginTop: '15px' }" class="mainDiv">
			<div class="subDiv">User Popularity</div>
			<div :style="{ padding: '29px' }">
				<div :style="{ display: 'flex', justifyContent: 'space-between' }">
					<div><b>Likes</b> : {{ formData.liked || 0 }}</div>
					<div><b>Reported</b> : {{ formData.reportedCount || 0 }}</div>
				</div>
				<div :style="{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }">
					<div><b>Super-Likes</b> : {{ formData.starred || 0 }}</div>
					<div><b>Blocked</b> : {{ formData.blocked || 0 }}</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
	import { ref } from "vue";
	const { formData, openDrawerValue, popularityCount } = defineProps(["formData", "openDrawerValue", "popularityCount"]);
	const disable = ref(false);
</script>
<style scoped>
	.mainDiv {
		border: 2px solid rgb(190, 190, 190);
		position: relative;
		border-radius: 8px;
		.ant-form-item {
			margin-bottom: 8px !important;
			width: 100%;
		}
	}

	.subDiv {
		display: flex;
		position: absolute;
		top: -12px;
		background: #ffff;
		margin-left: 20px;
		padding: 0 5px;
		font-weight: 700;
	}
</style>
