<template>
	<div :style="{ position: 'relative', width: '100%' }">
		<div :style="{ width: '100%' }" class="mainDiv">
			<div class="subDiv">User Profile</div>
			<div :style="{ padding: '10px' }">
				<div :style="{ marginTop: '10px' }">
					<a-form-item label="Display Name" name="fullName" :style="{ marginBottom: '8px' }" :rules="[{ required: true, message: 'Please input your full name!' }]">
						<a-input v-model:value="formData.fullName" />
					</a-form-item>
					<a-form-item label="Gender" name="gender" :rules="[{ required: true, message: 'Please select your gender!' }]">
						<a-radio-group v-model:value="formData.gender">
							<a-radio value="m">Male</a-radio>
							<a-radio value="f">Female</a-radio>
						</a-radio-group>
					</a-form-item>
					<!-- <a-form-item label="Region" name="georegion" :rules="[{ required: true, message: 'Please input your region!' }]">
						<a-input v-model:value="formData.georegion" />
					</a-form-item> -->
					<a-form-item label="Pref cul. regions" name="celebrityRegion" :rules="[{ required: true, message: 'Please select your preferred cultural regions!' }]">
						<a-select v-model:value="formData.celebrityRegion">
							<a-select-option value="west">West</a-select-option>
							<a-select-option value="far_east">Far East</a-select-option>
							<a-select-option value="east">East</a-select-option>
							<a-select-option value="southwest">South West</a-select-option>
							<a-select-option value="south">South</a-select-option>
							<a-select-option value="notb">Notb</a-select-option>
							<a-select-option value="IN">IN</a-select-option>
							<a-select-option value="IN-N">IN-N</a-select-option>
							<a-select-option value="IN-S">IN-S</a-select-option>
							<a-select-option value="IN-E">IN-E</a-select-option>
							<a-select-option value="IN-W">IN-W</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="Height" name="height" :rules="[{ required: true, message: 'Please input your height!' }]">
						<a-input v-model:value="formData.height" type="number" step="0.01" addon-after="cm" />
					</a-form-item>
					<a-form-item label="Profession" :style="{ marginBottom: '8px' }" name="profession" :rules="[{ required: true, message: 'Please input your profession!' }]">
						<a-input v-model:value="formData.profession" />
					</a-form-item>
					<a-form-item label="Rodden" :style="{ marginBottom: '8px' }" name="rodden" :rules="[{ required: true, message: 'Please input your rodden!' }]">
						<a-select v-model:value="formData.rodden">
							<a-select-option value="AA">AA</a-select-option>
							<a-select-option value="A">A</a-select-option>
							<a-select-option value="B">B</a-select-option>
							<a-select-option value="C">C</a-select-option>
							<a-select-option value="X">X</a-select-option>
							<a-select-option value="XX">XX</a-select-option>
							<a-select-option value="DD">DD</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="Jungian Type" name="jungian_type" :rules="[{ required: true, message: 'Please select your jungian type!' }]">
						<a-select v-model:value="formData.jungian_type" :options="jungianType"> </a-select>
					</a-form-item>
					<a-form-item label="Short Intro" name="publicProfileText" :rules="[{ required: true, message: 'Please input your short intro!' }]">
						<a-textarea v-model:value="formData.publicProfileText" :rows="4" />
					</a-form-item>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
	const { formData } = defineProps(["formData"]);

	const jungianType = [
		{ name: "ISTJ", value: "ISTJ" },
		{ name: "ISFJ", value: "ISFJ" },
		{ name: "INFJ", value: "INFJ" },
		{ name: "INTJ", value: "INTJ" },
		{ name: "ISTP", value: "ISTP" },
		{ name: "ISFP", value: "ISFP" },
		{ name: "INFP", value: "INFP" },
		{ name: "INTP", value: "INTP" },
		{ name: "ESTJ", value: "ESTJ" },
		{ name: "ESFJ", value: "ESFJ" },
		{ name: "ENFJ", value: "ENFJ" },
		{ name: "ENTJ", value: "ENTJ" },
		{ name: "ESTP", value: "ESTP" },
		{ name: "ESFP", value: "ESFP" },
		{ name: "ENFP", value: "ENFP" },
		{ name: "ENTP", value: "ENTP" },
	];
</script>
<style scoped>
	.mainDiv {
		border: 2px solid rgb(190, 190, 190);
		position: relative;
		border-radius: 8px;
		.ant-form-item {
			margin-bottom: 8px !important;
		}
	}

	.subDiv {
		display: flex;
		position: absolute;
		top: -12px;
		background: #ffff;
		margin-left: 20px;
		padding: 0 5px;
		font-weight: 700;
	}
</style>
