<template>
	<a-table
		size="middle"
		@change="handleChange"
		:columns="columns"
		:data-source="props.pairArray"
		:loading="props.loading"
		:pagination="{
				pageSize: pagination.pageSize,
				total: pagination.total,
				position: ['topRight'],
				size: 'small',
				showTotal: (total:any, range:any) => `${range[0]}-${range[1]} of ${total}`,
				showSizeChanger: false, 
			}"
		:scroll="{ x: 1, y: height - 340 }"
		class="tableBody"
	>
		<template #bodyCell="{ text, column, record }">
			<template v-if="column && column.dataIndex === '_id'">
				<a @click="singlePair(record._id)"> {{ record.userA.name }}/{{ record.userB.name }} </a>
			</template>
			<template v-if="column.dataIndex !== '_id' && text">
				<div>{{ text.toFixed(2) }}%</div>
			</template>
		</template>
	</a-table>
</template>

<script setup lang="ts">
	import { ColumnsData } from "@/interface/Members.interface";
	import router from "@/router";
	import { useScreenSize } from "@/utils";
	import { TableProps } from "ant-design-vue";
	import { Ref, ref, toRefs } from "vue";

	const props: any = defineProps(["pairArray", "multiplePairList", "fetchMultilePairData", "pagination", "loading", "singlePairDetails"]);

	const { multiplePairList, pagination }: any = toRefs(props);

	const { height } = useScreenSize();

	const singlePair = async (id: any) => {
		await localStorage.setItem("multiPairList", JSON.stringify({ ...multiplePairList.value, ...props.singlePairDetails }));
		router.push(`singlePair/${id}`);
	};

	const handleChange: TableProps["onChange"] = (pagination) => {
		multiplePairList.value.pageNo = pagination.current;
		multiplePairList.value.pageLimit = pagination.pageSize;
		props.fetchMultilePairData(multiplePairList.value.protocolId, multiplePairList.value.groupId, multiplePairList.value.pageNo, multiplePairList.value.pageLimit);
	};

	const columnsData: ColumnsData[] = [
		{
			title: "Pair IDs",
			dataIndex: "_id",
			key: "_id",
			width: 200,
		},
		{
			title: "Communication",
			dataIndex: "communication",
			key: "communication",
			width: 120,
		},
		{
			title: "Connection",
			dataIndex: "connection",
			key: "connection",
			width: 120,
		},
		{
			title: "Energy",
			dataIndex: "energy",
			key: "energy",
			width: 120,
		},
		{
			title: "Stability",
			dataIndex: "stability",
			key: "stability",
			width: 120,
		},
		{
			title: "Guna Milan",
			dataIndex: "guna",
			key: "guna",
			width: 120,
		},
		{
			title: "Score",
			dataIndex: "score",
			key: "score",
			width: 120,
		},
	];

	const columns: Ref<ColumnsData[]> = ref<ColumnsData[]>(columnsData);
</script>

<style scoped>
	.tableBody {
		width: 50%;
		margin-bottom: 20px;
	}
</style>
