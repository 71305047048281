<template>
	<div class="mainDiv" :style="{ marginBottom: '20px' }">
		<div class="subDiv">Synastry Category Text</div>
		<table class="synastry-table">
			<thead>
				<tr>
					<th></th>
					<th>></th>
					<th>{{ "<=" }}</th>
					<th>{{ ">%" }}</th>
					<th>{{ "<=%" }}</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(i, e) in synastryCategoryArray">
					<td :style="{ width: '15%' }">{{ i.label }}</td>
					<td><a-input type="number" :style="{ textAlign: 'center' }" :value="synastryCategoryArray[e + 1] ? synastryCategoryArray[e + 1].val2 : 0" :disabled="true"></a-input></td>
					<td><a-input type="number" :style="{ textAlign: 'center' }" v-model:value="i.val2" :disabled="e == 0" @change="(e:any)=>onChange(i,'val2',e)"></a-input></td>
					<td><a-input type="number" :style="{ textAlign: 'center' }" :value="synastryCategoryArray[e + 1] ? (synastryCategoryArray[e + 1].val2 * 100) / 4 : 0" :disabled="true"></a-input></td>
					<td><a-input type="number" :style="{ textAlign: 'center' }" :value="(i.val2 * 100) / 4" :disabled="true"></a-input></td>
				</tr>
			</tbody>
		</table>
	</div>

	<div class="mainDiv" :style="{ marginBottom: '20px' }">
		<div class="subDiv">Heart Logo</div>
		<table class="synastry-table">
			<thead>
				<tr>
					<th></th>
					<th>></th>
					<th>{{ "<=" }}</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>Very high</td>
					<td><a-input type="number" :style="{ textAlign: 'center' }" v-model:value="heartLogoArray.veryHigh.val1"></a-input></td>
					<td><a-input type="number" :style="{ textAlign: 'center' }" value="100" :disabled="true"></a-input></td>
				</tr>
				<tr>
					<td>High</td>
					<td><a-input type="number" :style="{ textAlign: 'center' }" v-model:value="heartLogoArray.high.val1"></a-input></td>
					<td><a-input type="number" :style="{ textAlign: 'center' }" :value="heartLogoArray.veryHigh.val1" :disabled="true"></a-input></td>
				</tr>
				<tr>
					<td>Medium</td>
					<td><a-input type="number" :style="{ textAlign: 'center' }" :value="heartLogoArray.veryLow.val2" :disabled="true"></a-input></td>
					<td><a-input type="number" :style="{ textAlign: 'center' }" :value="heartLogoArray.high.val1" :disabled="true"></a-input></td>
				</tr>
				<tr>
					<td>Very Low</td>
					<td><a-input type="number" :style="{ textAlign: 'center' }" value="0" :disabled="true"></a-input></td>
					<td><a-input type="number" :style="{ textAlign: 'center' }" v-model:value="heartLogoArray.veryLow.val2"></a-input></td>
				</tr>
			</tbody>
		</table>
	</div>

	<div class="mainDiv">
		<div class="subDiv">Synastry vs. GM</div>
		<table class="synastry-table">
			<thead>
				<tr>
					<th></th>
					<th>%</th>
					<th>%</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="i in synastryVsGmArray">
					<td>{{ i.label }}</td>
					<td><a-input type="number" :style="{ textAlign: 'center' }" v-model:value="i.val1"></a-input></td>
					<td><a-input type="number" :style="{ textAlign: 'center' }" :value="i.val1 * 100" :disabled="true"></a-input></td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script setup lang="ts">
	import { toRefs } from "vue";

	const props: any = defineProps(["synastryCategoryArray", "heartLogoArray", "synastryVsGmArray"]);
	const { synastryCategoryArray, heartLogoArray, synastryVsGmArray } = toRefs(props);

	const onChange = (i: any, val: any, e: any) => {
		for (const key in synastryCategoryArray.value) {
			const element = synastryCategoryArray.value[key];
			if (e.target.value > 4) {
				if (element.label == i.label) {
					element[val] = 4;
				}
			}
		}
	};
</script>

<style scoped>
	.synastry-table tbody tr td {
		padding: 2px 10px;
	}

	.synastry-table tbody tr td:first-child {
		border: 0px;
		text-align: end;
	}
	.mainDiv {
		border: 2px solid rgb(190, 190, 190);
		position: relative;
		border-radius: 8px;
		padding: 20px;
	}

	.subDiv {
		display: flex;
		position: absolute;
		top: -12px;
		background: #ffff;
		margin-left: 20px;
		padding: 0 5px;
		font-weight: 700;
	}
</style>
