<template>
	<div :style="{ position: 'relative' }">
		<div class="mainDiv">
			<div class="subDiv">User Profile</div>
			<div :style="{ padding: '15px' }">
				<div :style="{ marginTop: '15px' }">
					<a-form-item label="Display Name" name="fullName" :style="{ marginBottom: '8px' }" :rules="[{ required: true, message: 'Please enter display name!' }]">
						<a-input v-model:value="formData.fullName" />
					</a-form-item>
					<a-form-item label="Email Id" name="identifier" :rules="[{ required: true, message: 'Please enter email id!' }]">
						<a-input v-model:value="formData.identifier" />
					</a-form-item>
					<div v-if="adminAdd">
						<a-form-item label="Password" name="password" :rules="[{ required: true, validator: validatePass }]">
							<a-input-password v-model:value="formData.password" autocomplete="password" />
						</a-form-item>
						<a-form-item label="Confirm Password" name="cPassword" :rules="[{ required: true, validator: validatePass2 }]">
							<a-input-password v-model:value="formData.cPassword" autocomplete="cPassword" />
						</a-form-item>
					</div>
					<a-form-item label="Gender" name="gender" :rules="[{ required: true, message: 'Please select gender!' }]">
						<a-radio-group v-model:value="formData.gender">
							<a-radio value="m">Male</a-radio>
							<a-radio value="f">Female</a-radio>
						</a-radio-group>
					</a-form-item>
					<a-form-item label="User Type" name="roles" :rules="[{ required: true, message: 'Please select role!' }]">
						<a-select v-model:value="formData.roles">
							<a-select-option value="admin">Admin</a-select-option>
							<a-select-option value="superadmin">Super Admin</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="Profession" name="profession" :style="{ marginBottom: '8px' }" :rules="[{ required: true, message: 'Please enter Profession!' }]">
						<a-input v-model:value="formData.profession" />
					</a-form-item>
					<div v-if="!adminAdd">
						<a-form-item label="Joined">
							<a-typography-text class="ant-form-text" type="secondary">{{ formData.createdAt && dayjs.utc(formData.createdAt).format("DD-MMM-YYYY HH:mm") }} </a-typography-text>
						</a-form-item>
						<a-form-item label="Last Edited">
							<a-typography-text class="ant-form-text" type="secondary"> {{ formData.modifiedAt && dayjs.utc(formData.modifiedAt).format("DD-MMM-YYYY HH:mm") }} </a-typography-text>
						</a-form-item>
						<a-form-item label="Last Login">
							<a-typography-text class="ant-form-text" type="secondary">{{ formData.modifiedAt && dayjs.utc(formData.modifiedAt).format("DD-MMM-YYYY HH:mm") }} </a-typography-text>
						</a-form-item>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
	import { convertTimestamp } from "@/utils";
	import dayjs from "dayjs";
	import type { Rule } from "ant-design-vue/es/form";
	const { formData, adminAdd } = defineProps(["formData", "adminAdd"]);

	const validatePass = async (_rule: Rule, value: string) => {
		if (value === "") {
			return Promise.reject("Please input the password");
		} else {
			return Promise.resolve();
		}
	};
	const validatePass2 = async (_rule: Rule, value: string) => {
		if (value === "") {
			return Promise.reject("Please input the password");
		} else if (value !== formData.password) {
			return Promise.reject("Two inputs don't match!");
		} else {
			return Promise.resolve();
		}
	};
</script>
<style scoped>
	.mainDiv {
		width: 100%;
		height: 100%;
		border: 2px solid rgb(190, 190, 190);
		position: relative;
		border-radius: 8px;
		.ant-form-item {
			margin-bottom: 8px !important;
		}
	}

	.subDiv {
		display: flex;
		position: absolute;
		top: -12px;
		background: #ffff;
		margin-left: 20px;
		padding: 0 5px;
		font-weight: 700;
	}
</style>
