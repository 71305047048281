<template>
	<div v-if="loading" class="loading-wrapper">
		<a-spin />
	</div>
	<div v-if="!loading" class="AppSettingContainer">
		<div class="table-operations">
			<a-breadcrumb class="heading" separator="/">
				<a-breadcrumb-item>App Settings</a-breadcrumb-item>
			</a-breadcrumb>
			<a-button type="primary" class="saveBtn" @click="saveData">Save</a-button>
		</div>
		<div class="mainContainer">
			<div class="mainDiv">
				<div class="subDiv">
					<span :style="{ margin: '0px 5px' }"> Likes Settings </span>
				</div>
				<div class="detailsContainer" :style="{ margin: '25px 0px' }">
					<span>1. Likes bucket reset interval (minutes)</span>
					<span>
						<a-input type="number" class="input" v-model:value="likeResetInterval" addon-after="mins"></a-input>
					</span>
				</div>
				<div class="detailsContainer" :style="{ margin: '25px 0px' }">
					<span>2. Likes Bucket Limit (#)</span>
					<span>
						<a-input type="number" class="input" v-model:value="likeBucketLimit"></a-input>
					</span>
				</div>
			</div>
			<div class="mainDiv">
				<div class="subDiv">
					<span :style="{ margin: '0px 5px' }"> SuperSpark Settings </span>
				</div>
				<div class="detailsContainer" :style="{ margin: '25px 0px' }">
					<span>1. SuperSpark bucket reset interval (minutes)</span>
					<span>
						<a-input type="number" class="input" v-model:value="superSparkResetInterval" addon-after="mins"></a-input>
					</span>
				</div>
				<div class="detailsContainer" :style="{ margin: '25px 0px' }">
					<span>2. SuperSpark Bucket Limit (#)</span>
					<span>
						<a-input type="number" class="input" v-model:value="superSparkBucketLimit"></a-input>
					</span>
				</div>
			</div>
			<div class="mainDiv">
				<div class="subDiv">
					<span :style="{ margin: '0px 5px' }"> Swipe Members Settings </span>
				</div>
				<div class="detailsContainer" :style="{ margin: '25px 0px' }">
					<span>1. Passed member repeat interval (minutes)</span>
					<span>
						<a-input type="number" class="input" v-model:value="passesResetInterval" addon-after="mins"></a-input>
					</span>
				</div>
			</div>
			<div class="mainDiv">
				<div class="subDiv">
					<span :style="{ margin: '0px 5px' }">Permanently Delete Members Settings</span>
				</div>
				<div class="detailsContainer" :style="{ marginBottom: '30px', marginTop: '10px' }">
					<span>1. No. of Days to wait for Permanently Delete Member (#)</span>
					<div>
						<a-input type="number" class="input" v-model:value="deletedMemberArchiveDays" addon-after="days"></a-input>
					</div>
				</div>
			</div>
			<div class="mainDiv">
				<div class="subDiv">
					<span :style="{ margin: '0px 5px' }">Dormant Time Setting </span>
				</div>
				<div class="detailsContainer" :style="{ marginBottom: '30px', marginTop: '10px' }">
					<span>1. Dormant Time setting For user </span>
					<div>
						<a-input type="number" class="input" v-model:value="dormantTimeLimit" addon-after="days"></a-input>
					</div>
				</div>
			</div>
			<div class="mainDiv">
				<div class="subDiv">
					<span :style="{ margin: '0px 5px' }"> App service</span>
				</div>
				<div class="detailsContainer" :style="{ marginBottom: '30px', marginTop: '10px' }">
					<span>OTP services</span>
					<div>
						<a-switch v-model:checked="otpServices" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
	import { ref } from "vue";
	import { getListAppSetting, putAppSetting } from "../services/api/user";
	import { openNotificationWithIcon } from "@/utils";
	const loading = ref(false);
	const likeResetInterval: any = ref(0);
	const superSparkResetInterval: any = ref(0);
	const passesResetInterval: any = ref(0);
	const likeBucketLimit: any = ref(0);
	const superSparkBucketLimit: any = ref(0);
	const deletedMemberArchiveDays: any = ref(0);
	const dormantTimeLimit: any = ref(0);
	const otpServices = ref(false);

	const fetchData = async () => {
		loading.value = true;
		const appSettingsRes = await getListAppSetting();
		await appSettingsRes.items.forEach((appSettings: any) => {
			if (appSettings.key == "permission_limits") {
				for (let i = 0; i < appSettings.value?.length; i++) {
					const appSetting = appSettings.value[i];
					if (appSetting?.key === "basic_swipe_like") {
						likeBucketLimit.value = appSetting.value;
					}
					if (appSetting?.key === "basic_swipe_superstar") {
						superSparkBucketLimit.value = appSetting.value;
					}
				}
			}
			if (appSettings.key == "deleted_users_archived_min") {
				deletedMemberArchiveDays.value = appSettings.value / 60 / 24;
			}
			if (appSettings.key == "members__like_reset_min") {
				likeResetInterval.value = appSettings.value;
			}
			if (appSettings.key == "members__superSpark_reset_min") {
				superSparkResetInterval.value = appSettings.value;
			}
			if (appSettings.key == "members__repeat_interval") {
				passesResetInterval.value = appSettings.value;
			}
			if (appSettings.key == "otp_service") {
				otpServices.value = appSettings.value;
			}
			if (appSettings.key == "dormant_time_limit") {
				dormantTimeLimit.value = appSettings.value;
			}
		});
		loading.value = false;
	};
	fetchData();

	const saveData = async () => {
		if (likeResetInterval.value < 1 || passesResetInterval.value < 1 || likeBucketLimit.value < 1 || superSparkBucketLimit.value < 1 || deletedMemberArchiveDays.value < 1) {
			openNotificationWithIcon("error", "Allow only more than 0 value");
			return;
		}
		try {
			const data: any = [
				{ key: "members__repeat_interval", value: passesResetInterval.value },
				{ key: "members__like_reset_min", value: likeResetInterval.value },
				{ key: "members__superSpark_reset_min", value: superSparkResetInterval.value },
				{ key: "deleted_users_archived_min", value: deletedMemberArchiveDays.value * 60 * 24 },
				{
					key: "permission_limits",
					value: [
						{ key: "basic_swipe_like", value: likeBucketLimit.value },
						{ key: "basic_swipe_superstar", value: superSparkBucketLimit.value },
					],
				},
				{ key: "otp_service", value: otpServices.value },
				{ key: "dormant_time_limit", value: dormantTimeLimit.value },
			];
			await putAppSetting(data);
			await fetchData();
			openNotificationWithIcon("success", "App settings updated successfully");
		} catch (error) {
			console.log(error);
			openNotificationWithIcon("error", "Something want wrong");
		}
	};
</script>
<style scoped>
	.AppSettingContainer {
		padding: 20px 40px;
	}
	.table-operations {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 30px;
	}
	.heading {
		font-size: 16px;
		font-weight: 600;
		margin-right: auto;
		color: #44142a !important;
	}
	.mainContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 5px;
	}
	.mainDiv {
		padding: 20px;
		border: 2px solid rgb(190, 190, 190);
		position: relative;
		border-radius: 6px;
		width: 55%;
		margin-bottom: 25px;
	}
	.subDiv {
		display: flex;
		position: absolute;
		top: -11px;
		background: #ffff;
		margin-left: 20px;
		padding: 0 5px;
		font-weight: 700;
		font-size: 15px;
	}
	.detailsContainer {
		display: flex;
		justify-content: space-between;
		font-size: 14px;
	}
	.input {
		height: 2rem;
		width: 8rem;
	}
	.loading-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;
	}
	.saveBtn {
		width: 100px;
	}
</style>
