<template>
	<a-drawer :open="drawerOpen" @close="closeDrawer" title="Add Columns" width="750px">
		<div :style="{ display: 'flex' }">
			<a-checkbox v-model:checked="userProfileCheckAll" :indeterminate="userProfileIndeterminate" @change="userProfileChange" />
			<h3 class="heading">User Profile</h3>
		</div>
		<a-checkbox-group v-model:value="selectedUserProfileColumns" @change="(user: string)=>checkboxChanged(user)">
			<div class="chechBoxGroup">
				<div v-for="column in columns.filter((colum:any) => colum.category == 'userProfile')" :key="column.key" :style="{ width: '200px', margin: '5px' }">
					<a-checkbox :value="column.key" class="checkBox" :disabled="column.key == 'nickName'" :style="{ border: column.key == 'nickName' ? '1px solid grey' : ' 1px solid #44142a' }">{{ column.title }}</a-checkbox>
				</div>
			</div>
		</a-checkbox-group>

		<div :style="{ display: 'flex' }">
			<a-checkbox v-model:checked="accountStatusCheckAll" :indeterminate="accountStatusIndeterminate" @change="accountStatusChange" />
			<h3 class="heading">Account Status</h3>
		</div>
		<a-checkbox-group v-model:value="selectedAccountStatusColumns" @change="(user: string)=>accountStatusCheckboxChanged(user)">
			<div class="chechBoxGroup">
				<div v-for="column in columns.filter((colum:any) => colum.category == 'accountStatus')" :key="column.key" :style="{ width: '200px', margin: '5px' }">
					<a-checkbox :value="column.key" class="checkBox">{{ column.title }}</a-checkbox>
				</div>
			</div>
		</a-checkbox-group>

		<div :style="{ display: 'flex' }">
			<a-checkbox v-model:checked="userSettingCheckAll" :indeterminate="userSettingIndeterminate" @change="userSettingChange" />
			<h3 class="heading">User Settings</h3>
		</div>
		<a-checkbox-group v-model:value="selectedUserSettingColumns" @change="(user: string)=>userSettingCheckboxChanged(user)">
			<div class="chechBoxGroup">
				<div v-for="column in columns.filter((colum:any) => colum.category == 'userSetting')" :key="column.key" :style="{ width: '200px', margin: '5px' }">
					<a-checkbox :value="column.key" class="checkBox">{{ column.title }}</a-checkbox>
				</div>
			</div>
		</a-checkbox-group>

		<div :style="{ display: 'flex' }">
			<a-checkbox v-model:checked="notificationSettingsCheckAll" :indeterminate="notificationSettingsIndeterminate" @change="notificationSettingsChange" />
			<h3 class="heading">Notification Settings</h3>
		</div>
		<a-checkbox-group v-model:value="selectedNotificationSettingsColumns" @change="(user: string)=>notificationSettingsCheckboxChanged(user)">
			<div class="chechBoxGroup">
				<div v-for="column in columns.filter((colum:any) => colum.category == 'notificationSettings' )" :key="column.key" :style="{ width: '200px', margin: '5px' }">
					<a-checkbox :value="column.key" class="checkBox">{{ column.title }}</a-checkbox>
				</div>
			</div>
		</a-checkbox-group>

		<div :style="{ display: 'flex' }">
			<a-checkbox v-model:checked="swipeActivitiesCheckAll" :indeterminate="swipeActivitiesIndeterminate" @change="swipeActivitiesChange" />
			<h3 class="heading">Swipe Activities</h3>
		</div>
		<a-checkbox-group v-model:value="selectedSwipeActivitiesColumns" @change="(user: string)=>swipeActivitiesCheckboxChanged(user)">
			<div class="chechBoxGroup">
				<div v-for="column in columns.filter((colum:any) => colum.category == 'swipeActivities' )" :key="column.key" :style="{ width: '200px', margin: '5px' }">
					<a-checkbox :value="column.key" class="checkBox">{{ column.title }}</a-checkbox>
				</div>
			</div></a-checkbox-group
		>

		<div :style="{ display: 'flex' }">
			<a-checkbox v-model:checked="otherActivitiesCheckAll" :indeterminate="otherActivitiesIndeterminate" @change="otherActivitiesChange" />
			<h3 class="heading">Other Activities</h3>
		</div>
		<a-checkbox-group v-model:value="selectedOtherActivitiesColumns" @change="(user: string)=>otherActivitiesCheckboxChanged(user)">
			<div class="chechBoxGroup">
				<div v-for="column in columns.filter((colum:any) => !colum.category )" :key="column.key" :style="{ width: '200px', margin: '5px' }">
					<a-checkbox :value="column.key" class="checkBox">{{ column.title }}</a-checkbox>
				</div>
			</div>
		</a-checkbox-group>

		<div :style="{ display: 'flex' }">
			<a-checkbox v-model:checked="deviceCheckAll" :indeterminate="deviceIndeterminate" @change="deviceChange" />
			<h3 class="heading">Device Information</h3>
		</div>
		<a-checkbox-group v-model:value="selectedDeviceColumns" @change="(user: string)=>deviceCheckboxChanged(user)">
			<div class="chechBoxGroup">
				<div v-for="column in columns.filter((colum:any) => colum.category == 'device'  )" :key="column.key" :style="{ width: '200px', margin: '5px' }">
					<a-checkbox :value="column.key" class="checkBox">{{ column.title }}</a-checkbox>
				</div>
			</div>
		</a-checkbox-group>

		<template #footer>
			<div class="footerClass">
				<a-button size="large" :style="{ width: '20%' }" @click="closeDrawer">Cancel</a-button>
				<a-button type="primary" size="large" @click="saveChanges()" :style="{ width: '20%', marginLeft: '10px' }">Save</a-button>
			</div>
		</template>
	</a-drawer>
</template>

<script lang="ts" setup>
	import { ColumnsData } from "@/interface/Members.interface";
	import { computed, ref, watch } from "vue";
	const { closeDrawer, drawerOpen, columns, resetColumn } = defineProps(["closeDrawer", "drawerOpen", "columns", "resetColumn"]);
	const userProfileCheckAll = ref(true);
	const userProfileIndeterminate = ref(false);
	const selectedUserProfileColumns: any = ref([]);

	const accountStatusCheckAll = ref(false);
	const accountStatusIndeterminate = ref(false);
	const selectedAccountStatusColumns: any = ref([]);

	const userSettingCheckAll = ref(false);
	const userSettingIndeterminate = ref(false);
	const selectedUserSettingColumns: any = ref([]);

	const notificationSettingsCheckAll = ref(false);
	const notificationSettingsIndeterminate = ref(false);
	const selectedNotificationSettingsColumns: any = ref([]);

	const swipeActivitiesCheckAll = ref(false);
	const swipeActivitiesIndeterminate = ref(false);
	const selectedSwipeActivitiesColumns: any = ref([]);

	const otherActivitiesCheckAll = ref(false);
	const otherActivitiesIndeterminate = ref(false);
	const selectedOtherActivitiesColumns: any = ref([]);

	const deviceCheckAll = ref(true);
	const deviceIndeterminate = ref(false);
	const selectedDeviceColumns: any = ref([]);

	columns.map((e: any) => {
		if (e.visible && e.category == "userProfile") {
			selectedUserProfileColumns.value.push(e.key);
		}
		if (e.visible && e.category == "accountStatus") {
			selectedAccountStatusColumns.value.push(e.key);
		}
		if (e.visible && e.category == "userSetting") {
			selectedUserSettingColumns.value.push(e.key);
		}
		if (e.visible && e.category == "notificationSettings") {
			selectedNotificationSettingsColumns.value.push(e.key);
		}
		if (e.visible && e.category == "swipeActivities") {
			selectedSwipeActivitiesColumns.value.push(e.key);
		}
		if (e.visible && !e.category) {
			selectedOtherActivitiesColumns.value.push(e.key);
		}
		if (e.visible && e.category == "device") {
			selectedDeviceColumns.value.push(e.key);
		}
	});

	const saveChanges = async () => {
		const flattenedSelectedColumns = [...selectedDeviceColumns.value, ...selectedOtherActivitiesColumns.value, ...selectedUserProfileColumns.value, ...selectedAccountStatusColumns.value, ...selectedUserSettingColumns.value, ...selectedNotificationSettingsColumns.value, ...selectedSwipeActivitiesColumns.value];
		columns.forEach((column: ColumnsData) => {
			column.visible = flattenedSelectedColumns.includes(column.key);
		});
		resetColumn(columns);
		await localStorage.setItem("columns", JSON.stringify(columns));
		closeDrawer();
	};

	const checkAllAndIndeterminate = () => {
		userProfileCheckAll.value = selectedUserProfileColumns.value.length == 9 ? true : false;
		userProfileIndeterminate.value = selectedUserProfileColumns.value.length < 9 && selectedUserProfileColumns.value.length > 0 ? true : false;
		accountStatusCheckAll.value = selectedAccountStatusColumns.value.length == 5 ? true : false;
		accountStatusIndeterminate.value = selectedAccountStatusColumns.value.length < 5 && selectedAccountStatusColumns.value.length > 0 ? true : false;
		userSettingCheckAll.value = selectedUserSettingColumns.value.length == 9 ? true : false;
		userSettingIndeterminate.value = selectedUserSettingColumns.value.length < 9 && selectedUserSettingColumns.value.length > 0 ? true : false;
		notificationSettingsCheckAll.value = selectedNotificationSettingsColumns.value.length == 4 ? true : false;
		notificationSettingsIndeterminate.value = selectedNotificationSettingsColumns.value.length < 4 && selectedNotificationSettingsColumns.value.length > 0 ? true : false;
		swipeActivitiesCheckAll.value = selectedSwipeActivitiesColumns.value.length == 4 ? true : false;
		swipeActivitiesIndeterminate.value = selectedSwipeActivitiesColumns.value.length < 4 && selectedSwipeActivitiesColumns.value.length > 0 ? true : false;
		otherActivitiesCheckAll.value = selectedOtherActivitiesColumns.value.length == 6 ? true : false;
		otherActivitiesIndeterminate.value = selectedOtherActivitiesColumns.value.length < 6 && selectedOtherActivitiesColumns.value.length > 0 ? true : false;
		deviceCheckAll.value = selectedDeviceColumns.value.length == 6 ? true : false;
		deviceIndeterminate.value = selectedDeviceColumns.value.length < 6 && selectedDeviceColumns.value.length > 0 ? true : false;
	};
	checkAllAndIndeterminate();

	const checkboxChanged = (selectedColum: string) => {
		selectedUserProfileColumns.value = selectedColum;
		checkAllAndIndeterminate();
	};

	const userProfileChange = (e: any) => {
		const userProfileKeys = columns.filter((column: any) => column.category === "userProfile").map((column: any) => column.key);
		if (e.target.checked) {
			userProfileKeys.map((e: any) => {
				if (e != "nickName") {
					selectedUserProfileColumns.value.push(e);
				}
			});
		} else {
			userProfileIndeterminate.value = true;
			selectedUserProfileColumns.value = ["nickName"];
		}
	};

	const accountStatusCheckboxChanged = (selectedColum: string) => {
		selectedAccountStatusColumns.value = selectedColum;
		checkAllAndIndeterminate();
	};

	const accountStatusChange = (e: any) => {
		const accountStatusKeys = columns.filter((column: any) => column.category === "accountStatus").map((column: any) => column.key);
		if (e.target.checked) {
			accountStatusKeys.map((e: any) => {
				selectedAccountStatusColumns.value.push(e);
			});
		} else {
			selectedAccountStatusColumns.value = [];
			checkAllAndIndeterminate();
		}
	};

	const userSettingCheckboxChanged = (selectedColum: string) => {
		selectedUserSettingColumns.value = selectedColum;
		checkAllAndIndeterminate();
	};

	const userSettingChange = (e: any) => {
		const userSettingKeys = columns.filter((column: any) => column.category === "userSetting").map((column: any) => column.key);
		if (e.target.checked) {
			userSettingKeys.map((e: any) => {
				selectedUserSettingColumns.value.push(e);
			});
		} else {
			selectedUserSettingColumns.value = [];
			checkAllAndIndeterminate();
		}
	};

	const notificationSettingsCheckboxChanged = (selectedColum: string) => {
		selectedNotificationSettingsColumns.value = selectedColum;
		checkAllAndIndeterminate();
	};

	const notificationSettingsChange = (e: any) => {
		const notificationSettingsKeys = columns.filter((column: any) => column.category === "notificationSettings").map((column: any) => column.key);
		if (e.target.checked) {
			notificationSettingsKeys.map((e: any) => {
				selectedNotificationSettingsColumns.value.push(e);
			});
		} else {
			selectedNotificationSettingsColumns.value = [];
			checkAllAndIndeterminate();
		}
	};

	const swipeActivitiesCheckboxChanged = (selectedColum: string) => {
		selectedSwipeActivitiesColumns.value = selectedColum;
		checkAllAndIndeterminate();
	};

	const swipeActivitiesChange = (e: any) => {
		const swipeActivitiesKeys = columns.filter((column: any) => column.category === "swipeActivities").map((column: any) => column.key);
		if (e.target.checked) {
			swipeActivitiesKeys.map((e: any) => {
				selectedSwipeActivitiesColumns.value.push(e);
			});
		} else {
			selectedSwipeActivitiesColumns.value = [];
			checkAllAndIndeterminate();
		}
	};

	const otherActivitiesCheckboxChanged = (selectedColum: string) => {
		selectedOtherActivitiesColumns.value = selectedColum;
		checkAllAndIndeterminate();
	};

	const otherActivitiesChange = (e: any) => {
		const otherActivitiesKeys = columns.filter((column: any) => !column.category).map((column: any) => column.key);
		if (e.target.checked) {
			otherActivitiesKeys.map((e: any) => {
				selectedOtherActivitiesColumns.value.push(e);
			});
		} else {
			selectedOtherActivitiesColumns.value = [];
			checkAllAndIndeterminate();
		}
	};

	const deviceCheckboxChanged = (selectedColum: string) => {
		selectedDeviceColumns.value = selectedColum;
		checkAllAndIndeterminate();
	};

	const deviceChange = (e: any) => {
		const deviceKeys = columns.filter((column: any) => column.category == "device").map((column: any) => column.key);
		if (e.target.checked) {
			deviceKeys.map((e: any) => {
				selectedDeviceColumns.value.push(e);
			});
		} else {
			selectedDeviceColumns.value = [];
			checkAllAndIndeterminate();
		}
	};
</script>

<style scoped>
	.chechBoxGroup {
		margin: 5px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.checkBox {
		padding: 6px 15px;
		border-radius: 5px;
		width: 100%;
		color: #44142a;
		border: 1px solid #44142a;
		margin: 15px;
	}
	.heading {
		margin: 0px 0px 0px 10px;
	}
</style>
