<template>
	<a-modal :open="openImportModal" title="Import Pair(s) to Pair Group" @cancel="closeImportModal" width="100%" :style="{ maxWidth: '650px' }" :footer="null">
		<div :style="{ display: 'flex', columnGap: '15px', marginTop: '20px', flexWrap: 'wrap' }"></div>
		<a-form ref="formRef" :model="dynamicValidateForm" @finish="onSave">
			<a-form-item name="groupId" :label-col="{ style: { fontWeight: '650', color: 'black' } }" label="Add to Pair Group" :rules="{ required: true, message: 'please select group' }">
				<div :style="{ display: 'flex' }">
					<a-select v-model:value="dynamicValidateForm.groupId" :style="{ width: '200px' }" :options="items">
						<template v-slot:notFoundContent>
							<Spin size="small" v-if="loading" />
							<Empty :image="Empty.PRESENTED_IMAGE_SIMPLE" v-else />
						</template>
						<template #dropdownRender="{ menuNode: menu }">
							<v-nodes :vnodes="menu" />
							<a-divider style="margin: 4px 0" />
							<div :style="{ display: 'flex' }">
								<a-input v-model:value="groupName" :style="{ width: '52%' }"></a-input>
								<div style="padding: 4px 8px; cursor: pointer" @mousedown="(e) => e.preventDefault()" @click="addItem">
									<plus-outlined />
									Add item
								</div>
							</div>
						</template>
					</a-select>
				</div>
			</a-form-item>
			<h4 :style="{ fontWeight: '650' }">Add a Pair Manually</h4>
			<div class="main-border" v-for="(domain, index) in dynamicValidateForm.domains" :key="domain.key">
				<div :style="{ display: 'flex', position: 'relative', justifyContent: 'center' }">
					<div :style="{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', width: '100%' }">
						<div :style="{ display: 'grid', width: '48%' }" class="border">
							<h4>Person A:</h4>
							<a-form-item :name="['domains', index, 'userAName']" :rules="{ required: true, message: 'please enter person A name' }">
								<div><span :style="{ color: 'red' }">*</span> Name:</div>
								<a-input v-model:value="domain.userAName" />
							</a-form-item>

							<a-form-item :name="['domains', index, 'userAGender']" :rules="{ required: true, message: 'please select gender' }">
								<div><span :style="{ color: 'red' }">*</span> Gender:</div>
								<a-select v-model:value="domain.userAGender" :style="{ width: '100px' }">
									<a-select-option value="m">Male</a-select-option>
									<a-select-option value="f">Female</a-select-option>
									<a-select-option value="o">Other</a-select-option>
								</a-select>
							</a-form-item>

							<a-form-item :name="['domains', index, 'userADob']" :rules="{ required: true, message: 'please select date of birth' }">
								<div><span :style="{ color: 'red' }">*</span> DOB:</div>
								<a-date-picker v-model:value="domain.userADob" format="DD-MMM-YYYY" />
							</a-form-item>

							<a-form-item :name="['domains', index, 'userATob']" :rules="{ required: true, message: 'please select time of birth' }">
								<div><span :style="{ color: 'red' }">*</span> TOB:</div>
								<a-time-picker v-model:value="domain.userATob" />
							</a-form-item>

							<a-form-item :name="['domains', index, 'userAPob']" :rules="{ required: true, message: 'please select place of birth' }">
								<div><span :style="{ color: 'red' }">*</span> POB:</div>
								<a-select :style="{ width: '150px' }" v-model:value="domain.userAPob" allow-clear show-search placeholder="Search a place" :options="locationItems" @search="search">
									<template v-slot:notFoundContent>
										<Spin size="small" v-if="loading" />
										<Empty :image="Empty.PRESENTED_IMAGE_SIMPLE" v-else />
									</template>
								</a-select>
							</a-form-item>
						</div>
						<div :style="{ display: 'grid', width: '48%' }" class="border">
							<h4>Person B:</h4>
							<a-form-item :name="['domains', index, 'userBName']" :rules="{ required: true, message: 'please enter person B name' }">
								<div><span :style="{ color: 'red' }">*</span> Name:</div>
								<a-input v-model:value="domain.userBName" />
							</a-form-item>

							<a-form-item :name="['domains', index, 'userBGender']">
								<div><span :style="{ color: 'red' }">*</span> Gender:</div>
								<a-select v-model:value="domain.userBGender" :style="{ width: '100px' }">
									<a-select-option value="m">Male</a-select-option>
									<a-select-option value="f">Female</a-select-option>
									<a-select-option value="o">Other</a-select-option>
								</a-select>
							</a-form-item>

							<a-form-item :name="['domains', index, 'userBDob']" :rules="{ required: true, message: 'please select date of birth' }">
								<div><span :style="{ color: 'red' }">*</span> DOB:</div>
								<a-date-picker v-model:value="domain.userBDob" format="DD-MMM-YYYY" />
							</a-form-item>

							<a-form-item :name="['domains', index, 'userBTob']" :rules="{ required: true, message: 'please select time of birth' }">
								<div><span :style="{ color: 'red' }">*</span> TOB:</div>
								<a-time-picker v-model:value="domain.userBTob" />
							</a-form-item>

							<a-form-item :name="['domains', index, 'userBPob']" :rules="{ required: true, message: 'please select place of birth' }">
								<div><span :style="{ color: 'red' }">*</span> POB:</div>
								<a-select :style="{ width: '150px' }" v-model:value="domain.userBPob" allow-clear show-search placeholder="Search a place" :options="locationItems" @search="search">
									<template v-slot:notFoundContent>
										<Spin size="small" v-if="loading" />
										<Empty :image="Empty.PRESENTED_IMAGE_SIMPLE" v-else />
									</template>
								</a-select>
							</a-form-item>
						</div>
					</div>
					<MinusCircleOutlined :style="{ fontSize: '20px' }" v-if="dynamicValidateForm.domains.length > 1" class="dynamic-delete-button" :disabled="dynamicValidateForm.domains.length === 1" @click="removeDomain(domain)" />
				</div>
			</div>
			<div :style="{ display: 'flex', justifyContent: 'center', width: '100%' }">
				<a-button class="plus-btn" type="dashed" :style="{ width: '40%' }" @click="addDomain" v-if="dynamicValidateForm.domains.length < 5">
					<PlusOutlined />
					Add Another Pair
				</a-button>
			</div>
			<div class="footerClass">
				<a-button :style="{ width: '20%' }" @click="closeImportModal">Cancel</a-button>
				<a-button type="primary" html-type="submit" :style="{ width: '20%', marginLeft: '10px' }">Save</a-button>
			</div>
		</a-form>
	</a-modal>
</template>

<script setup lang="ts">
	import { getGeoCordApi, getLocationApi } from "@/services/api/auth";
	import { addPair, getGroupListApi, addGroupApi } from "@/services/api/user";
	import { openNotificationWithIcon } from "@/utils";
	import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
	import { Empty, Spin } from "ant-design-vue";
	import { reactive, ref } from "vue";

	const loading = ref(false);
	const locationItems: any = ref([]);
	const formRef = ref();
	const items: any = ref([]);
	const groupName = ref();
	const { openImportModal, closeImportModal, fetchData } = defineProps(["openImportModal", "closeImportModal", "fetchData"]);

	interface Domain {
		value: string;
		key: number;
	}

	const fetchGroupName = async () => {
		items.value = [];
		const groupList = await getGroupListApi();
		groupList?.data.map((e: any) => {
			items.value.push({ label: e?.name, value: e?._id, key: e?._id });
		});
	};

	fetchGroupName();

	const dynamicValidateForm: any = reactive({
		groupId: "",
		domains: [
			{
				userAName: "",
				userAGender: "m",
				userADob: "",
				userATob: "",
				userAPob: "",
				userBName: "",
				userBGender: "f",
				userBDob: "",
				userBTob: "",
				userBPob: "",
			},
		],
	});

	const onSave = async () => {
		const pairArray: any = [];

		await Promise.all(
			dynamicValidateForm.domains.map(async (e: any) => {
				const userAGeoCordinate = await getGeoCordApi(e.userAPob);
				const firstUserAResult = userAGeoCordinate?.data?.results[0];
				const userAlat = firstUserAResult.geometry.location.lat;
				const userAlng = firstUserAResult.geometry.location.lng;

				const userBGeoCordinate = await getGeoCordApi(e.userBPob);
				const firstUserBResult = userBGeoCordinate?.data?.results[0];
				const userBlat = firstUserBResult.geometry.location.lat;
				const userBlng = firstUserBResult.geometry.location.lng;

				pairArray.push({
					groupId: dynamicValidateForm.groupId,
					userA: {
						name: e.userAName,
						gender: e.userAGender,
						dob: `${e.userADob.format("YYYY-MM-DD")}T${e.userATob.format("HH:mm:ss.SSS[Z]")}`,
						pob: e.userAPob,
						geoCordinate: `${userAlat.toFixed(4)} ${userAlng.toFixed(4)}`,
					},
					userB: {
						name: e.userBName,
						gender: e.userBGender,
						dob: `${e.userBDob.format("YYYY-MM-DD")}T${e.userBTob.format("HH:mm:ss.SSS[Z]")}`,
						pob: e.userBPob,
						geoCordinate: `${userBlat.toFixed(4)} ${userBlng.toFixed(4)}`,
					},
				});
			})
		);
		const pairRes = await addPair(pairArray);
		openNotificationWithIcon("success", pairRes?.message);
		// closeImportModal();
	};

	const removeDomain = (item: Domain) => {
		let index = dynamicValidateForm.domains.indexOf(item);
		if (index !== -1) {
			dynamicValidateForm.domains.splice(index, 1);
		}
	};
	const addDomain = () => {
		dynamicValidateForm.domains.push({
			userAName: "",
			userAGender: "m",
			userADob: "",
			userATob: "",
			userAPob: "",
			userBName: "",
			userBGender: "f",
			userBDob: "",
			userBTob: "",
			userBPob: "",
			groupName: "longMarriage",
		});
	};

	const locationList = async (input: string) => {
		try {
			if (input) {
				const res = await getLocationApi(input);
				const locationData = res?.data?.predictions;
				locationItems.value = locationData.map((e: any, i: number) => ({
					label: e.description,
					value: e.description,
					key: i,
				}));
				loading.value = false;
			}
		} catch (error) {
			loading.value = false;
			console.log(error);
		}
	};

	const search = (input: string) => {
		loading.value = true;
		setTimeout(() => {
			locationList(input);
		}, 1000);
	};

	const VNodes = (_: any, { attrs }: any) => {
		return attrs.vnodes;
	};
	const addItem = async () => {
		await addGroupApi({ name: groupName.value });
		groupName.value = "";
		fetchData();
		fetchGroupName();
	};
</script>

<style scoped>
	p {
		margin: 0px;
	}

	.main-border {
		border-radius: 10px;
		border: 1px solid black;
		background-color: #f4f4f4;
		padding: 25px;
		margin: 15px;
	}

	.border {
		border-radius: 10px;
		border: 1px solid black;
		background-color: #f4f4f4;
		padding: 12px 15px 0px 15px;
	}
	.dynamic-delete-button {
		cursor: pointer;
		position: absolute;
		top: -20px;
		right: -20px;
		font-size: 24px;
		color: black;
	}
	.dynamic-delete-button:hover {
		color: #777;
	}

	.footerClass {
		display: flex;
		justify-content: end;
		margin-top: 20px;
	}

	.plus-btn {
		display: flex;
		justify-content: center;
		place-items: center;
		width: 100%;
	}
</style>
