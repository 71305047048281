export const snippetsData = async (data: any) => `
//***********************************************************************
// This class contains all the static constant text used in the App UI
// Date: ${data.time}
// Version: ${data.version}
//***********************************************************************

import 'language_translation.dart';


class UISnipText {
 


  `;