<template>
	<div class="notificationListContainer">
		<div class="table-operations">
			<a-breadcrumb class="heading" separator="/">
				<a-breadcrumb-item :style="{ color: '#44142a' }">Incomplete Profile </a-breadcrumb-item>
			</a-breadcrumb>
			<a-button @click="clearFilters" type="primary" class="clear-filter">Clear filters</a-button>
		</div>
		<a-table
			:columns="column"
			:scroll="{ x: 'max-content' }"
			style="width: 100%"
			bordered
			:data-source="data"
			:loading="loading"
			class="tableBody"
			:pagination="{
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: pagination.total,
                position: ['topRight'],
                size: 'small',
                showTotal: (total:any, range:any) => `${range[0]}-${range[1]} of ${total}`,
                showSizeChanger: false
            }"
			@change="handleTableChange"
		>
			<template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
				<div style="padding: 8px" v-if="column.modifiedAtCalendar">
					<h4>Start Date :</h4>
					<a-date-picker class="filterInput" format="DD-MMM-YYYY" :value="selectedKeys[0]" @change="(e:any) => {modifiedAtStartDate(e), (selectedKeys[0] = e)}" />
					<h4>End Date :</h4>
					<a-date-picker class="filterInput" format="DD-MMM-YYYY" :value="selectedKeys[1]" @change="(e:any) => {modifiedAtEndDate(e), (selectedKeys[1] = e)}" />
					<a-button :style="{ marginRight: '15px' }" @click="handleReset(clearFilters, confirm, column.dataIndex)"> Reset </a-button>
					<a-button type="primary" :style="{ width: '45%' }" @click="handleSearch(selectedKeys, confirm, column.dataIndex)">Ok </a-button>
				</div>
				<div style="padding: 8px" v-else-if="column.customCalendar">
					<h4>Start Date :</h4>
					<a-date-picker class="filterInput" format="DD-MMM-YYYY" :value="selectedKeys[0]" @change="(e:any) => {startDate(e), (selectedKeys[0] = e)}" />
					<h4>End Date :</h4>
					<a-date-picker class="filterInput" format="DD-MMM-YYYY" :value="selectedKeys[1]" @change="(e:any) => {endDate(e), (selectedKeys[1] = e)}" />
					<a-button :style="{ marginRight: '15px' }" @click="handleReset(clearFilters, confirm, column.dataIndex)"> Reset </a-button>
					<a-button type="primary" :style="{ width: '45%' }" @click="handleSearch(selectedKeys, confirm, column.dataIndex)">Ok </a-button>
				</div>
				<div style="padding: 8px" v-else>
					<a-input :placeholder="`Search ${column.title}`" :value="selectedKeys[0]" class="filterInput" @change="(user:any) => setSelectedKeys(user.target.value ? [user.target.value] : [])" @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)" />
					<a-button :style="{ width: '90px', marginRight: '8px' }" @click="handleReset(clearFilters, confirm, column.dataIndex)"> Reset </a-button>
					<a-button type="primary" @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
						<template #icon><SearchOutlined /></template>
						Search
					</a-button>
				</div>
			</template>
		</a-table>
	</div>
</template>

<script lang="ts" setup>
	import { ref } from "vue";
	import { getIncompleteProfileList } from "@/services/api/user";
	import { openNotificationWithIcon, removeNullFromObj } from "@/utils";
	import dayjs from "dayjs";

	const data = ref([]);
	const loading = ref(false);
	const filteredInfo = ref();
	const filtered: any = ref([]);

	const userListFilter: any = ref({
		pageNo: 1,
		pageLimit: 20,
		sortBy: null,
		orderBy: null,
		searchBy: null,
		search: null,
		lastEditedStartDate: null,
		lastEditedEndDate: null,
		gender: null,
	});
	const pagination = ref({
		pageSize: 20,
		total: 0,
		current: 1,
		totalPages: 0,
	});
	const userListPagination = ref({ pageNo: 1, pageLimit: 20 });

	const column = [
		{
			title: "Name",
			dataIndex: "nickName",
			key: "nickName",
			width: 150,
			visible: true,
			customFilterDropdown: true,
			sorter: true,
		},
		{
			title: "Email",
			dataIndex: "identifier",
			key: "identifier",
			visible: true,
			customFilterDropdown: true,
			width: 300,
			sorter: true,
		},
		{
			title: "Gender",
			dataIndex: "gender",
			key: "gender",
			visible: true,
			category: "userProfile",
			filters: [
				{ text: "Male", value: "m" },
				{ text: "Female", value: "f" },
			],
			filterMultiple: false,
			width: 100,
			align: "center",
		},
		{
			title: "Current Location",
			dataIndex: "location",
			key: "location",
			width: 200,
			visible: true,
			customFilterDropdown: true,
			sorter: true,
		},
		{
			title: "Last Edited",
			dataIndex: "modifiedAt",
			key: "modifiedAt",
			visible: true,
			sorter: true,
			align: "center",
			customFilterDropdown: true,
			width: 150,
			filterMultiple: true,
			modifiedAtCalendar: true,
		},
		{
			title: "Joined Date",
			dataIndex: "createdAt",
			key: "createdAt",
			visible: true,
			sorter: true,
			align: "center",
			customFilterDropdown: true,
			width: 150,
			filterMultiple: true,
			customCalendar: true,
		},
	];

	const modifiedAtStartDate = (e: any) => {
		userListFilter.value.lastEditedStartDate = e.format(`YYYY-MM-DDT00:00:00.SSS[Z]`);
	};

	const modifiedAtEndDate = (e: any) => {
		userListFilter.value.lastEditedEndDate = e.format("YYYY-MM-DDT11:59:00.SSS[Z]");
	};

	const startDate = (e: any) => {
		userListFilter.value.createdStartDate = e.format(`YYYY-MM-DDT00:00:00.SSS[Z]`);
	};

	const endDate = (e: any) => {
		userListFilter.value.createdEndDate = e.format("YYYY-MM-DDT11:59:00.SSS[Z]");
	};

	const clearFilters = () => {
		userListFilter.value.searchBy = null;
		userListFilter.value.search = null;
		userListFilter.value.lastEditedStartDate = null;
		userListFilter.value.lastEditedEndDate = null;
		userListFilter.value.createdStartDate = null;
		userListFilter.value.createdEndDate = null;
		userListFilter.value.gender = null;
		userListFilter.value.sortBy = null;
		userListFilter.value.orderBy = null;
		fetchData();
	};

	const fetchData = async () => {
		try {
			loading.value = true;
			await removeNullFromObj(userListFilter.value);
			const apiRes = await getIncompleteProfileList({ pageNo: userListPagination.value.pageNo, pageLimit: userListPagination.value.pageLimit, ...userListFilter.value });
			data.value = apiRes?.item || [];
			data.value.forEach((item: any) => {
				if (item.gender) {
					item.gender = item.gender === "m" ? "Male" : item.gender === "f" ? "Female" : item.gender;
				}
				if (item.location && item.location.combine) {
					item.location = item.location.combine;
				}
				if (item.modifiedAt) {
					item.modifiedAt = dayjs(item.modifiedAt).format("YYYY-MM-DD HH:mm:ss");
				}
				if (item.createdAt) {
					item.createdAt = dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss");
				}
			});
			pagination.value.total = apiRes?.pagination.total;
			pagination.value.current = apiRes?.pagination.currentPage;
			pagination.value.totalPages = apiRes?.pagination.totalPages;
			loading.value = false;
		} catch (error) {
			loading.value = false;
			openNotificationWithIcon("error", "Failed to fetch languages");
			console.error("Error fetching data:", error);
		}
	};
	fetchData();

	const handleTableChange = (pagination: any, filters: any, sorter: any) => {
		filteredInfo.value = filters;
		userListPagination.value.pageNo = Number(pagination.current);
		userListFilter.value.pageNo = Number(pagination.current);
		userListPagination.value.pageLimit = Number(pagination.pageSize);

		if (filters.gender) {
			userListFilter.value.gender = filters.gender[0];
		} else {
			userListFilter.value.gender = null;
		}
		userListFilter.value.orderBy = sorter.order === "ascend" ? "asc" : sorter.order === "descend" ? "desc" : null;
		userListFilter.value.sortBy = sorter.order === undefined ? null : sorter.field;
		fetchData();
	};

	const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
		confirm();
		if (dataIndex == "modifiedAt") {
			userListFilter.value.lastEditedStartDate = selectedKeys[0] && selectedKeys[0].format("YYYY-MM-DDT00:00:00.SSS[Z]");
			userListFilter.value.lastEditedEndDate = selectedKeys[1] && selectedKeys[1].format("YYYY-MM-DDT11:59:00.SSS[Z]");
			fetchData();
		} else if (dataIndex == "createdAt") {
			userListFilter.value.createdStartDate = selectedKeys[0] && selectedKeys[0].format("YYYY-MM-DDT00:00:00.SSS[Z]");
			userListFilter.value.createdEndDate = selectedKeys[1] && selectedKeys[1].format("YYYY-MM-DDT11:59:00.SSS[Z]");
		} else {
			userListFilter.value.searchBy = dataIndex;
			userListFilter.value.search = selectedKeys[0].trim();
			fetchData();
		}
	};

	const handleReset = async (clearFilters: (arg0: { confirm: boolean }) => void, confirm: () => void, dataIndex: string) => {
		confirm();
		clearFilters({ confirm: true });

		if (dataIndex == "modifiedAt") {
			userListFilter.value.lastEditedStartDate = null;
			userListFilter.value.lastEditedEndDate = null;
		}
		if (dataIndex == "createdAt") {
			userListFilter.value.createdStartDate = null;
			userListFilter.value.createdEndDate = null;
		}
		userListFilter.value.search = "";
		userListFilter.value.searchBy = "";
		fetchData();
	};
</script>

<style scoped>
	.notificationListContainer {
		padding: 22px;
	}
	.table-operations {
		display: flex;
		justify-content: space-between;
	}
	.heading {
		font-size: 16px;
		font-weight: 600;
		margin-right: auto;
	}
	.filterInput {
		margin-bottom: 8px;
		display: block;
	}
</style>
