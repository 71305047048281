<template>
	<div class="ForceUpdateContainer">
		<div class="table-operations">
			<a-breadcrumb class="heading" separator="/">
				<a-breadcrumb-item :style="{ color: '#44142a' }">Force Update</a-breadcrumb-item>
			</a-breadcrumb>
			<a-button type="primary" class="saveBtn" @click="saveData">Save</a-button>
		</div>
		<div class="mainContainer">
			<div class="mainDiv">
				<div class="subDiv">
					<span :style="{ margin: '0px 5px' }">IOS</span>
				</div>
				<div class="detailsContainer" :style="{ margin: '25px 0px' }">
					<div :style="{ display: 'flex' }">
						<span class="containerText">Version :</span>
						<span>
							<a-input type="text" class="input" v-model:value="iosVersion"></a-input>
						</span>
					</div>
					<div class="forceUpdateContainer">
						<a-switch v-model:checked="iosForceUpdate" />
						<span class="containerText">Force Update</span>
					</div>
				</div>
			</div>
			<div class="mainDiv">
				<div class="subDiv">
					<span :style="{ margin: '0px 5px' }">Android</span>
				</div>
				<div class="detailsContainer" :style="{ margin: '25px 0px' }">
					<div :style="{ display: 'flex' }">
						<span class="containerText">Version :</span>
						<span>
							<a-input type="text" class="input" v-model:value="androidVersion"></a-input>
						</span>
					</div>
					<div class="forceUpdateContainer">
						<a-switch v-model:checked="androidForceUpdate" />
						<span class="containerText">Force Update</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { ref } from "vue";
	import { getDevices, putDevicesApi } from "../services/api/user";
	import { openNotificationWithIcon } from "@/utils";
	const loading = ref(false);
	const iosVersion = ref([""]);
	const iosForceUpdate = ref(false);
	const androidVersion = ref("");
	const androidForceUpdate = ref(false);

	const fetchData = async () => {
		loading.value = true;
		const getUserData: any = localStorage.getItem("user");
		const userData = JSON.parse(getUserData);
		const appForceUpdateRes = await getDevices(userData._id);
		await appForceUpdateRes.forEach((appForceUpdate: any) => {
			if (appForceUpdate.key === "mobile__ios") {
				iosVersion.value = appForceUpdate.version;
				iosForceUpdate.value = appForceUpdate.forceUpdate;
			}
			if (appForceUpdate.key === "mobile__android") {
				androidVersion.value = appForceUpdate.version;
				androidForceUpdate.value = appForceUpdate.forceUpdate;
			}
		});
		loading.value = false;
	};
	fetchData();
	const saveData = async () => {
		const getUserData: any = localStorage.getItem("user");
		const userData = JSON.parse(getUserData);
		const appForceUpdateRes = await getDevices(userData._id);
		try {
			const data: any = [
				{
					key: "mobile__ios",
					version: iosVersion.value,
					forceUpdate: iosForceUpdate.value,
				},
				{
					key: "mobile__android",
					version: androidVersion.value,
					forceUpdate: androidForceUpdate.value,
				},
			];
			await putDevicesApi(data, userData._id);
			await fetchData();
			openNotificationWithIcon("success", "App settings updated successfully");
		} catch (error) {
			console.log(error);
			openNotificationWithIcon("error", "Something want wrong");
		}
	};
</script>

<style scoped>
	.ForceUpdateContainer {
		padding: 20px 40px;
	}
	.table-operations {
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
	}
	.heading {
		font-size: 16px;
		font-weight: 600;
		margin-right: auto;
	}
	.saveBtn {
		width: 100px;
	}
	.mainContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 50px;
	}
	.mainDiv {
		padding: 20px;
		border: 2px solid rgb(190, 190, 190);
		position: relative;
		border-radius: 6px;
		width: 55%;
		margin-bottom: 25px;
	}
	.subDiv {
		display: flex;
		position: absolute;
		top: -11px;
		background: #ffff;
		margin-left: 20px;
		padding: 0 5px;
		font-weight: 700;
		font-size: 15px;
	}
	.detailsContainer {
		display: flex;
		justify-content: space-between;
		font-size: 14px;
	}
	.input {
		margin-left: 15px;
	}
	.containerText {
		display: flex;
		align-items: center;
		font-weight: 500;
		font-size: 16px;
	}
	.forceUpdateContainer {
		display: flex;
		align-items: center;
		gap: 15px;
	}
</style>
