<template>
	<a-modal :open="openModal" title="Add Synastry" @cancel="closeDrawer" :footer="null">
		<a-form :model="formState" layout="horizontal" :label-col="labelCol" :wrapper-col="wrapperCol" @finish="onSave(isImport)" @finishFailed="onFinishFailed">
			<a-form-item label="Name" name="name" :rules="[{ required: true, message: 'Please enter  name!' }]">
				<a-input v-model:value="formState.name" :style="{ width: '200px' }" />
			</a-form-item>
			<a-form-item label="Rule/Score set" name="score" :rules="[{ required: true, message: 'Please select name!' }]" v-if="!isImport">
				<a-select label="Rule/Score set :" v-model:value="formState.score" :style="{ width: '200px' }" :options="items" @select="select">
					<template v-slot:notFoundContent>
						<Spin size="small" v-if="loading" />
						<Empty :image="Empty.PRESENTED_IMAGE_SIMPLE" v-else />
					</template>
				</a-select>
			</a-form-item>
			<a-form-item name="file" v-else label="Upload Excel  file" :rules="[{ required: true, message: 'Please upload one excel file!' }]">
				<a-upload :file-list="fileList" :remove="handleRemove" :before-upload="beforeUpload" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel">
					<a-button v-if="fileList.length < 1">
						<upload-outlined />
						Select File
					</a-button>
				</a-upload>
			</a-form-item>
			<div class="footerClass">
				<a-button :style="{ width: '20%' }" @click="closeDrawer">Cancel</a-button>
				<a-button type="primary" html-type="submit" :style="{ width: '20%', marginLeft: '10px' }">Save</a-button>
			</div>
		</a-form>
	</a-modal>
</template>

<script setup lang="ts">
	import { Empty, Spin } from "ant-design-vue";
	import { astroSynastryAddApi, heartLogoHeading, synastry, synastryTableHeading, synastryVsGmHeading, checkBoxPlanetsArray, orbSettingCheckbox, synastryRulesNameArray } from "@/services/api/user";
	import { reactive, ref } from "vue";
	import { openNotificationWithIcon } from "@/utils";
	import { UploadOutlined } from "@ant-design/icons-vue";
	import * as XLSX from "xlsx";

	const { closeDrawer, openModal, loading, items, fetchData, isImport } = defineProps(["openModal", "closeDrawer", "loading", "items", "fetchData", "isImport"]);
	const labelCol = { style: { width: "130px" } };
	const wrapperCol = { span: 14 };
	const selectedData: any = ref([]);

	const fileList: any = ref([]);

	const userData: any = localStorage.getItem("user");
	const toJson = JSON.parse(userData);
	const formState = reactive<any>({
		name: "",
		score: "",
		file: "",
	});

	const handleRemove = (file: any) => {
		const index = fileList.value.indexOf(file);
		const newFileList = fileList.value.slice();
		newFileList.splice(index, 1);
		fileList.value = newFileList;
	};

	const beforeUpload = (file: any) => {
		fileList.value = [...fileList.value, file];
		formState.file = "file";
		return false;
	};

	const select = (id: any, record: any) => {
		selectedData.value = record.data;
	};

	const onSave = async (isImport: boolean) => {
		if (isImport) {
			synastryRulesNameArray.value.forEach((item: any) => {
				item.communication = 0;
				item.connection = 0;
				item.energy = 0;
				item.stability = 0;
			});
			const categoryArray: any = [];
			const heartLogoArray: any = [];
			const synastryVsGMArray: any = [];
			const plantesArray: any = [];
			const orbsArray: any = [];
			const formData = new FormData();

			fileList.value.forEach((file: any) => {
				formData.append("files[]", file as any);
			});
			let rulesError = false;
			const selectedFile = fileList.value[0];

			const reader = new FileReader();
			reader.onload = async (e: any) => {
				const data = new Uint8Array(e.target.result);
				const workbook = XLSX.read(data, { type: "array" });
				const jsonData: any = {
					planets: XLSX.utils.sheet_to_json(workbook.Sheets["Planets"]),
					orbs: XLSX.utils.sheet_to_json(workbook.Sheets["Orbs"]),
					synastryCategory: XLSX.utils.sheet_to_json(workbook.Sheets["Synastry Category"]),
					heartLogo: XLSX.utils.sheet_to_json(workbook.Sheets["Heart Logo"]),
					synastryVsGM: XLSX.utils.sheet_to_json(workbook.Sheets["Synastry vs. GM"]),
					synastryRules: XLSX.utils.sheet_to_json(workbook.Sheets["Synastry Rules"]),
				};

				for (let i = 0; i < jsonData.synastryCategory.length; i++) {
					const element = jsonData.synastryCategory[i];
					const matchingOrbSetting: any = synastryTableHeading.find((orbSetting: any) => orbSetting.key == element.Category);
					jsonData.synastryCategory[0].val2 = 4;
					element.val1 = jsonData.synastryCategory[i + 1] ? jsonData.synastryCategory[i + 1].val2 : "";
					element.val3 = jsonData.synastryCategory[i + 1] ? (jsonData.synastryCategory[i + 1].val2 * 100) / 4 : "";
					element.val4 = (element.val2 * 100) / 4;
					jsonData.synastryCategory[4].val1 = 0;
					jsonData.synastryCategory[4].val3 = 0;
					categoryArray.push({
						label: matchingOrbSetting.value,
						val1: element.val1,
						val2: element.val2,
						val3: element.val3,
						val4: element.val4,
					});
				}

				jsonData.heartLogo.map((item: any) => {
					const matchingOrbSetting: any = heartLogoHeading.find((orbSetting: any) => orbSetting.key == item.Heart_Logo);
					heartLogoArray.push({ label: matchingOrbSetting.value, val1: item.val1, val2: item.val2 });
				});

				jsonData.synastryVsGM.map((item: any) => {
					const matchingOrbSetting: any = synastryVsGmHeading.find((orbSetting: any) => orbSetting.key == item.Synastry_vs_GM);
					synastryVsGMArray.push({ label: matchingOrbSetting.value, val1: item.val1, val2: item.val2 });
				});

				jsonData.planets.map((e: any) => {
					if (e.Exist) {
						const matchingOrbSetting: any = checkBoxPlanetsArray.value.find((data: any) => data.fullName == e.Planet);
						plantesArray.push(matchingOrbSetting.value);
					}
				});

				jsonData.orbs.map((e: any) => {
					if (e.Exist) {
						const matchingOrbSetting: any = orbSettingCheckbox.value.find((data: any) => data.name == e.Orbs);
						orbsArray.push({ key: matchingOrbSetting.value, value: e.Value });
					}
				});

				jsonData?.synastryRules.map((rule: any) => {
					const findRulesIndex = synastryRulesNameArray.value.findIndex((e: any) => e.rules === rule.Rules);
					if (findRulesIndex && findRulesIndex > 0) {
						if (rule.Communication || rule.Connection || rule.Energy || rule.Stability) {
							if ((!rule.Communication && rule.Communication !== 0) || (!rule.Connection && rule.Connection !== 0) || (!rule.Energy && rule.Energy !== 0) || (!rule.Stability && rule.Stability !== 0)) {
								rulesError = true;
							}
						}
						const element = synastryRulesNameArray.value[findRulesIndex];
						const orbsValue = orbsArray.find((orb: any) => orb.key === element.orbsName);
						element.communication = rule.Communication;
						element.connection = rule.Connection;
						element.energy = rule.Energy;
						element.stability = rule.Stability;
						element.isVisible = true;
						if (orbsValue) {
							element.minOrbsValue = element.deg - orbsValue.value;
							element.maxOrbsValue = element.deg + orbsValue.value;
							element.orbsValue = orbsValue.value;
						}
					}
				});

				const synastryRulesArray = synastryRulesNameArray.value.filter((e: any) => e.communication);
				const synastryCategory = await synastry(categoryArray, synastryTableHeading);
				const heartLogo = await synastry(heartLogoArray, heartLogoHeading);
				const synastryVsGm = await synastry(synastryVsGMArray, synastryVsGmHeading);

				const matchingName = items.find((name: any) => name.label.toLowerCase() == formState.name.toLowerCase());
				if (matchingName) {
					openNotificationWithIcon("error", `${formState.name} is already exist`);
				} else if (synastryRulesArray.length < 0) {
					openNotificationWithIcon("error", "Please fill all rule value");
				} else if (rulesError) {
					openNotificationWithIcon("error", "Please fill all rule value");
				} else {
					const data = {
						name: formState.name,
						heartLogo,
						notes: "",
						orbs: orbsArray,
						synastryCategory,
						synastryVsGm,
						planets: plantesArray,
						synastryRules: synastryRulesArray,
						user: toJson._id,
					};

					await astroSynastryAddApi(data);
					closeDrawer();
					openNotificationWithIcon("success", "Synastry add success");
					fetchData();
				}
			};

			reader.readAsArrayBuffer(selectedFile);
		} else {
			const data = {
				name: formState.name,
				categories: selectedData.value.categories,
				collections: selectedData.value.collections,
				heartLogo: selectedData.value.heartLogo,
				notes: "",
				orbs: selectedData.value.orbs,
				planets: selectedData.value.planets,
				synastryCategory: selectedData.value.synastryCategory,
				synastryRules: selectedData.value.synastryRules,
				synastryVsGm: selectedData.value.synastryVsGm,
				user: selectedData.value.user._id,
			};
			const matchingName = items.find((name: any) => name.label.toLowerCase() == formState.name.toLowerCase());
			if (matchingName) {
				openNotificationWithIcon("error", `${formState.name} is already exist`);
			} else {
				await astroSynastryAddApi(data);
				closeDrawer();
				openNotificationWithIcon("success", "Synastry add success");
				fetchData();
			}
		}
	};
	const onFinishFailed = (errorInfo: any) => {
		console.log("Failed:", errorInfo);
	};
</script>

<style scoped>
	.footerClass {
		display: flex;
		justify-content: end;
	}
</style>
