<template>
	<div :style="{ fontSize: '25px', margin: '10px 10px 15px 10px', display: 'flex', justifyContent: 'space-between' }">
		<a-breadcrumb class="heading" separator="|">
			<a-breadcrumb-item :style="{ color: '#44142a', cursor: 'pointer', fontSize: '16px', fontWeight: '700' }" @click="closeAdminDrawer"><UserSwitchOutlined /> Admin</a-breadcrumb-item>
		</a-breadcrumb>
		<CloseCircleOutlined @click="closeAdminDrawer" />
	</div>
	<a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol" layout="horizontal" @finish="onFinish" @finishFailed="onFinishFailed">
		<div :style="{ display: 'grid', justifyContent: 'center', marginTop: '70px' }">
			<div :style="{ display: 'flex', padding: '5px' }">
				<AdminProfile :formData="formState" :adminAdd="true"></AdminProfile>
				<AdminNotification :newUploads="newUploads" :deleteFile="deleteFile" :formData="formState" :adminAdd="true"></AdminNotification>
			</div>
			<div :style="{ display: 'grid', justifyContent: 'center', marginTop: '50px' }">
				<a-button size="large" html-type="submit" type="primary" :style="{ width: '200px' }">Save</a-button>
			</div>
		</div>
		<!-- <div :style="{ display: 'flex', justifyContent: 'space-between', margin: '10px' }">
			<div :style="{ display: 'flex', alignItems: 'center', cursor: 'pointer' }">
				<LeftCircleOutlined :style="{ fontSize: '40px', color: '#44142a' }" @click="previousDetails" />
				<div :style="{ marginLeft: '10px', fontSize: '16px', fontWeight: 700, color: '#44142a' }">Previous<br />User</div>
			</div>
			<div>
				<a-button size="large" @click="submitUserDetails" type="primary" :style="{ width: '200px', marginTop: '5px' }">Save</a-button>
			</div>
			<div :style="{ display: 'flex', alignItems: 'center', cursor: 'pointer' }">
				<div :style="{ marginRight: '10px', fontSize: '16px', fontWeight: 700, color: '#44142a' }">Next<br />User</div>
				<RightCircleOutlined :style="{ fontSize: '40px', color: '#44142a' }" @click="nextDetails" />
			</div>
		</div> -->
	</a-form>
</template>

<script lang="ts" setup>
	import { CloseCircleOutlined, LeftCircleOutlined, RightCircleOutlined, UserSwitchOutlined } from "@ant-design/icons-vue";
	import { reactive, ref } from "vue";
	import AdminProfile from "@/components/AdminDetails/AdminProfile.vue";
	import AdminNotification from "@/components/AdminDetails/AdminNotification.vue";
	import { addUserApi, updateUserApi } from "@/services/api/auth";
	import dayjs from "dayjs";
	import { openNotificationWithIcon } from "@/utils";
	import { uploadImage } from "@/services/api/user";
	const { closeAdminDrawer } = defineProps(["closeAdminDrawer"]);
	const labelCol = { style: { width: "130px" } };
	const wrapperCol = { span: 14 };
	const newUploads = ref([]);
	const deleteFile = ref([]);

	const formState = reactive<any>({
		fullName: "",
		identifier: "",
		gender: "",
		profession: "",
		dob: "",
		pob: "",
		tob: "",
		geoCordinate: "",
		image: "",
		password: "",
		cPassword: "",
		roles: "",
	});

	const onFinish = async () => {
		formState.dob = `${formState.selectDob.format("YYYY-MM-DD")}T${formState.tob.format("HH:mm:ss.SSS[Z]")}`;

		const preferences: any = [];
		for (const key in formState) {
			const element = formState[key];
			if (key == "profession") {
				preferences.push({ key, value: element, type: typeof element });
			}
		}

		const publicCaptions = [formState.credit];
		formState.nickName = formState.fullName;
		await addUserApi({ ...formState, preferences, roles: [formState.roles] })
			.then(async (res) => {
				newUploads.value.map(async (file: any) => {
					const formDetails = new FormData();
					formDetails.set("file", file);
					await uploadImage(res?.user?._id, res?.user?.fullName, formDetails);
				});
				res && (await updateUserApi(res?.user?._id, { publicCaptions, geo: formState.geo, preferences }));
				res && closeAdminDrawer();
			})
			.catch((error) => {
				openNotificationWithIcon("error", error?.response?.data?.msg);
			});
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log("Failed:", errorInfo);
	};
</script>
