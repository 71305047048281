<template>
	<div v-if="!loading" :style="{ fontSize: '25px', margin: '10px 10px 15px 10px', display: 'flex', justifyContent: 'space-between' }">
		<a-breadcrumb class="heading" separator="|">
			<a-breadcrumb-item :style="{ color: '#44142a', cursor: 'pointer', fontSize: '16px', fontWeight: '700' }" @click="closeDetails"><TeamOutlined /> Admins</a-breadcrumb-item>
			<a-breadcrumb-item :style="{ fontSize: '16px', fontWeight: '700' }">{{ formData._id }}</a-breadcrumb-item>
		</a-breadcrumb>
		<CloseCircleOutlined :style="{ paddingRight: '10px' }" @click="closeDetails" />
	</div>
	<div v-if="loading" class="loading-wrapper">
		<a-spin />
	</div>
	<a-form v-if="!loading" :label-col="labelCol" :wrapper-col="wrapperCol" layout="horizontal" :disabled="componentDisabled">
		<div :style="{ display: 'grid', justifyContent: 'center', marginTop: '70px' }">
			<div :style="{ display: 'flex', padding: '5px', justifyContent: 'space-between' }">
				<AdminProfile :formData="formData" :closeDrawer="closeDrawer" :openDrawer="openDrawer" :openDrawerValue="openDrawerValue"></AdminProfile>
				<AdminNotification :formData="formData"></AdminNotification>
			</div>
			<div :style="{ display: 'grid', justifyContent: 'center', marginTop: '50px' }">
				<a-button size="large" @click="submitUserDetails" type="primary" :style="{ width: '200px' }">Save</a-button>
			</div>

			<!-- <div :style="{ display: 'flex', justifyContent: 'space-between', margin: '20px' }"> -->
			<!-- <div :style="{ display: 'flex', alignItems: 'center', cursor: 'pointer' }">
					<LeftCircleOutlined :style="{ fontSize: '40px', color: '#44142a' }" @click="previousDetails" />
					<div :style="{ marginLeft: '10px', fontSize: '16px', fontWeight: 700, color: '#44142a' }">Previous<br />User</div>
				</div> -->
			<!-- <div :style="{ display: 'flex', alignItems: 'center', cursor: 'pointer' }">
					<div :style="{ marginRight: '10px', fontSize: '16px', fontWeight: 700, color: '#44142a' }">Next<br />User</div>
					<RightCircleOutlined :style="{ fontSize: '40px', color: '#44142a' }" @click="nextDetails" />
				</div> -->
			<!-- </div> -->
		</div>
	</a-form>
</template>

<script lang="ts" setup>
	import { CloseCircleOutlined, LeftCircleOutlined, RightCircleOutlined, TeamOutlined } from "@ant-design/icons-vue";
	import { ref } from "vue";
	import AdminProfile from "@/components/AdminDetails/AdminProfile.vue";
	import AdminNotification from "@/components/AdminDetails/AdminNotification.vue";
	import { getUser, getUserMap, userSample } from "@/services/api/user";
	import { updateUserApi } from "@/services/api/auth";
	import router from "@/router";
	const componentDisabled = ref(false);
	const loading = ref(false);
	const user = ref(userSample);
	const formData = ref<any>({});
	const labelCol = { style: { width: "130px" } };
	const wrapperCol = { span: 14 };
	const openDrawerValue = ref(false);

	const submitUserDetails = async () => {
		const geoCordinate = formData.value.geoCordinate.split(" ");
		formData.value.geo.lat = geoCordinate[0];
		formData.value.geo.lng = geoCordinate[1];
		formData.value.profession_public = formData.value.profession_public_element == "public" ? true : false;
		formData.value.residence_public = formData.value.residence_public_element == "public" ? true : false;
		formData.value.orientation_public = formData.value.orientation_public_element == "public" ? true : false;
		formData.value.relationship_public = formData.value.relationship_public_element == "public" ? true : false;
		formData.value.roles = ["admin", "superadmin"];
		formData.value.adminBlocked && formData.value.roles.push("blocked");

		for (const key in formData.value) {
			const element = formData.value[key];
			for (let i = 0; i < formData.value.preferences.length; i++) {
				const preferencesElement = formData.value.preferences[i];
				if (preferencesElement.key === key) {
					preferencesElement.value = element;
				}
			}
		}

		await updateUserApi(router.currentRoute.value.params.id, formData.value);
		router.back();
	};
	const closeDetails = async () => {
		router.back();
	};

	const fetchData = async () => {
		try {
			loading.value = true;
			const id: any = router.currentRoute.value.params.id;
			if (!id) {
				return;
			}
			const apiRes: any = await getUser(id);
			user.value = await getUserMap(apiRes?.user);
			formData.value = user.value;
			loading.value = false;
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	fetchData();

	const openDrawer = () => {
		openDrawerValue.value = true;
	};

	const closeDrawer = () => {
		openDrawerValue.value = false;
	};

	const previousDetails = () => {};

	const nextDetails = () => {};
</script>
<style scoped>
	.loading-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;
	}
</style>
