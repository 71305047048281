<template>
	<div class="home">
		<div :style="{ display: 'flex' }">
			<h1 class="heading">REGISTRATION</h1>
			<!-- <div class="iconNearLine"></div> -->
		</div>
		<div class="row">
			<div class="t_register">
				<div class="card card_2">
					<h1 class="card_title">TODAY'S REGISTRATION</h1>
					<h2 class="value">{{ dashboardData?.todayRegistration || 0 }}</h2>
					<div :style="{ display: 'flex', justifyContent: 'space-between' }">
						<p class="last_text" :style="{ color: '#FCECF6 ' }"><CalendarOutlined :style="{ marginRight: '5px' }" />{{ todayDate }}</p>
						<div class="icon">DAY</div>
					</div>
				</div>
			</div>
			<div class="t_register">
				<div class="card card_3">
					<h1 class="card_title">WEEKLY REGISTRATION</h1>
					<h2 class="value">{{ dashboardData?.WeeklyRegistration || 0 }}</h2>
					<div :style="{ display: 'flex', justifyContent: 'space-between' }">
						<p class="last_text" :style="{ color: '#ffe2c1' }"><CalendarOutlined :style="{ marginRight: '5px' }" />{{ weeklyFormattedDate }} - {{ formattedDate }}</p>
						<div class="icon">WEEK</div>
					</div>
				</div>
			</div>
			<div class="t_register">
				<div class="card card_4">
					<h1 class="card_title">MONTHLY REGISTRATION</h1>
					<h2 class="value">{{ dashboardData?.monthlyRegistration || 0 }}</h2>
					<div :style="{ display: 'flex', justifyContent: 'space-between' }">
						<p class="last_text" :style="{ color: '#c4d3ff' }"><CalendarOutlined :style="{ marginRight: '5px' }" />{{ monthlyFormattedDate }} - {{ formattedDate }}</p>
						<div class="icon">MONTH</div>
					</div>
				</div>
			</div>
		</div>

		<!-- <div :style="{ display: 'flex', justifyContent: 'center' }">
			<div class="iconNearLine"></div>
			<img src="heart-logo.ico" :style="{ width: '30px' }" />
			<div class="iconNearLine"></div>
		</div> -->
		<div :style="{ display: 'flex', justifyContent: 'center' }">
			<div class="iconNearLine"></div>
		</div>
		<div :style="{ display: 'flex' }">
			<h1 class="heading">OVERVIEW</h1>
		</div>

		<div class="second-row">
			<!-- <div class="col">
				<img src="total-members.png" class="image" :style="{ backgroundColor: '#e8f8ff' }" />
				<h1 class="card_title">Total Members<span> (Active)</span></h1>

				<h2 class="value">{{ dashboardData?.totalMembers || 0 }}</h2>
			</div> -->
			<div class="col">
				<img src="registration-complate.png" class="image" :style="{ backgroundColor: '#e8f8ff' }" />
				<h1 class="card_title">Registration Completed</h1>
				<h2 class="value">{{ dashboardData?.registrationComplete || 0 }}</h2>
			</div>
			<div class="col">
				<img src="pay.png" class="image" :style="{ backgroundColor: '#e4e4ff' }" />
				<h1 class="card_title">Registration Incomplete</h1>
				<h2 class="value">{{ dashboardData?.registrationUncompleted || 0 }}</h2>
			</div>
			<div class="col">
				<img src="online.png" class="image" :style="{ backgroundColor: '#e1ffe1' }" />
				<h1 class="card_title">Online Members</h1>
				<h2 class="value">{{ dashboardData?.onlineMembers || 0 }}</h2>
			</div>
			<div class="col">
				<img src="male.png" class="image" :style="{ backgroundColor: '#ffeecf' }" />
				<h1 class="card_title">Male Members</h1>
				<h2 class="value">{{ dashboardData?.maleMembers || 0 }}</h2>
			</div>
			<div class="col">
				<img src="female.png" class="image" :style="{ backgroundColor: '#fbdbe1' }" />
				<h1 class="card_title">Female Members</h1>
				<h2 class="value">{{ dashboardData?.femaleMembers || 0 }}</h2>
			</div>
		</div>

		<!-- <div :style="{ display: 'flex', justifyContent: 'center' }">
			<div class="iconNearLine"></div>
		</div> -->
		<!-- <div :style="{ display: 'flex' }">
			<h1 class="heading">OVERVIEW</h1>
		</div> -->

		<div class="last-row">
			<div class="col">
				<img src="profile.png" class="image" :style="{ backgroundColor: '#e0e0e0' }" />
				<h1 class="card_title">Profile Completed</h1>
				<h2 class="value">{{ dashboardData?.completeProfileMembers || 0 }}</h2>
			</div>
			<!-- <div class="col">
				<img src="dollar.png" class="image" :style="{ backgroundColor: '#e8f8ff' }" />
				<h1 class="card_title">TOTAL REVENUE GENERATED</h1>
				<h2 class="value">$ {{ dashboardData?.revenue || 0 }}</h2>
			</div>
			<div class="col">
				<img src="dollar.png" class="image" :style="{ backgroundColor: '#e8f8ff' }" />
				<h1 class="card_title"></h1>
				<h2 class="value">$ {{ dashboardData?.revenue || 0 }}</h2>
			</div> -->
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { userLoginCheck } from "@/services/api/auth";
	import { getDashboardList } from "@/services/api/user";
	import { CalendarOutlined } from "@ant-design/icons-vue";
	import dayjs from "dayjs";
	import { ref } from "vue";

	const dashboardData = ref();

	const userLoginCheckFunction = async () => {
		await userLoginCheck();
		dashboardData.value = await getDashboardList();
	};
	userLoginCheckFunction();

	const currentDate = dayjs();
	const todayDate = currentDate.format("DD MMM YY").toUpperCase();
	const formattedDate = currentDate.format("DD MMM").toUpperCase();

	const weeklyDate = dayjs().subtract(7, "days");
	const weeklyFormattedDate = weeklyDate.format("DD MMM").toUpperCase();

	const monthlyDate = dayjs().subtract(30, "days");
	const monthlyFormattedDate = monthlyDate.format("DD MMM").toUpperCase();
</script>

<style scoped>
	.home {
		padding: 20px 50px;
	}
	.adminDashboard {
		display: grid;
		padding: 20px 0;
		place-items: center;
	}

	.last-row {
		display: flex;
		flex-wrap: wrap;
		justify-content: start;
		gap: 20px;
	}

	/* .iconNearLine {
		margin: 10px;
		background-color: #44142a;
		border: 1px solid #44142a;
		width: 115px;
		height: 5px;
	} */

	.iconNearLine {
		margin: 15px;
		background-color: rgb(236, 236, 236);
		border: 1px solid rgb(236, 236, 236);
		width: 80%;
		height: 0.5px;
	}
	@media (min-width: 992px) {
		.t_register {
			flex: 0 0 28%;
			max-width: 28%;
		}
	}

	.row {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.card {
		margin: 20px 0px;
		border: 0px;
		border-radius: 0.625rem;
		color: #fff !important;
		box-shadow: 6px 11px 41px -28px #a99de7;
		padding: 1.88rem 0.5rem 0.1rem 1.88rem;
		flex: 1 1 auto;
		position: relative;
	}

	.card_2 {
		background-image: linear-gradient(230deg, #fc5286, #fbaaa2);
	}

	.card_3 {
		background-image: linear-gradient(230deg, #ffc480, #ff763b);
	}

	.card_4 {
		background-image: linear-gradient(230deg, #0e4cfd, #6a8eff);
	}

	.card_title {
		font-size: 18px;
		font-weight: 500;
		line-height: 18px;
		margin-bottom: 0.75rem;
	}

	.value {
		display: inline-block !important;
		font-size: 1.875rem;
		margin-bottom: 0px;
	}

	.last_text {
		margin-bottom: 0px;
		margin-top: 10px;
		font-weight: 600;
		font-size: 15px;
	}

	.icon {
		text-align: end;
		font-size: 2.5rem;
		font-weight: 700;
		padding-top: 10px;
	}

	.second-row {
		margin: 20px 0px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.col {
		margin: 15px 0px;
		display: grid;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 20px;
		border-radius: 15px;
		box-shadow: 0 4px 8px 0 rgb(0 0 0 / 0%), 0 6px 20px 0 rgb(0 0 0 / 10%);
		position: relative;
		width: 18%;
		min-width: 190px;
	}

	.image {
		min-width: 50px;
		width: 20%;
		margin-bottom: 20px;
		padding: 12px;
		border-radius: 10px;
	}

	.heading {
		font-weight: 700;
	}

	.revenue {
		margin: 15px 0px;
		display: grid;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 20px;
		border-radius: 15px;
		box-shadow: 0 4px 8px 0 rgb(0 0 0 / 0%), 0 6px 20px 0 rgb(0 0 0 / 10%);
		position: relative;
		min-width: 190px;
	}
</style>

<!-- #ffc7d6 #ffb686 -->
<!-- <div class="secondDiv">
			<div class="adminDashboard">
				<img src="calendar-page.svg" :style="{ width: '130px' }" />
				<h3 class="registerTitle">TODAY'S REGISTRATION</h3>
				<p class="registerValue">12</p>
			</div>
			<div class="adminDashboard">
				<img src="calendar-page.svg" :style="{ width: '130px' }" />
				<h3 class="registerTitle">WEEKLY REGISTRATION</h3>
				<p class="registerValue">12</p>
			</div>
			<div class="adminDashboard">
				<img src="calendar-page.svg" :style="{ width: '130px' }" />
				<h3 class="registerTitle">MONTHLY REGISTRATION</h3>
				<p class="registerValue">12</p>
			</div>
		</div> -->

<!-- .registerTitle {
			margin-top: 20px;
			font-size: 23px;
			font-weight: 350;
		}

		.registerValue {
			margin-top: 10px;
			color: yellow;
			background-color: black;
			text-align: center;
			padding: 5px 0px;
			width: 25%;
			border-radius: 10px;
		}

		.secondDiv {
			padding: 40px 100px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		} -->
