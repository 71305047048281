<template>
	<div :style="{ padding: '20px 20px 0px 20px' }">
		<div class="table-operations" v-if="!openDrawer">
			<a-breadcrumb class="heading" separator="/">
				<a-breadcrumb-item :style="{ color: '#44142a' }"><StarOutlined /> Celebrities</a-breadcrumb-item>
			</a-breadcrumb>
			<!-- <a-button @click="() => (drawerOpen = true)" type="primary">Additional fields</a-button> -->
			<!-- <AdditionalFieldsDrawer :closeDrawer="closeDrawer" :drawerOpen="drawerOpen" :columns="columns" /> -->
			<a-button @click="() => (openDrawer = true)" type="primary" class="clear-filter">Add Celebrities</a-button>
			<a-button @click="clearFilters" type="primary" class="clear-filter">Clear filters</a-button>
			<a-button type="primary"
				><template #icon><DownloadOutlined /></template
			></a-button>
		</div>

		<CelebritiesDetailsAdd v-if="openDrawer" :closeCelebritiesDrawer="closeCelebritiesDrawer" :data="data" />

		<a-table
			v-if="!openDrawer"
			size="middle"
			bordered
			@change="handleChange"
			:columns="columns.filter((column) => column.visible)"
			:data-source="data"
			:pagination="{
				current: pagination.current,
				pageSize: pagination.pageSize,
				total: pagination.total,
				position: ['topRight'],
				size: 'small',
				showTotal: (total:any, range:any) => `${range[0]}-${range[1]} of ${total}`,
				showSizeChanger: false, 
			}"
			:loading="loading"
			:scroll="{ x: 1, y: height - 245 }"
			class="tableBody"
		>
			<template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
				<div style="padding: 8px" v-if="!column.customCalendar">
					<a-input
						:placeholder="`Search ${column.title}`"
						:value="selectedKeys[0]"
						class="filterInput"
						@change="(user:any) => setSelectedKeys(user.target.value ? [user.target.value] : [])
			"
						@pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
					/>
					<a-button :style="{ width: '90px', marginRight: '8px' }" @click="handleReset(clearFilters, confirm, column.dataIndex)"> Reset </a-button>
					<a-button type="primary" @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
						<template #icon><SearchOutlined /></template>
						Search
					</a-button>
				</div>
				<div style="padding: 8px" v-else>
					<h4>Start Date :</h4>
					<a-date-picker class="filterInput" format="DD-MMM-YYYY" :value="selectedKeys[0]" @change="(e:any)=>{startDate(e),selectedKeys[0]=e}" :disabledDate="disabledDate" />
					<h4>End Date :</h4>
					<a-date-picker class="filterInput" format="DD-MMM-YYYY" :value="selectedKeys[1]" @change="(e:any)=>{endDate(e),selectedKeys[1]=e}" :disabledDate="disabledDate" />
					<a-button :style="{ marginRight: '15px' }" @click="handleReset(clearFilters, confirm, column.dataIndex)"> Reset </a-button>
					<a-button type="primary" :style="{ width: '45%' }" @click="handleSearch(selectedKeys, confirm, column.dataIndex)">Ok </a-button>
				</div>
			</template>
			<template #bodyCell="{ text, column, record }">
				<template v-if="column && column.dataIndex === 'fullName'">
					<a>
						<router-link :to="'/celebrities/' + record._id" :data="data">{{ truncateText(text, 25) }}</router-link>
					</a>
				</template>
				<template v-if="column.dataIndex !== 'fullName' && text">
					<div>
						{{ truncateText(text, 25) }}
					</div>
				</template>
				<template v-if="column && column.dataIndex === 'action'">
					<a-button type="danger" @click="handleDelete(record)">
						<DeleteOutlined />
					</a-button>
				</template>
			</template>
		</a-table>
	</div>
</template>
<script lang="ts" setup>
	import { DeleteOutlined, DownloadOutlined, ExclamationCircleOutlined, StarOutlined } from "@ant-design/icons-vue";
	import { Ref, createVNode, ref } from "vue";
	import { Modal, type TableProps } from "ant-design-vue";
	import { SearchOutlined } from "@ant-design/icons-vue";
	import { ColumnsData } from "./../interface/Members.interface";
	import { getUserList, getCelebritiesMap, filteredUser } from "../services/api/user";
	import { removeNullFromObj, truncateText, useScreenSize } from "../utils/index";
	import dayjs from "dayjs";
	import CelebritiesDetailsAdd from "./CelebritiesDetailsAdd.vue";
	import { updateUserApi } from "@/services/api/auth";

	const loading = ref();
	const userList = ref<any[]>([]);
	// const data = ref<User[]>([]);
	const data: any = ref([]);
	const openDrawer = ref(false);
	const toJson: any = ref({ userList: {}, filtered: [] });
	const filtered: any = ref([]);

	const closeCelebritiesDrawer = () => {
		openDrawer.value = false;
		fetchData();
	};

	const selectedUser = ref<any>({});
	const userListFilter: any = ref({
		pageNo: 1,
		pageLimit: 20,
		sortBy: null,
		orderBy: null,
		search: null,
		searchBy: null,
		type: "demo",
		accountStatus: true,
	});
	const pagination = ref({ pageSize: 20, total: 0, current: 0 });

	const columnsData: ColumnsData[] = [
		{
			title: "Name",
			dataIndex: "fullName",
			key: "fullName",
			visible: true,
			sorter: true,
			fixed: true,
			customFilterDropdown: true,
			width: 200,
		},
		{
			title: "Date of Birth",
			dataIndex: "dob",
			key: "dob",
			visible: true,
			sorter: false,
			align: "center",
			width: 200,
		},
		{
			title: "Place of Birth",
			dataIndex: "pob",
			key: "pob",
			visible: true,
			align: "center",
			width: 200,
			customFilterDropdown: true,
		},
		{
			title: "R",
			dataIndex: "rodden",
			key: "rodden",
			visible: true,
			sorter: true,
			align: "center",
			width: 80,
		},
		{
			title: "16 Type",
			dataIndex: "jungianType",
			key: "jungianType",
			visible: true,
			align: "center",
			filters: [
				{ text: "ISTJ", value: "ISTJ" },
				{ text: "ISFJ", value: "ISFJ" },
				{ text: "INFJ", value: "INFJ" },
				{ text: "INTJ", value: "INTJ" },
				{ text: "ISTP", value: "ISTP" },
				{ text: "ISFP", value: "ISFP" },
				{ text: "INFP", value: "INFP" },
				{ text: "INTP", value: "INTP" },
				{ text: "ESTJ", value: "ESTJ" },
				{ text: "ESFJ", value: "ESFJ" },
				{ text: "ENFJ", value: "ENFJ" },
				{ text: "ENTJ", value: "ENTJ" },
				{ text: "ESTP", value: "ESTP" },
				{ text: "ESFP", value: "ESFP" },
				{ text: "ENFP", value: "ENFP" },
				{ text: "ENTP", value: "ENTP" },
			],
			width: 110,
		},
		{
			title: "Profession",
			dataIndex: "profession",
			key: "profession",
			visible: true,
			customFilterDropdown: true,
			align: "center",
			width: 150,
		},
		{
			title: "Height",
			dataIndex: "height",
			key: "height",
			visible: true,
			align: "center",
			width: 100,
		},
		{
			title: "Has Intro",
			dataIndex: "hasIntro",
			key: "hasIntro",
			visible: true,
			align: "center",
			width: 100,
		},
		{
			title: "Region",
			dataIndex: "celebrityRegion",
			key: "celebrityRegion",
			visible: true,
			customFilterDropdown: true,
			align: "center",
			width: 120,
		},
		{
			title: "Creation Dt",
			dataIndex: "createdAt",
			key: "createdAt",
			visible: true,
			sorter: true,
			customFilterDropdown: true,
			customCalendar: true,
			align: "center",
			width: 200,
		},
		{
			title: "Action",
			dataIndex: "action",
			key: "action",
			visible: true,
			align: "center",
			width: 100,
		},
	];

	const modalOpen = ref(false);
	const columns: Ref<ColumnsData[]> = ref<ColumnsData[]>(columnsData);
	const { height } = useScreenSize();

	const handleChange: TableProps["onChange"] = async (pagination, filters: any, sorter: any) => {
		if (filters.jungianType) {
			userListFilter.value.jungianType = filters.jungianType.join(",");
		}
		userListFilter.value.pageNo = pagination.current;
		userListFilter.value.pageLimit = pagination.pageSize;
		userListFilter.value.orderBy = sorter.order === "ascend" ? "asc" : sorter.order === "descend" ? "desc" : null;
		userListFilter.value.sortBy = sorter.order === undefined ? null : sorter.field;
		await localStorage.setItem("userListFilter", JSON.stringify({ userList: { ...userListFilter.value }, filtered: filtered.value }));
		fetchData();
	};

	const clearFilters = async () => {
		userListFilter.value = {
			pageNo: 1,
			pageLimit: 20,
			type: "demo",
			accountStatus: true,
		};
		await localStorage.setItem("userListFilter", JSON.stringify({ userList: { ...userListFilter.value }, filtered: filtered.value }));
		fetchData();
	};

	const handleSearch = async (selectedKeys: string[], confirm: () => void, dataIndex: string) => {
		confirm();
		if (dataIndex === "fullName" || dataIndex === "profession" || dataIndex === "celebrityRegion" || dataIndex === "pob") {
			userListFilter.value.search = selectedKeys[0] && selectedKeys[0].trim();
			userListFilter.value.searchBy = dataIndex;
		} else {
			filtered.value.push(dataIndex);
		}
		await localStorage.setItem("userListFilter", JSON.stringify({ userList: { ...userListFilter.value }, filtered: filtered.value }));
		setTimeout(() => {
			fetchData();
		}, 1000);
	};

	const handleReset = async (clearFilters: (arg0: { confirm: boolean }) => void, confirm: () => void, dataIndex: string) => {
		confirm();
		clearFilters({ confirm: true });
		if (dataIndex == "createdAt") {
			userListFilter.value.createdEndDate = null;
			userListFilter.value.createdStartDate = null;
		}
		userListFilter.value.search = "";
		userListFilter.value.searchBy = "";
		await localStorage.setItem("userListFilter", JSON.stringify({ userList: { ...userListFilter.value }, filtered: filtered.value }));
		setTimeout(() => {
			fetchData();
		}, 1000);
	};

	const disabledDate = (date: any) => {
		return dayjs(date).isAfter(dayjs().add(0, "days"), "day");
	};

	const startDate = (e: any) => {
		userListFilter.value.createdStartDate = e.format(`YYYY-MM-DDT00:00:00.SSS[Z]`);
	};

	const endDate = (e: any) => {
		userListFilter.value.createdEndDate = e.format("YYYY-MM-DDT11:59:00.SSS[Z]");
	};

	const handleDelete = (record: any) => {
		Modal.confirm({
			title: "Are you sure delete this celebrity?",
			icon: createVNode(ExclamationCircleOutlined),
			// content: "Some descriptions",
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			async onOk() {
				record.active = false;
				record.roles.push("blocked");
				await updateUserApi(record._id, { ...record }, true);
				fetchData();
			},
		});
	};
	const viewDetails = (record: any) => {
		for (let i = 0; i < userList.value.length; i++) {
			const user = userList.value[i];
			if (user._id == record._id) {
				selectedUser.value = user;
			}
		}
		modalOpen.value = true;
	};

	const fetchData = async () => {
		try {
			loading.value = true;
			const userListFromLocalStorage: any = localStorage.getItem("userListFilter");
			if (userListFromLocalStorage) {
				toJson.value = JSON.parse(userListFromLocalStorage);
			}
			await removeNullFromObj(toJson.value.userList);

			const apiRes: any = await getUserList(toJson.value?.userList?.type == "demo" ? { ...toJson.value.userList } : { ...userListFilter.value });
			userList.value = apiRes?.items;
			data.value = await getCelebritiesMap(userList.value);
			pagination.value.pageSize = Number(apiRes?.pageLimit);
			pagination.value.current = Number(apiRes?.pageNo);
			pagination.value.total = Number(apiRes?.TotalFilteredUser);
			// await localStorage.setItem("userListFilter", JSON.stringify({ userList: { ...userListFilter.value }, filtered: filtered.value }));
			await filteredUser(columns.value, toJson.value);

			loading.value = false;
		} catch (error) {
			loading.value = false;
			console.error("Error fetching data:", error);
		}
	};

	fetchData();
</script>
<style scoped>
	.table-operations {
		display: flex;
		justify-content: flex-end;
		gap: 8px;
	}

	.table-operations > button {
		margin-left: 8px;
	}

	.heading {
		font-size: 16px;
		font-weight: 600;
		margin-right: auto;
		color: #44142a !important;
	}

	.filterInput {
		margin-bottom: 8px;
		display: block;
	}
</style>
