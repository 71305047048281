import { fetchData } from "@/services";
import { notification } from "ant-design-vue";
import { onBeforeUnmount, onMounted, ref } from "vue";

export function calculateAge(dateOfBirth: string): number {
	const dob = new Date(dateOfBirth);
	const today = new Date();
	let age = today.getFullYear() - dob.getFullYear();
	const hasBirthdayOccurred = today.getMonth() > dob.getMonth() || (today.getMonth() === dob.getMonth() && today.getDate() >= dob.getDate());

	if (!hasBirthdayOccurred) {
		age--;
	}

	return age;
}

export const latLongToCoordinates = (latitude: number, longitude: number): string => {
	const latDirection = latitude >= 0 ? "N" : "S";
	const lonDirection = longitude >= 0 ? "E" : "W";

	const latDegrees = Math.floor(Math.abs(latitude));
	const latMinutesDecimal = (Math.abs(latitude) - latDegrees) * 60;
	const latMinutes = Math.floor(latMinutesDecimal);

	const lonDegrees = Math.floor(Math.abs(longitude));
	const lonMinutesDecimal = (Math.abs(longitude) - lonDegrees) * 60;
	const lonMinutes = Math.floor(lonMinutesDecimal);

	return `${latDegrees}° ${latMinutes}' ${latDirection}, ${lonDegrees}° ${lonMinutes}' ${lonDirection}`;
};

export const formattedDate = (date: Date) => {
	const dateObject = new Date(date);

	const formattedDate = dateObject.toLocaleString("en-GB", {
		day: "2-digit",
		month: "2-digit",
		year: "numeric",
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
	});
	return formattedDate;
};

export const dob = (date: Date) => {
	const dateObject = new Date(date);

	const day = dateObject.getDate();
	const month = dateObject.toLocaleString("default", { month: "long" });
	const year = dateObject.getFullYear();

	return `${day} ${month} ${year}`;
};

type NotificationType = "success" | "info" | "warning" | "error";
export const openNotificationWithIcon = (type: NotificationType, description: string) => {
	notification[type]({
		message: description,
		// description: description,
	});
};

export const notEmptyString = (str: any, minLength = 0): boolean => {
	if (typeof str === "string") {
		if (!minLength) {
			minLength = 0;
		}
		return str.trim().length > minLength;
	}
	return false;
};

export const buildQueryString = (criteria: any = null, literal = false) => {
	let str = "";
	if (criteria instanceof Object) {
		const parts: Array<string> = [];
		Object.entries(criteria).forEach((entry) => {
			const [key, val] = entry;

			let paramVal = val;
			if (typeof val === "string") {
				paramVal = literal ? val : encodeURIComponent(val);
			} else if (typeof val === "number" || typeof val === "boolean") {
				paramVal = val.toString();
			} else if (val instanceof Array) {
				paramVal = val.join(",");
			}
			if (paramVal) parts.push(key + "=" + paramVal);
		});
		if (parts.length > 0) {
			str = "?" + parts.join("&");
		}
	}
	return str;
};

export const extractDataObj = (res: any) => {
	if (res instanceof Object) {
		const { data } = res;
		if (data instanceof Object || data instanceof Array) {
			return data;
		}
	}
};

export const fetchContent = async (path = "", userId = "") => {
	return fetchData(path, false, userId);
};

export function convertTimestamp(timestamp: string, onlyDate = false, onlyTime = false): string {
	if (!timestamp) {
		return ""
	}
	const date = new Date(timestamp);

	const day = date.getDate().toString().padStart(2, "0");
	const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
	const year = date.getFullYear().toString();

	const hours = date.getHours().toString().padStart(2, "0");
	const minutes = date.getMinutes().toString().padStart(2, "0");

	const formattedDate = `${day}-${getMonthAbbreviation(month)}-${year} ${hours}:${minutes}`;
	if (onlyDate) {
		return `${day}-${getMonthAbbreviation(month)}-${year}`;
	}
	if (onlyTime) {
		return `${hours}:${minutes}:00`;
	}
	return formattedDate;
}

export function getMonthAbbreviation(month: string): string {
	const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	return monthNames[parseInt(month, 10) - 1];
}

export function getCurrentTime(date: string): string {
	const now = new Date(date);
	const hours = String(now.getUTCHours()).padStart(2, "0");
	const minutes = String(now.getUTCMinutes()).padStart(2, "0");
	const seconds = String(now.getUTCSeconds()).padStart(2, "0");

	return `${hours}:${minutes}:${seconds}`;
}

export function useScreenSize() {
	const height = ref(window.innerHeight);
	const width = ref(window.innerWidth);
	let resizeObserver: any;

	const handleResize = () => {
		height.value = window.innerHeight;
		width.value = window.innerWidth;
	};

	onMounted(() => {
		resizeObserver = new ResizeObserver(handleResize);
		resizeObserver.observe(document.body);

		window.addEventListener("resize", handleResize);
	});

	onBeforeUnmount(() => {
		if (resizeObserver) {
			resizeObserver.disconnect();
		}

		window.removeEventListener("resize", handleResize);
	});

	return { height, width };
}
export function firstLetterCapitalize(str: string) {
	if (typeof str !== "string") return str;
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export function readBinaryData(file: File): Promise<ArrayBuffer> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => resolve(reader.result as ArrayBuffer);
		reader.onerror = (error) => reject(error);
		reader.readAsArrayBuffer(file);
	});
}

export const calculateDate = (age: number) => {
	const currentDate = new Date();
	const birthYear = currentDate.getFullYear() - age;
	const startDate = new Date(birthYear, 0, 0, 0, 0, 0);
	const endDate = new Date(birthYear, 11, 31, 23, 59, 0);
	return { startDate, endDate };
};

export const calculateScrollWidth = (column: any) => {
	let width = 0;
	for (let i = 0; i < column.length; i++) {
		const element = column[i];
		if (element.visible) {
			width += element.width
		}
	}
	return width
}

export const truncateText = (text: string, limit: number) => {
	if (text.length > limit) {
		return text.slice(0, limit) + "...";
	}
	return text;
};

export const removeNullFromObj = async (object: any) => {
	for (const i in object) {
		const element = object[i];
		if (element == null || element == undefined || element == "") {
			delete object[i];
		}
	}
}