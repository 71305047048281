<template>
	<div v-if="!loading" :style="{ fontSize: '25px', margin: '10px 10px 15px 10px', display: 'flex', justifyContent: 'space-between' }">
		<a-breadcrumb class="heading" separator="|">
			<a-breadcrumb-item :style="{ color: '#44142a', cursor: 'pointer', fontSize: '16px', fontWeight: '700' }" @click="closeDetails"><TeamOutlined /> Admins</a-breadcrumb-item>
			<a-breadcrumb-item :style="{ fontSize: '16px', fontWeight: '700' }">{{ formData._id }}</a-breadcrumb-item>
		</a-breadcrumb>
		<CloseCircleOutlined :style="{ paddingRight: '10px' }" @click="closeDetails" />
	</div>
	<div v-if="loading" class="loading-wrapper">
		<a-spin />
	</div>
	<a-form v-if="!loading" :label-col="labelCol" :wrapper-col="wrapperCol" layout="horizontal" :model="formData">
		<div :style="{ display: 'grid', justifyContent: 'center', marginTop: '70px' }">
			<div :style="{ display: 'flex', padding: '5px', justifyContent: 'space-between' }">
				<AdminProfile :formData="formData"></AdminProfile>
				<AdminNotification :newUploads="newUploads" :deleteFile="deleteFile" :formData="formData"></AdminNotification>
			</div>
			<div :style="{ display: 'grid', justifyContent: 'center', marginTop: '50px' }">
				<a-button size="large" @click="submitUserDetails" type="primary" :style="{ width: '200px' }">Save</a-button>
			</div>

			<!-- <div :style="{ display: 'flex', justifyContent: 'space-between', margin: '20px' }"> -->
			<!-- <div :style="{ display: 'flex', alignItems: 'center', cursor: 'pointer' }">
					<LeftCircleOutlined :style="{ fontSize: '40px', color: '#44142a' }" @click="previousDetails" />
					<div :style="{ marginLeft: '10px', fontSize: '16px', fontWeight: 700, color: '#44142a' }">Previous<br />User</div>
				</div> -->
			<!-- <div :style="{ display: 'flex', alignItems: 'center', cursor: 'pointer' }">
					<div :style="{ marginRight: '10px', fontSize: '16px', fontWeight: 700, color: '#44142a' }">Next<br />User</div>
					<RightCircleOutlined :style="{ fontSize: '40px', color: '#44142a' }" @click="nextDetails" />
				</div> -->
			<!-- </div> -->
		</div>
	</a-form>
</template>

<script lang="ts" setup>
	import { CloseCircleOutlined, LeftCircleOutlined, RightCircleOutlined, TeamOutlined } from "@ant-design/icons-vue";
	import { ref } from "vue";
	import AdminProfile from "@/components/AdminDetails/AdminProfile.vue";
	import AdminNotification from "@/components/AdminDetails/AdminNotification.vue";
	import { deleteImage, getUser, getUserMap, uploadImage, userSample } from "@/services/api/user";
	import { updateUserApi } from "@/services/api/auth";
	import router from "@/router";
	const id: any = router.currentRoute.value.params.id;

	const loading = ref(false);
	const user = ref(userSample);
	const formData = ref<any>({});
	const labelCol = { style: { width: "130px" } };
	const wrapperCol = { span: 14 };
	const newUploads = ref([]);
	const deleteFile = ref([]);
	const getUserData: any = localStorage.getItem("user");

	const submitUserDetails = async () => {
		formData.value.nickName = formData.value.fullName;
		formData.value.dob = `${formData.value.selectDob.format("YYYY-MM-DD")}T${formData.value.tob.format("HH:mm:ss.SSS[Z]")}`;

		for (const key in formData.value) {
			const element = formData.value[key];
			let foundPreference = false;
			for (let i = 0; i < formData.value.preferences.length; i++) {
				const preferencesElement = formData.value.preferences[i];
				if (preferencesElement.key === key) {
					preferencesElement.value = element;
					foundPreference = true;
					break;
				}
				if (!foundPreference && key === "profession") {
					formData.value.preferences.push({ type: "string", key, value: element });
				}
			}
		}

		newUploads.value.map(async (file: any) => {
			const formDetails = new FormData();
			formDetails.set("file", file);
			await uploadImage(id, formData.value?.fullName, formDetails);
		});
		deleteFile.value.length > 0 &&
			deleteFile.value.map(async (fileName: string) => {
				await deleteImage(id, fileName);
			});
		await updateUserApi(id, { ...formData.value, roles: [formData.value.roles] });
		router.back();
	};
	const closeDetails = async () => {
		router.back();
	};

	const fetchData = async () => {
		try {
			loading.value = true;
			if (!id) {
				return;
			}
			const apiRes: any = await getUser(id);
			user.value = await getUserMap(apiRes?.user);
			formData.value = user.value;
			loading.value = false;
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	fetchData();
</script>

<style scoped>
	.loading-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;
	}
</style>
