<template>
	<div class="IpWhiteListContainer">
		<div class="table-operations">
			<a-breadcrumb class="heading" separator="/">
				<a-breadcrumb-item :style="{ color: '#44142a' }"
					><span>{{ userName }}</span
					>'s IP Whitelist
				</a-breadcrumb-item>
			</a-breadcrumb>
			<div class="addIpAdressContaoiner">
				<a-input v-model:value="ip" style="width: 200px" id="inputIp"></a-input>
				<a-input v-model:value="ipv6" style="width: 300px" id="inputIp"></a-input>
				<a-button type="primary" class="saveBtn" @click="saveData">Save</a-button>
			</div>
		</div>

		<a-table :columns="columns" :data-source="ipAddresses" :loading="loading" row-key="_id" bordered :pagination="false" :rowClassName="(record:any, index:number) => ipExistIntable(record.ipAddress.toString())">
			<template #index="{ text }">
				{{ text }}
			</template>
			<template #createdAt="{ text }">
				{{ formatDate(text) }}
			</template>
			<template #action="{ record }" #bodyCell="{ column, record }">
				<a-button type="danger" @click="removeRecord(record)">
					<DeleteOutlined />
				</a-button>
			</template>
			<!-- Apply conditional class to rows -->
			<template v-slot:default="{ text, record, index }">
				<tr>
					<td>{{ index + 1 }}</td>
					<td>{{ record.ipAddress }}</td>
					<td>{{ formatDate(record.createdAt) }}</td>
					<td>
						<a-button type="danger" @click="removeRecord(record)">
							<DeleteOutlined />
						</a-button>
					</td>
				</tr>
			</template>
		</a-table>
	</div>
</template>

<script lang="ts" setup>
	import { deleteIpWhiteListApi, getIpWhiteListApi, putIpWhiteListApi, getIPV4Address, getIPV6Address } from "@/services/api/user";
	import { openNotificationWithIcon } from "@/utils";
	import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
	import { Modal } from "ant-design-vue";
	import { createVNode, ref } from "vue";
	const getUserData: any = localStorage.getItem("user");
	const userData = JSON.parse(getUserData);
	const loading = ref(false);
	const ipAddresses = ref<any[]>([]);
	const ip = ref("");
	const ipv6 = ref("");
	const firstLetter = ref("");
	let userName = "";

	if (getUserData) {
		const toJson = JSON.parse(getUserData);
		firstLetter.value = toJson.fullName.charAt(0).toUpperCase();
		userName = toJson.fullName;
	}

	const fetchData = async () => {
		try {
			loading.value = true;
			const apiRes: any = await getIpWhiteListApi(userData._id);
			apiRes.ips.forEach((ip: any, index: number) => {
				ip.index = index + 1;
			});
			ipAddresses.value = apiRes?.ips;
			loading.value = false;
		} catch (error) {
			loading.value = false;
			console.error("Error fetching data:", error);
		}
	};
	fetchData();

	const columns = [
		{
			title: "Index",
			dataIndex: "index",
			key: "index",
			width: 10,
			slots: { customRender: "indexRender" },
		},
		{
			title: "IP Address",
			dataIndex: "ipAddress",
			key: "ipAddress",
			width: 50,
		},
		{
			title: "Created Date",
			dataIndex: "createdAt",
			slots: { customRender: "createdAt" },
			width: 50,
		},
		{
			title: "Action",
			dataIndex: "remove",
			key: "remove",
			visible: true,
			align: "center",
			width: 50,
			slots: { customRender: "action" },
		},
	];

	const removeRecord = async (record: any) => {
		let strings = ipAddresses.value
			.filter((ip: any) => ip?.ipAddress !== record?.ipAddress)
			.map((ip: any) => {
				return ip.ipAddress;
			});
		Modal.confirm({
			title: "Are you sure to delete this IP Address?",
			icon: createVNode(ExclamationCircleOutlined),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			async onOk() {
				try {
					const deleteResult = await deleteIpWhiteListApi(userData._id, record.ipAddress);
					if (deleteResult.valid) {
						fetchData();
						openNotificationWithIcon("success", "IP Address deleted successfully");
					} else {
						openNotificationWithIcon("error", "Failed to delete IP Address");
					}
				} catch (error) {
					console.error("Error deleting IP Address:", error);
					openNotificationWithIcon("error", "An error occurred while deleting IP Address");
				}
			},
		});
	};

	// Function to format date and time
	const formatDate = (dateTime: string) => {
		const options: Intl.DateTimeFormatOptions = {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
		};
		return new Date(dateTime).toLocaleString("en-US", options);
	};

	// Function to fetch IPV4
	const fetchIPV4Address = async () => {
		try {
			const ipAddress = await getIPV4Address();
			if (ipAddress) {
				ip.value = ipAddress;
			}
		} catch (error) {
			console.error("Error fetching IP Address:", error);
		}
	};
	fetchIPV4Address();

	const fetchIPV6Address = async () => {
		try {
			const ipAddress = await getIPV6Address();
			if (ipAddress) {
				ipv6.value = ipAddress;
			}
		} catch (error) {
			console.error("Error fetching IP Address:", error);
		}
	};
	fetchIPV6Address();

	// Function to save data
	const saveData = async () => {
		try {
			const data: any = {
				userId: userData._id,
				ipAddress: ip.value,
			};
			if (ipv6.value !== "") {
				data.ip6Address = ipv6.value;
			}
			const response = await putIpWhiteListApi(userData._id, data);
			if (response && response.valid) {
				await fetchData();
				openNotificationWithIcon("success", "IP Address added successfully");
			} else {
				openNotificationWithIcon("error", "Failed to add IP Address");
			}
		} catch (error) {
			console.error("Error adding IP Address:", error);
			openNotificationWithIcon("error", "An error occurred while adding IP Address");
		}
	};

	// Function to check if IP Address is in the table
	const isIPInTable = (ipAddress: string) => {
		return ipAddresses.value.some((item) => item.ipAddress === ipAddress);
	};

	// Function to apply colour for IPV4adress
	const ipExistIntable = (rawIp: string) => {
		if (rawIp === ip.value || rawIp === ipv6.value) {
			return "highlighted";
		}
		return "table-row-light";
	};
</script>

<style scoped>
	.IpWhiteListContainer {
		padding: 20px 100px;
	}
	.table-operations {
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
	}
	.heading {
		font-size: 16px;
		font-weight: 600;
		margin-right: auto;
	}
	.IpWhiteListContainer .ant-table {
		border: 1px solid #e8e8e8;
	}
	.addIpAdressContaoiner {
		display: flex;
		gap: 15px;
	}
</style>
