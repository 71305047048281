<template>
	<a-modal :open="modalOpen" title="" :footer="null" @cancel="closeSinglePairDetails" :style="{ maxWidth: '700px', width: '100%' }">
		<h2>{{ userDetails.otherDetail.rules }}</h2>
		<!-- <div :style="{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }"></div> -->
		<div :style="{ display: 'flex', marginTop: '10px', flexWrap: 'wrap', width: '100%', justifyContent: 'center', columnGap: '20px' }">
			<div>
				<div class="flexDisplay">
					<h3>User A to B Grahas Position :</h3>
					<a-typography-text :style="{ marginTop: '5px' }" type="secondary">{{ userDetails?.otherDetail?.abs || 0 }}</a-typography-text>
				</div>
				<div class="mainDiv">
					<div class="subDiv">Person A</div>
					<div>
						<div :style="{ display: 'flex' }">
							<h4>Name :</h4>
							<a-typography-text class="typography" type="secondary">{{ userDetails.userADetail.name }}</a-typography-text>
						</div>
						<div :style="{ display: 'flex' }">
							<h4>Gender :</h4>
							<a-typography-text class="typography" type="secondary">{{ userDetails.userADetail.gender }}</a-typography-text>
						</div>
						<div :style="{ display: 'flex' }">
							<h4>DOB :</h4>
							<a-typography-text class="typography" type="secondary">{{ userDetails.userADetail.dob }}</a-typography-text>
						</div>
						<div :style="{ display: 'flex' }">
							<h4>TOB :</h4>
							<a-typography-text class="typography" type="secondary">{{ userDetails.userADetail.tob }}</a-typography-text>
						</div>
						<div :style="{ display: 'flex' }">
							<h4>UTC :</h4>
							<a-typography-text class="typography" type="secondary">{{ userDetails.userADetail.utc }}</a-typography-text>
						</div>
						<div :style="{ display: 'flex' }">
							<h4>POB :</h4>
							<a-typography-text class="typography" type="secondary">{{ userDetails.userADetail.pob }}</a-typography-text>
						</div>
						<div :style="{ display: 'flex' }">
							<h4>geo-coordinate :</h4>
							<a-typography-text class="typography" type="secondary">{{ userDetails.userADetail.geoCordinate }}</a-typography-text>
						</div>
						<div :style="{ display: 'flex' }">
							<h4>{{ userDetails.otherDetail.userAPlanet }} :</h4>
							<a-typography-text class="typography" type="secondary">{{ userDetails.otherDetail.userALng }}</a-typography-text>
						</div>
						<div :style="{ display: 'flex' }">
							<h4>{{ userDetails.otherDetail.userBPlanet }} :</h4>
							<a-typography-text class="typography" type="secondary">{{ userDetails.otherDetail.userA2Lng }}</a-typography-text>
						</div>
					</div>
				</div>
			</div>

			<div>
				<div class="flexDisplay">
					<h3>User B to A Grahas Position :</h3>
					<a-typography-text :style="{ marginTop: '5px' }" type="secondary">{{ userDetails?.otherDetail?.abs2 }}</a-typography-text>
				</div>
				<div class="mainDiv">
					<div class="subDiv">Person B</div>
					<div>
						<div :style="{ display: 'flex' }">
							<h4>Name :</h4>
							<a-typography-text class="typography" type="secondary">{{ userDetails.userBDetail.name }}</a-typography-text>
						</div>
						<div :style="{ display: 'flex' }">
							<h4>Gender :</h4>
							<a-typography-text class="typography" type="secondary">{{ userDetails.userBDetail.gender }}</a-typography-text>
						</div>
						<div :style="{ display: 'flex' }">
							<h4>DOB :</h4>
							<a-typography-text class="typography" type="secondary">{{ userDetails.userBDetail.dob }}</a-typography-text>
						</div>
						<div :style="{ display: 'flex' }">
							<h4>TOB :</h4>
							<a-typography-text class="typography" type="secondary">{{ userDetails.userBDetail.tob }}</a-typography-text>
						</div>
						<div :style="{ display: 'flex' }">
							<h4>UTC :</h4>
							<a-typography-text class="typography" type="secondary">{{ userDetails.userBDetail.utc }}</a-typography-text>
						</div>
						<div :style="{ display: 'flex' }">
							<h4>POB :</h4>
							<a-typography-text class="typography" type="secondary">{{ userDetails.userBDetail.pob }}</a-typography-text>
						</div>
						<div :style="{ display: 'flex' }">
							<h4>geo-coordinate :</h4>
							<a-typography-text class="typography" type="secondary">{{ userDetails.userBDetail.geoCordinate }}</a-typography-text>
						</div>
						<div :style="{ display: 'flex' }">
							<h4>{{ userDetails.otherDetail.userBPlanet }} :</h4>
							<a-typography-text class="typography" type="secondary">{{ userDetails.otherDetail.userBLng }}</a-typography-text>
						</div>
						<div :style="{ display: 'flex' }">
							<h4>{{ userDetails.otherDetail.userAPlanet }} :</h4>
							<a-typography-text class="typography" type="secondary">{{ userDetails.otherDetail.userB2Lng }}</a-typography-text>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footerClass">
			<a-button :style="{ width: '20%' }" @click="closeSinglePairDetails">Close</a-button>
		</div>
	</a-modal>
</template>

<script setup lang="ts">
	const { closeSinglePairDetails, modalOpen, userDetails } = defineProps(["closeSinglePairDetails", "modalOpen", "userDetails"]);
</script>

<style scoped>
	h3 {
		margin-right: 10px;
	}
	h2 {
		text-align: center;
	}
	h4 {
		margin-right: 20px;
		margin-left: auto;
		text-align: end;
		width: 100%;
	}

	.footerClass {
		display: flex;
		justify-content: end;
		margin-top: 20px;
	}
	.mainDiv {
		width: 300px;
		border: 2px solid rgb(190, 190, 190);
		position: relative;
		border-radius: 8px;
		padding: 5px;
		margin-top: 10px;
		height: 90%;
	}
	.subDiv {
		display: flex;
		position: absolute;
		top: -12px;
		background: #ffff;
		margin-left: 20px;
		padding: 0 5px;
		font-weight: 700;
	}

	.flexDisplay {
		display: flex;
	}

	.typography {
		width: 100%;
	}
</style>
