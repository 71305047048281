<template>
	<a-drawer :open="openDrawerValue" @close="closeDrawer" width="650px" title="User Profile">
		<div>
			<a-upload class="clearfix" v-model:file-list="fileList" list-type="picture-card" :remove="handleRemove" :before-upload="beforeUpload" @preview="handlePreview" accept="image/*">
				<div v-if="fileList && fileList.length < 6">
					<plus-outlined />
					<div style="margin-top: 8px">Select File</div>
				</div>
			</a-upload>
			<a-modal :open="previewVisible" :title="previewTitle" :footer="null" @cancel="handleCancel">
				<img alt="example" style="width: 100%" :src="previewImage" />
			</a-modal>
		</div>
		<template #footer>
			<div class="footerClass">
				<a-button size="large" style="" @click="closeDrawer" class="clear-filter customCancel">Cancel</a-button>
				<a-button type="primary" size="large" @click="saveChanges()" style="width: 127px">Save</a-button>
			</div>
		</template>
	</a-drawer>
</template>

<script lang="ts" setup>
	import { PlusOutlined } from "@ant-design/icons-vue";
	import { ref } from "vue";
	import { BASE_URL_FOR_MEDIA } from "@/.config";
	import { uploadImage, deleteImage } from "@/services/api/user";
	import router from "@/router";
	const { openDrawerValue, closeDrawer, formData } = defineProps(["openDrawerValue", "closeDrawer", "formData"]);

	function getBase64(file: File) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}
	const previewVisible = ref(false);
	const previewImage = ref("");
	const previewTitle = ref("");
	const fileList: any = ref([]);
	const deleteFile: any = ref([]);
	const newUploads: any = ref([]);

	formData.profiles.map((e: any) => {
		e.mediaItems.map((element: any, index: number) => {
			fileList.value.push({ uid: index, name: element.filename, url: `${BASE_URL_FOR_MEDIA}${element.filename}` });
		});
	});

	const handleCancel = () => {
		previewVisible.value = false;
		previewTitle.value = "";
	};
	const handlePreview = async (file: any["fileList"][number]) => {
		if (!file.url && !file.preview) {
			file.preview = (await getBase64(file.originFileObj)) as string;
		}
		previewImage.value = file.url || file.preview;
		previewVisible.value = true;
		previewTitle.value = file.name || file.url.substring(file.url.lastIndexOf("/") + 1);
	};

	const handleRemove = (file: any) => {
		deleteFile.value.push(file.name);
		const index = fileList.value.indexOf(file);
		const newFileList = fileList.value.slice();
		newFileList.splice(index, 1);
		fileList.value = newFileList;

		const newFileIndex = newUploads.value.indexOf(file);
		const newUploadsFileList = newUploads.value.slice();
		newUploadsFileList.splice(newFileIndex, 1);
		newUploads.value = newUploadsFileList;
	};

	const beforeUpload = async (file: any) => {
		fileList.value = [...fileList.value, file];
		newUploads.value.push(file);
		return false;
	};

	const saveChanges = async () => {
		newUploads.value.map(async (file: any) => {
			const formDetails = new FormData();
			formDetails.set("file", file);
			await uploadImage(formData._id, formData.nickName, formDetails);
		});
		deleteFile.value.length > 0 &&
			deleteFile.value.map(async (fileName: string) => {
				await deleteImage(formData._id, fileName);
			});
		closeDrawer();
		router.back();
	};
</script>
<style scoped>
	.ant-upload-select-picture-card i {
		font-size: 32px;
		color: #999;
	}

	.ant-upload-select-picture-card .ant-upload-text {
		margin-top: 8px;
		color: #666;
	}

	.customCancel {
		width: 127px;
		margin-right: 20px;
	}

	.footerClass {
		display: flex;
		justify-content: end;
	}
</style>
