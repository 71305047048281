<template>
	<a-modal :open="openRulesModal" title="Add synastry rule" @cancel="closeRulesModal" :footer="null">
		<a-form ref="formRef" :model="formData" :label-col="labelCol" :wrapper-col="wrapperCol" @finish="onSave">
			<a-form-item name="rules" label="Synastry Rule " :rules="{ required: true, message: 'Please select rule' }">
				<a-select :options="rulesListData" v-model:value="formData.rules" @select="select" showSearch :filterOption="(input:any, option:any) => option.label.toLowerCase().startsWith(input.toLowerCase()) ">
					<template v-slot:notFoundContent>
						<Spin size="small" v-if="loading" />
						<Empty :image="Empty.PRESENTED_IMAGE_SIMPLE" v-else />
					</template>
				</a-select>
			</a-form-item>
			<a-form-item name="communication" label="Communication" :rules="{ required: true, message: 'please enter communication value' }">
				<a-input type="number" v-model:value="formData.communication"></a-input>
			</a-form-item>
			<a-form-item name="connection" label="Connection" :rules="{ required: true, message: 'please enter connection value' }">
				<a-input type="number" v-model:value="formData.connection"></a-input>
			</a-form-item>
			<a-form-item name="energy" label="Energy" :rules="{ required: true, message: 'please enter energy value' }">
				<a-input type="number" v-model:value="formData.energy"></a-input>
			</a-form-item>
			<a-form-item name="stability" label="Stability" :rules="{ required: true, message: 'please enter stability value' }">
				<a-input type="number" v-model:value="formData.stability"></a-input>
			</a-form-item>
			<div class="footerClass">
				<a-button :style="{ width: '20%' }" @click="closeRulesModal">Cancel</a-button>
				<a-button type="primary" html-type="submit" :style="{ width: '20%', marginLeft: '10px' }">Save</a-button>
			</div>
		</a-form>
	</a-modal>

	<table class="synastry-table">
		<thead>
			<tr>
				<th>Index</th>
				<th>Rules</th>
				<th>Communication</th>
				<th>Connection</th>
				<th>Energy</th>
				<th>Stability</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="(i, e) in synastryRulesArray" :key="i">
				<td>{{ e + 1 }}</td>
				<td>{{ i.rules }}</td>
				<td>{{ i.communication }}</td>
				<td>{{ i.connection }}</td>
				<td>{{ i.energy }}</td>
				<td>{{ i.stability }}</td>
			</tr>
		</tbody>
	</table>
</template>

<script setup lang="ts">
	import { Empty, Spin } from "ant-design-vue";
	import { ref, toRefs } from "vue";

	const props: any = defineProps(["synastryRulesArray", "onSave", "formData", "rulesListData", "closeRulesModal", "openRulesModal"]);
	const { synastryRulesArray, onSave, formData, rulesListData, closeRulesModal, openRulesModal } = toRefs(props);

	const loading = ref(false);
	const labelCol = { style: { width: "130px" } };
	const wrapperCol = { span: 14 };

	const select = () => {
		formData.value.communication = "";
		formData.value.connection = "";
		formData.value.energy = "";
		formData.value.stability = "";
	};
</script>

<style scoped>
	.title {
		color: #44142a;
		cursor: pointer;
		font-size: 16px;
		font-weight: 700;
	}
	.header {
		display: flex;
		justify-content: flex-end;
		place-items: center;
		gap: 10px;
		flex-wrap: wrap;
	}
	.synastry-table tbody tr td {
		height: 45px;
		width: 10%;
		padding: 5px 0px;
		text-align: center;
	}
	.synastry-table tbody tr {
		border: 2px solid rgb(190, 190, 190);
	}
	.synastry-table tbody tr td {
		border: 2px solid rgb(190, 190, 190);
	}
	.synastry-table thead tr th {
		border: 2px solid rgb(190, 190, 190);
	}
	.synastry-table thead tr {
		position: sticky;
		top: 0;
		background-color: white;
		z-index: 1;
		height: 50px;
	}

	.synastry-table {
		width: 73%;
		margin-left: auto;
		margin-right: auto;
	}

	.heading {
		font-weight: 700;
		font-size: 30px;
		margin: 20px;
	}

	.iconNearLine {
		margin: 15px;
		background-color: rgb(236, 236, 236);
		border: 1px solid rgb(236, 236, 236);
		width: 80%;
		height: 0.5px;
	}

	.footerClass {
		display: flex;
		justify-content: end;
	}
</style>
