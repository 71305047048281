<template>
	<div v-if="!loading" :style="{ fontSize: '25px', margin: '10px 10px 15px 10px', display: 'flex', justifyContent: 'space-between' }">
		<a-breadcrumb class="heading" separator="|">
			<a-breadcrumb-item :style="{ color: '#44142a', cursor: 'pointer', fontSize: '16px', fontWeight: '700' }" @click="closeDetails"><TeamOutlined /> Celebrities</a-breadcrumb-item>
			<a-breadcrumb-item :style="{ fontSize: '16px', fontWeight: '700' }">{{ formData._id }}</a-breadcrumb-item>
		</a-breadcrumb>
		<CloseCircleOutlined :style="{}" @click="closeDetails" />
	</div>
	<div v-if="loading" class="loading-wrapper">
		<a-spin />
	</div>
	<a-form v-if="!loading" :model="formData" :label-col="labelCol" :wrapper-col="wrapperCol" layout="horizontal" :disabled="componentDisabled" @finish="onFinish" @finishFailed="onFinishFailed">
		<div :style="{ display: 'grid', justifyContent: 'center', marginTop: '70px' }">
			<div :style="{ display: 'flex', padding: '5px' }">
				<CelebritiesProfile :formData="formData"></CelebritiesProfile>
				<CelebritiesNotification :newUploads="newUploads" :deleteFile="deleteFile" :formData="formData"></CelebritiesNotification>
			</div>
			<div :style="{ display: 'grid', justifyContent: 'center', marginTop: '50px' }">
				<a-button size="large" html-type="submit" type="primary" :style="{ width: '200px' }">Save</a-button>
			</div>
		</div>
		<!-- <div :style="{ display: 'flex', justifyContent: 'space-between', margin: '10px' }">
			<div :style="{ display: 'flex', alignItems: 'center', cursor: 'pointer' }">
				<LeftCircleOutlined :style="{ fontSize: '40px', color: '#44142a' }" @click="previousDetails" />
				<div :style="{ marginLeft: '10px', fontSize: '16px', fontWeight: 700, color: '#44142a' }">Previous<br />User</div>
			</div>
			<div>
				<a-button size="large" @click="submitUserDetails" type="primary" :style="{ width: '200px', marginTop: '5px' }">Save</a-button>
			</div>
			<div :style="{ display: 'flex', alignItems: 'center', cursor: 'pointer' }">
				<div :style="{ marginRight: '10px', fontSize: '16px', fontWeight: 700, color: '#44142a' }">Next<br />User</div>
				<RightCircleOutlined :style="{ fontSize: '40px', color: '#44142a' }" @click="nextDetails" />
			</div>
		</div> -->
	</a-form>
</template>

<script lang="ts" setup>
	import { CloseCircleOutlined, TeamOutlined } from "@ant-design/icons-vue";
	import { ref } from "vue";
	import CelebritiesProfile from "@/components/CelebritiesDetails/CelebritiesProfile.vue";
	import CelebritiesNotification from "@/components/CelebritiesDetails/CelebritiesNotification.vue";
	import { deleteImage, getUser, getUserMap, uploadImage, userSample } from "@/services/api/user";
	import { saveUserChart, updateUserApi } from "@/services/api/auth";
	import router from "@/router";

	const componentDisabled = ref(false);
	const loading = ref(false);
	const user = ref(userSample);
	const formData = ref<any>({});
	const labelCol = { style: { width: "130px" } };
	const wrapperCol = { span: 14 };
	const newUploads = ref([]);
	const deleteFile = ref([]);

	const onFinish = async () => {
		try {
			formData.value.dob = `${formData.value.selectDob.format("YYYY-MM-DD")}T${formData.value.tob.format("HH:mm:ss.SSS[Z]")}`;
			formData.value.height = Number((formData.value.height / 100).toFixed(2));
			for (const key in formData.value) {
				const element = formData.value[key];
				let foundPreference = false;
				for (let i = 0; i < formData.value.preferences.length; i++) {
					const preferencesElement = formData.value.preferences[i];
					if (preferencesElement.key === key) {
						preferencesElement.value = element;
						foundPreference = true;
						break;
					}
				}
				if (!foundPreference && (key === "height" || key === "rodden" || key === "georegion" || key === "jungian_type" || key == "public" || key == "profession" || key == "socioregions")) {
					formData.value.preferences.push({ type: "string", key, value: element });
					if (key == "socioregions") {
						formData.value.preferences.push({ type: "string", key: "georegion", value: element });
						formData.value.preferences.push({ type: typeof element, key: "socioregions", value: element });
					}
				}
			}
			const publicCaptions = [formData.value.credit];
			const id: any = router.currentRoute.value.params.id;
			formData.value.nickName = formData.value.fullName;
			newUploads.value.map(async (file: any) => {
				const formDetails = new FormData();
				formDetails.set("file", file);
				await uploadImage(id, formData.value?.fullName, formDetails);
			});
			deleteFile.value.length > 0 &&
				deleteFile.value.map(async (fileName: string) => {
					await deleteImage(id, fileName);
				});
			await updateUserApi(id, { publicCaptions, ...formData.value });

			const dataForChart = {
				name: formData.value.nickName,
				gender: formData.value.gender,
				user: formData.value._id,
				lat: formData.value.geo.lat,
				lng: formData.value.geo.lng,
				alt: 0,
				isDefaultBirthChart: true,
				datetime: formData.value.dob,
				tzOffset: 0,
				roddenValue: 1000,
				type: "person",
				eventType: "birth",
				_id: "",
				pob: formData.value.pob,
				celebrityRegion: formData.value.celebrityRegion,
			};
			await saveUserChart(dataForChart);
			router.back();
		} catch (error) {
			console.log(error);
		}
	};
	const closeDetails = async () => {
		router.back();
	};

	const fetchData = async () => {
		try {
			loading.value = true;
			const id: any = router.currentRoute.value.params.id;
			if (!id) {
				return;
			}
			const apiRes: any = await getUser(id);
			user.value = await getUserMap(apiRes?.user);
			formData.value = user.value;
			loading.value = false;
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	fetchData();

	const onFinishFailed = (errorInfo: any) => {
		console.log("Failed:", errorInfo);
	};

	const previousDetails = () => {};

	const nextDetails = () => {};
</script>
<style scoped>
	.loading-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;
	}
</style>
