<template>
	<div :style="{ padding: '20px 20px 0px 20px' }">
		<div class="table-operations">
			<a-breadcrumb class="heading" separator="/">
				<a-breadcrumb-item :style="{ color: '#44142a' }"><AuditOutlined /> Reported</a-breadcrumb-item>
			</a-breadcrumb>
			<!-- <a-button type="primary"
				><template #icon><DownloadOutlined /></template
			></a-button> -->
			<a-button @click="clearFilters" type="primary" class="clear-filter">Clear filters</a-button>
		</div>

		<a-table
			size="small"
			bordered
			@change="handleChange"
			:columns="columns"
			:data-source="data"
			:pagination="{
				current: pagination.current,
				pageSize: pagination.pageSize,
				total: pagination.total,
				position: ['topRight'],
				size: 'small',
				showTotal: (total:any, range:any) => `${range[0]}-${range[1]} of ${total}`,
				showSizeChanger: false, 
			}"
			:loading="loading"
			:scroll="{ x: 1, y: height - 245 }"
			class="tableBody"
		>
			<template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
				<div style="padding: 8px">
					<a-input
						:placeholder="`Search ${column.title}`"
						:value="selectedKeys[0]"
						class="filterInput"
						@change="(user:any) => setSelectedKeys(user.target.value ? [user.target.value] : [])
			"
						@pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
					/>
					<a-button :style="{ width: '90px', marginRight: '8px' }" @click="handleReset(clearFilters, confirm)"> Reset </a-button>
					<a-button type="primary" @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
						<template #icon><SearchOutlined /></template>
						Search
					</a-button>
				</div>
			</template>
			<template #bodyCell="{ text, column, record }">
				<template v-if="column && column.dataIndex === 'totalReports'">
					<a-tag color="pink" :style="{ padding: '4px 35px', cursor: 'pointer' }" @click="openDrawer(record)">{{ text }}</a-tag>
				</template>
				<template v-if="column && column.dataIndex === 'nickName'">
					<a>
						<router-link :to="'/report/' + record._id" :data="data">{{ truncateText(text, 18) }}</router-link>
					</a>
				</template>
				<template v-else-if="column.dataIndex !== 'totalReports' && text">
					<div>
						{{ truncateText(text, 100) }}
					</div>
				</template>
			</template>
		</a-table>
		<ReportedNestedDrawer v-if="openNestedDrawer" :openNestedDrawer="openNestedDrawer" :closeNestedDrawer="closeNestedDrawer" :innerData="innerData" :loading="loading" :userDetails="userDetails" :selectedId="selectedId" />
	</div>
</template>
<script lang="ts" setup>
	import { DownloadOutlined, AuditOutlined } from "@ant-design/icons-vue";
	import { Ref, ref } from "vue";
	import type { TableProps } from "ant-design-vue";
	import { SearchOutlined } from "@ant-design/icons-vue";
	import { ColumnsData } from "@/interface/Members.interface";
	import { getReportersList, reportedMapData, reportedNestedMapData } from "@/services/api/user";
	import AdditionalFieldsDrawer from "@/components/AdditionalFieldsDrawer.vue";
	import ReportedNestedDrawer from "./ReportedNestedDrawer.vue";
	import { truncateText, useScreenSize } from "@/utils";

	const filteredInfo = ref();
	const loading = ref(false);
	const userList = ref<any[]>([]);
	const data: any = ref([]);
	const innerData: any = ref([]);
	const userListFilter: any = ref({
		sort: "modified",
		search: null,
		searchBy: null,
		orderBy: null,
		sortBy: null,
	});
	const defaultValue = ref("All");
	const pagination = ref({ pageSize: 20, total: 0, current: 0 });
	const userListPagination = ref({ pageNo: 1, pageLimit: 20 });
	const openNestedDrawer = ref(false);
	let selectedId = ref("");

	const columnsData: ColumnsData[] = [
		{
			title: "Name",
			dataIndex: "nickName",
			key: "nickName",
			// align: "center",
			width: 100,
			customFilterDropdown: true,
		},
		{
			title: "Email Id",
			dataIndex: "identifier",
			key: "identifier",
			align: "center",
			width: 200,
			customFilterDropdown: true,
		},
		{
			title: "Last Reported Dt",
			dataIndex: "lastReportedAt",
			key: "lastReportedAt",
			align: "center",
			width: 150,
			sorter: true,
		},
		{
			title: "Times Reported",
			dataIndex: "totalReports",
			key: "totalReports",
			align: "center",
			sorter: true,
			width: 100,
		},
		{
			title: "Reporters",
			dataIndex: "totalUniqueReportedBy",
			key: "totalUniqueReportedBy",
			align: "center",
			sorter: true,
			width: 100,
		},

		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			align: "center",
			width: 100,
			filters: [
				{ text: "Active", value: "active" },
				{ text: "Dormant", value: "dormant" },
				{ text: "Blocked", value: "blocked" },
			],
			filterMultiple: false,
			filteredValue: filteredInfo.value?.status || null,
		},
		{
			title: "On Hold",
			dataIndex: "onHold",
			key: "onHold",
			align: "center",
			width: 100,
			filters: [
				{ text: "Yes", value: "true" },
				{ text: "No", value: "false" },
			],
			filterMultiple: false,
			filteredValue: filteredInfo.value?.onHold || null,
		},
		{
			title: "Passes",
			dataIndex: "userSwipes",
			key: "userSwipes",
			visible: false,
			width: 90,
			sorter: false,
			align: "center",
		},
		{
			title: "Likes",
			dataIndex: "userLikes",
			key: "userLikes",
			visible: false,
			width: 90,
			sorter: false,
			align: "center",
		},
		{
			title: "Super likes",
			dataIndex: "userSuperLikes",
			key: "userSuperLikes",
			visible: false,
			width: 90,
			sorter: false,
			align: "center",
		},
		{
			title: "Matches",
			dataIndex: "matchesMade",
			key: "matchesMade",
			width: 90,
			visible: false,
			sorter: false,
			align: "center",
		},
		{
			title: "Like To",
			dataIndex: "likeCount",
			key: "likeCount",
			width: 90,
			visible: false,
			sorter: false,
			align: "center",
		},
		{
			title: "Super To",
			dataIndex: "superLikeCount",
			key: "superLikeCount",
			width: 90,
			visible: false,
			sorter: false,
			align: "center",
		},
	];

	const columns: Ref<ColumnsData[]> = ref<ColumnsData[]>(columnsData);
	const { height } = useScreenSize();
	const userDetails = ref();

	const handleChange: TableProps["onChange"] = (pagination, filters, sorter: any) => {
		filteredInfo.value = filters;
		if (sorter.field == "numReporters") sorter.field = "reporters";
		if (sorter.field == "numReports") sorter.field = "num";
		userListPagination.value.pageNo = Number(pagination.current);
		userListPagination.value.pageLimit = Number(pagination.pageSize);
		userListFilter.value.orderBy = sorter.order === "ascend" ? "asc" : sorter.order === "descend" ? "desc" : null;
		userListFilter.value.sortBy = sorter.order === undefined ? null : sorter.field;
		if (filters.status && filters.status.length > 0) {
			userListFilter.value.search = filters.status[0]; // First selected filter value
			userListFilter.value.searchBy = "status"; // The column key for filtering
		}
		if (filters.onHold && filters.onHold.length > 0) {
			userListFilter.value.search = filters.onHold[0];
			userListFilter.value.searchBy = "onHold";
		}
		fetchData();
	};

	const clearFilters = () => {
		filteredInfo.value = null;
		userListFilter.value.search = null;
		userListFilter.value.searchBy = null;
		userListFilter.value.sortBy = null;
		userListFilter.value.orderBy = null;
		defaultValue.value = "all";
		columns.value = columns.value.map((col) => {
			if (col.dataIndex === "status") {
				col.filteredValue = null; // Clear filter for status column
			}
			if (col.dataIndex === "onHold") {
				col.filteredValue = null; // Clear filter for onHold column, if needed
			}
			return col;
		});
		fetchData();
	};

	const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
		userListFilter.value.searchBy = dataIndex;
		userListFilter.value.search = selectedKeys[0].trim();
		confirm();
		fetchData();
	};

	const handleReset = (clearFilters: any, confirm: any) => {
		clearFilters();
		userListFilter.value.search = null;
		userListFilter.value.searchBy = null;
		confirm();
		fetchData();
	};

	const closeNestedDrawer = () => {
		openNestedDrawer.value = false;
		innerData.value = [];
		userDetails.value = "";
	};

	const openDrawer = async (record: any) => {
		openNestedDrawer.value = true;
		selectedId.value = record._id;
		userDetails.value = record;
		innerData.value = await reportedNestedMapData(record.reports);
	};

	const selectReason = (e: any) => {
		if (e == "all") {
			userListFilter.value.search = null;
		} else {
			userListFilter.value.search = e;
			userListFilter.value.searchBy = "reason";
		}
		fetchData();
	};

	const storeIds = async (apiRes: any) => {
		const ids = apiRes.items.map((a: any) => a._id);
		localStorage.setItem("reportedIds", JSON.stringify(ids));
	};

	const fetchData = async () => {
		try {
			loading.value = true;
			const apiRes: any = await getReportersList({ ...userListPagination.value }, userListFilter.value);
			storeIds(apiRes);
			userList.value = apiRes?.items;
			data.value = await reportedMapData(userList.value);
			pagination.value.total = apiRes?.total;
			pagination.value.current = Number(userListPagination.value.pageNo);
			loading.value = false;
		} catch (error) {
			loading.value = false;
			console.error("Error fetching data:", error);
		}
	};

	fetchData();
</script>
<style scoped>
	.table-operations {
		display: flex;
		justify-content: flex-end;
		gap: 8px;
	}

	.table-operations > button {
		margin-left: 8px;
	}

	.heading {
		font-size: 16px;
		font-weight: 600;
		margin-right: auto;
		color: #44142a !important;
	}

	.filterInput {
		margin-bottom: 8px;
		display: block;
	}
</style>
