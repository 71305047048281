<template>
	<div class="mainDiv">
		<div class="header">
			<a-breadcrumb class="heading" separator="|">
				<a-breadcrumb-item class="title">Synastry</a-breadcrumb-item>
			</a-breadcrumb>
			<h1 :style="{ marginLeft: 'auto' }">Rule/Score set :</h1>
			<a-select label="Rule/Score set :" v-model:value="defaultValue" :style="{ width: '200px' }" :options="items" @select="select">
				<template v-slot:notFoundContent>
					<Spin size="small" v-if="loading" />
					<Empty :image="Empty.PRESENTED_IMAGE_SIMPLE" v-else />
				</template>
			</a-select>
			<a-button type="primary" class="btn" @click="astroUpdate">Save to Rule/Score set</a-button>
			<a-button @click="() => (openModal = true)"> New</a-button>
			<NewSynastryModal :openModal.async="openModal" :isImport="isImport" :closeDrawer="closeDrawer" :loading="loading" :items="items" :fetchData="fetchData" :orbSettingCheckbox="orbSettingCheckbox" />
			<a-button @click="() => ((openModal = true), (isImport = true))">Import</a-button>
			<a-button @click="jsonToExcel(selectUserData)">Export</a-button>
			<a-button @click="() => router.push({ path: 'multiPair' })">Test multiple pairs</a-button>
			<a-button @click="handleDelete" type="primary"><DeleteOutlined /></a-button>
		</div>
		<div :style="{ position: 'absolute', width: '100%', zIndex: '1', marginTop: '350px', height: '100%', backgroundColor: '#ffffff8a' }" v-if="loading">
			<Spin
				:style="{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100%',
					width: '100%',
				}"
			></Spin>
		</div>
		<div :style="{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', width: '100%' }" v-if="!loading">
			<div class="grahas">
				<Grahas :planets="planets" :planetsChanged="planetsChanged" :orbCheckboxArray="orbCheckboxArray" :orbChanged="orbChanged" :orbValueArray="orbValueArray" />
			</div>
			<div class="synastryTable">
				<SynastryTable :synastryCategoryArray="synastryCategoryArray" :heartLogoArray="heartLogoArray" :synastryVsGmArray="synastryVsGmArray" />
			</div>
		</div>
		<div v-if="!loading">
			<div :style="{ display: 'flex', justifyContent: 'center' }">
				<div class="iconNearLine"></div>
			</div>
			<div class="header">
				<a-breadcrumb class="heading" separator="|">
					<a-breadcrumb-item class="title">Synastry 207 Rule Scores</a-breadcrumb-item>
				</a-breadcrumb>
				<a-button type="primary" :style="{ marginLeft: 'auto' }" @click="openRulesModalFun"><EditOutlined /> Add/Update</a-button>
			</div>
			<RulesScore :synastryRulesArray="synastryRulesArray" :formData="formData" :rulesListData="rulesListData" :onSave="onSave" :openRulesModal="openRulesModal" :closeRulesModal="closeRulesModal" />
		</div>
	</div>
</template>

<script setup lang="ts">
	import Grahas from "@/components/AstroSetting/Grahas.vue";
	import SynastryTable from "@/components/AstroSetting/SynastryTable.vue";
	import RulesScore from "@/components/AstroSetting/RulesScore.vue";
	import { createVNode, reactive, ref } from "vue";
	import { getAstroSettingList, deleteSynastry, jsonToExcel, synastryTableHeading, heartLogoHeading, synastryVsGmHeading } from "@/services/api/user";
	import { Empty, Modal, Spin } from "ant-design-vue";
	import { astroUpdateApi, synastry, getSynastry, synastryRulesNameArray, orbSettingCheckbox } from "@/services/api/user";
	import { openNotificationWithIcon } from "@/utils";
	import NewSynastryModal from "@/components/AstroSetting/NewSynastryModal.vue";
	import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
	import router from "@/router";

	const planets: any = ref([]);
	const items: any = ref([]);
	const loading = ref(false);
	const defaultValue = ref();
	const orbCheckboxArray: any = ref([]);
	const orbSettingValue: any = ref([]);
	const orbValueArray: any = ref([]);
	const selectUserData: any = ref();
	const synastryCategoryArray: any = ref([]);
	const heartLogoArray: any = ref([]);
	const synastryVsGmArray: any = ref({});
	const synastryRulesArray: any = ref([]);
	const openModal = ref(false);
	const isImport = ref(false);
	const rulesListData: any = ref([]);
	const openRulesModal = ref(false);
	const synastryData = ref();

	const closeDrawer = () => {
		openModal.value = false;
		isImport.value = false;
	};

	const fetchData = async () => {
		items.value = [];
		orbValueArray.value = [];
		loading.value = true;
		const data = await getAstroSettingList();
		const reverseData = data?.slice().reverse();
		synastryData.value = data[data.length - 1];

		reverseData?.map((e: any) => {
			items.value.push({ key: e?._id, value: e?._id, label: e?.name, data: e });
		});

		defaultValue.value = data.length > 0 ? data[data.length - 1]?.name : "";
		selectUserData.value = data.length > 0 ? data[data.length - 1] : "";

		await grahasData(data ? data[data.length - 1] : []);
		loading.value = false;
	};

	fetchData();

	const grahasData = async (data: any) => {
		planets.value = data?.planets;
		synastryCategoryArray.value = await getSynastry(data?.synastryCategory, synastryTableHeading);
		const getHeartLogo = await getSynastry(data?.heartLogo, heartLogoHeading);
		heartLogoArray.value = await synastry(getHeartLogo, heartLogoHeading);
		synastryVsGmArray.value = await getSynastry(data?.synastryVsGm, synastryVsGmHeading);

		for (let i = 0; i < data?.orbs?.length; i++) {
			const element = data?.orbs[i];
			orbCheckboxArray.value.push(element.key);
			orbSettingValue.value.push(element);
		}
		for (let i = 0; i < orbSettingCheckbox.value.length; i++) {
			const checkBoxCel = orbSettingCheckbox.value[i];
			const matchingOrbSetting = orbSettingValue.value.find((orbSetting: any) => orbSetting.key == checkBoxCel.value);
			orbValueArray.value.push({ ...checkBoxCel, inputValue: matchingOrbSetting ? Number(matchingOrbSetting.value) : "", inputValueExist: matchingOrbSetting ? true : false });
		}

		data?.synastryRules?.forEach((item: any) => {
			const findRulesIndex = synastryRulesNameArray.value.findIndex((rule: any) => rule.rules === item.rules);
			if (findRulesIndex && findRulesIndex > 0) {
				const element = synastryRulesNameArray.value[findRulesIndex];
				const orbsValue = orbValueArray.value.find((orb: any) => orb.value === element.orbsName);
				element.communication = item.communication;
				element.connection = item.connection;
				element.energy = item.energy;
				element.stability = item.stability;
				element.minOrbsValue = element.deg - orbsValue.inputValue;
				element.maxOrbsValue = element.deg + orbsValue.inputValue;
				element.orbsValue = orbsValue.inputValue;
				if (planets.value.includes(element.userAPlanet) && planets.value.includes(element.userBPlanet) && orbCheckboxArray.value.includes(element.orbsName)) {
					element.isVisible = true;
				} else {
					element.isVisible = false;
				}
			}
		});
		synastryRulesArray.value = synastryRulesNameArray.value.filter((e: any) => e.isVisible);
		await fetchRulesListData(orbCheckboxArray.value, planets.value);
	};

	const select = async (index: number, record: any) => {
		loading.value = true;
		planets.value = [];
		orbCheckboxArray.value = [];
		orbSettingValue.value = [];
		orbValueArray.value = [];
		synastryRulesArray.value = [];
		synastryVsGmArray.value = [];
		synastryCategoryArray.value = [];
		heartLogoArray.value = {};
		selectUserData.value = record.data;
		synastryRulesNameArray.value.forEach((item: any) => {
			item.communication = 0;
			item.connection = 0;
			item.energy = 0;
			item.stability = 0;
		});
		await grahasData(record.data);
		loading.value = false;
	};

	//***************************************************Synastry rules add modal********************************************************* */

	const formData = reactive<any>({
		rules: "",
		communication: "",
		connection: "",
		energy: "",
		stability: "",
	});

	const onSave = (value: any) => {
		const findRulesIndex = synastryRulesNameArray.value.findIndex((rule: any) => rule.rules === value.rules);
		if (findRulesIndex && findRulesIndex > 0) {
			const element = synastryRulesNameArray.value[findRulesIndex];
			const orbsValue = orbValueArray.value.find((orb: any) => orb.value === element.orbsName);
			element.communication = formData.communication;
			element.connection = formData.connection;
			element.energy = formData.energy;
			element.stability = formData.stability;
			element.minOrbsValue = element.deg - orbsValue.inputValue;
			element.maxOrbsValue = element.deg + orbsValue.inputValue;
			element.orbsValue = orbsValue.inputValue;
			element.isVisible = true;
		}
		synastryRulesArray.value = synastryRulesNameArray.value.filter((e: any) => e.isVisible);
		openRulesModal.value = false;
	};

	const fetchRulesListData = async (orbArray: any, planetArray: any) => {
		loading.value = true;
		rulesListData.value = [];
		synastryRulesNameArray.value.map((e: any) => {
			if (planetArray.includes(e.userAPlanet) && planetArray.includes(e.userBPlanet) && orbArray.includes(e.orbsName)) {
				rulesListData.value.push({ key: e.rules, value: e.rules, label: e.rules });
			}
		});
		loading.value = false;
	};

	const closeRulesModal = () => {
		openRulesModal.value = false;
	};

	const openRulesModalFun = () => {
		openRulesModal.value = true;
		formData.rules = "";
		formData.communication = "";
		formData.connection = "";
		formData.energy = "";
		formData.stability = "";
	};

	const onChangeRulesChange = async () => {
		const rules: any = synastryRulesNameArray.value.filter((rule: any) => rule.isVisible);
		rules.forEach((item: any) => {
			const findRulesIndex = synastryRulesNameArray.value.findIndex((rule: any) => rule.rules === item.rules);
			if (findRulesIndex && findRulesIndex > 0) {
				const element = synastryRulesNameArray.value[findRulesIndex];
				if (planets.value.includes(element.userAPlanet) && planets.value.includes(element.userBPlanet) && orbCheckboxArray.value.includes(element.orbsName)) {
					element.isVisible = true;
				} else {
					element.isVisible = false;
				}
			}
		});
		synastryRulesArray.value = synastryRulesNameArray.value.filter((e: any) => e.isVisible);
	};

	//************************************************************************************************************************************** */

	const planetsChanged = async (data: any) => {
		planets.value = data;
		await fetchRulesListData(orbCheckboxArray.value, data);
		await onChangeRulesChange();
	};

	const orbChanged = async (data: any) => {
		orbCheckboxArray.value = data;
		orbValueArray.value.map((e: any) => {
			const matchingOrbSetting: any = data.find((data: any) => data == e.value);
			if (matchingOrbSetting) {
				e.inputValueExist = true;
			} else {
				e.inputValueExist = false;
				e.inputValue = " ";
			}
		});
		await fetchRulesListData(data, planets.value);
		await onChangeRulesChange();
	};

	const astroUpdate = async () => {
		const orbs: any = [];
		orbCheckboxArray.value.map((orb: any) => {
			const matchingOrbSetting = orbValueArray.value.find((orbSetting: any) => orbSetting.value == orb);
			orbs.push({ key: orb, value: Number(matchingOrbSetting.inputValue) });
		});
		const synastryCategoryClearArray: any = [];
		for (let i = 0; i < synastryCategoryArray.value.length; i++) {
			const element = synastryCategoryArray.value[i];
			synastryCategoryArray.value[0].val2 = 4;
			element.val1 = synastryCategoryArray.value[i + 1] ? synastryCategoryArray.value[i + 1].val2 : "";
			element.val3 = synastryCategoryArray.value[i + 1] ? (synastryCategoryArray.value[i + 1].val2 * 100) / 4 : "";
			element.val4 = (element.val2 * 100) / 4;
			synastryCategoryArray.value[4].val1 = 0;
			synastryCategoryArray.value[4].val3 = 0;
			synastryCategoryClearArray.push({ label: element.label, val1: element.val1, val2: element.val2, val3: element.val3, val4: element.val4 });
		}

		const synastryCategory = await synastry(synastryCategoryClearArray, synastryTableHeading);

		const heartLogoObj = {
			veryHigh: { val1: Number(heartLogoArray.value.veryHigh.val1), val2: 100 },
			high: { val1: Number(heartLogoArray.value.high.val1), val2: Number(heartLogoArray.value.veryHigh.val1) },
			medium: { val1: Number(heartLogoArray.value.veryLow.val2), val2: Number(heartLogoArray.value.high.val1) },
			veryLow: { val1: 0, val2: Number(heartLogoArray.value.veryLow.val2) },
		};
		const heartLogo = heartLogoObj;
		const synastryVsGm = await synastry(synastryVsGmArray.value, synastryVsGmHeading);

		const data = {
			user: selectUserData.value.user._id,
			notes: "",
			categories: selectUserData.value.categories,
			collections: selectUserData.value.collections,
			name: selectUserData.value.name,
			orbs,
			synastryCategory,
			heartLogo,
			synastryVsGm,
			planets: planets.value,
			synastryRules: synastryRulesNameArray.value.filter((e: any) => e.isVisible),
		};
		const res = await astroUpdateApi(selectUserData.value._id, data);
		orbCheckboxArray.value = [];
		orbValueArray.value = [];

		if (res.valid) {
			openNotificationWithIcon("success", "Synastry updated successfully");
		} else {
			openNotificationWithIcon("error", "Something want wrong");
		}
		fetchData();
	};

	const handleDelete = () => {
		Modal.confirm({
			title: "Delete protocol",
			icon: createVNode(ExclamationCircleOutlined),
			content: "Are you sure you want to delete this Rule/Score set?",
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			async onOk() {
				await deleteSynastry(selectUserData.value._id, selectUserData.value.user._id);
				fetchData();
			},
		});
	};
</script>

<style scoped>
	.heading {
		font-weight: 700;
		font-size: 30px;
		margin: 20px;
	}
	.mainDiv {
		display: grid;
		position: relative;
	}

	.header {
		display: flex;
		justify-content: flex-end;
		place-items: center;
		gap: 10px;
		flex-wrap: wrap;
	}

	.option-divider {
		border: 1px solid black;
		padding: 8px 0;
		margin: 8px 0;
	}

	.grahas {
		margin: 30px 10px;
		display: flex;
		justify-content: space-between;
	}

	.synastryTable {
		margin: 30px 10px;
		width: 100%;
		max-width: 600px;
	}

	.title {
		color: #44142a;
		cursor: pointer;
		font-size: 16px;
		font-weight: 700;
	}
</style>
