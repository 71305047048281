<template>
	<div class="mainDiv" :style="{ marginBottom: '25px' }">
		<div class="subDiv">Rules Average</div>
		<table class="synastry-table">
			<thead>
				<tr>
					<th></th>
					<th>Sum</th>
					<th>{{ "Total %" }}</th>
					<th>{{ ">100%" }}</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(i, e) in singlePairArray" :key="e">
					<td :style="{ textAlign: 'end', fontWeight: e == 6 ? '700' : '0' }">{{ i.value }}</td>
					<td :style="{ fontWeight: e == 6 ? '700' : '0' }">{{ i.sum.toFixed(2) }}</td>
					<td :style="{ fontWeight: e == 6 ? '700' : '0' }">{{ i.per.toFixed(2) }}</td>
					<td :style="{ fontWeight: e == 6 ? '700' : '0' }">{{ i.outOfPer.toFixed(2) }}</td>
				</tr>
			</tbody>
		</table>
	</div>

	<div class="mainDiv" :style="{ marginBottom: '25px' }">
		<div class="subDiv">Scores</div>
		<h1 class="heading">Total Score: {{ singlePairArray[6].per.toFixed(2) }}% <img src="/heart-logo.ico" :style="{ width: '25px', marginLeft: '10px' }" /></h1>
		<div :style="{ display: 'flex', justifyContent: 'space-between' }">
			<h4>Communication</h4>
			<h4>{{ comPer.outOfPer.toFixed(2) }}%</h4>
		</div>
		<a-progress :percent="comPer.outOfPer" :show-info="false" stroke-color="#44142a" strokeWidth="15" trailColor="#FDE3CF" />
		<div :style="{ display: 'flex', justifyContent: 'space-between' }">
			<h4>Connection</h4>
			<h4>{{ conPer.outOfPer.toFixed(2) }}%</h4>
		</div>
		<a-progress :percent="conPer.outOfPer" :show-info="false" stroke-color="#44142a" strokeWidth="15" trailColor="#FDE3CF" />
		<div :style="{ display: 'flex', justifyContent: 'space-between' }">
			<h4>Energy</h4>
			<h4>{{ energyPer.outOfPer.toFixed(2) }}%</h4>
		</div>
		<a-progress :percent="energyPer.outOfPer" :show-info="false" stroke-color="#44142a" strokeWidth="15" trailColor="#FDE3CF" />
		<div :style="{ display: 'flex', justifyContent: 'space-between' }">
			<h4>Stability</h4>
			<h4>{{ stabilityPer.outOfPer.toFixed(2) }}%</h4>
		</div>
		<a-progress :percent="stabilityPer.outOfPer" :show-info="false" stroke-color="#44142a" strokeWidth="15" trailColor="#FDE3CF" />
		<div :style="{ display: 'flex', justifyContent: 'space-between' }">
			<h4>Guna Milan</h4>
			<h4>{{ gmPer.outOfPer.toFixed(2) }}%</h4>
		</div>
		<a-progress :percent="gmPer.outOfPer" :show-info="false" stroke-color="#44142a" strokeWidth="15" trailColor="#FDE3CF" />
	</div>

	<div class="mainDiv">
		<div class="subDiv">Text Distribution by Category</div>
		<table class="synastry-table">
			<thead>
				<tr>
					<th></th>
					<th>Com</th>
					<th>Con</th>
					<th>Ene</th>
					<th>Stab</th>
				</tr>
			</thead>
			<tbody class="last-table">
				<tr v-for="i in synastryTableHeading">
					<td :style="{ textAlign: 'end' }">{{ i.value }}</td>
					<td>{{ i.val1?.toFixed(2) }}</td>
					<td>{{ i.val2?.toFixed(2) }}</td>
					<td>{{ i.val3?.toFixed(2) }}</td>
					<td>{{ i.val4?.toFixed(2) }}</td>
				</tr>
			</tbody>
		</table>
	</div>

	<div class="mainDiv" :style="{ marginTop: '20px' }">
		<div class="subDiv">Heart Score</div>
		<table class="synastry-table">
			<thead>
				<tr>
					<th></th>
				</tr>
			</thead>
			<tbody class="last-table">
				<tr v-for="i in synastryHeartAnimation">
					<td :style="{ textAlign: 'end' }">{{ i.value }}</td>
					<td>{{ i.val1 }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script setup lang="ts">
	import { rulesCount } from "../../../services/api/user";
	const { singlePairData } = defineProps(["singlePairData"]);

	let totalPer = 0;
	let totalScore = 0;
	const singlePairArray = rulesCount.map((ruleCount) => {
		if (ruleCount.key === "totalSynastry") {
			const per = singlePairData.synPer || 0;
			return { value: ruleCount.value, sum: 0, per: per, outOfPer: per > 100 ? 99.5 : per };
		} else if (ruleCount.key === "gunaMilan") {
			const per = singlePairData.gunaPer || 0;
			return { value: ruleCount.value, sum: singlePairData.gunaTotal || 0, per, outOfPer: per > 100 ? 99.5 : per };
		} else if (ruleCount.key === "totalScore") {
			totalScore = singlePairData.score || 0;
			return { value: ruleCount.value, sum: 0, per: totalScore, outOfPer: totalScore > 100 ? 99.5 : totalScore };
		} else {
			const key = ruleCount.key;
			const sum = singlePairData.outRules.reduce((acc: any, rule: any) => acc + (rule[key] || 0), 0);
			const per = (sum / singlePairData.outRules.length / 4) * 100;
			if (ruleCount.key != "totalSynastry" && ruleCount.key != "gunaMilan" && ruleCount.key != "totalScore") {
				totalPer = totalPer + per;
			}
			return { value: ruleCount.value, sum, avg: sum / singlePairData.outRules.length, per, outOfPer: per > 100 ? 99.5 : per };
		}
	});

	const comPer: any = singlePairArray.find((e: any) => e.value == "Communication");
	const conPer: any = singlePairArray.find((e: any) => e.value == "Connection");
	const energyPer: any = singlePairArray.find((e: any) => e.value == "Energy");
	const stabilityPer: any = singlePairArray.find((e: any) => e.value == "Stability");
	const gmPer: any = singlePairArray.find((e: any) => e.value == "Guna Milan");

	if (comPer.avg > singlePairData.synastryCategory.veryHigh.val1) {
	}

	const veryLowVal1 = comPer.avg > singlePairData.synastryCategory.veryLow.val1 && comPer.avg < singlePairData.synastryCategory.veryLow.val2 ? comPer.avg : 0;
	const veryLowVal2 = conPer.avg > singlePairData.synastryCategory.veryLow.val1 && conPer.avg < singlePairData.synastryCategory.veryLow.val2 ? conPer.avg : 0;
	const veryLowVal3 = energyPer.avg > singlePairData.synastryCategory.veryLow.val1 && energyPer.avg < singlePairData.synastryCategory.veryLow.val2 ? energyPer.avg : 0;
	const veryLowVal4 = stabilityPer.avg > singlePairData.synastryCategory.veryLow.val1 && stabilityPer.avg < singlePairData.synastryCategory.veryLow.val2 ? stabilityPer.avg : 0;

	const synastryTableHeading = [
		{ key: "veryHigh", value: "Very high", val1: comPer.avg > singlePairData.synastryCategory.veryHigh.val1 ? comPer.avg : 0, val2: conPer.avg > singlePairData.synastryCategory.veryHigh.val1 ? conPer.avg : 0, val3: energyPer.avg > singlePairData.synastryCategory.veryHigh.val1 ? energyPer.avg : 0, val4: stabilityPer.avg > singlePairData.synastryCategory.veryHigh.val1 ? stabilityPer.avg : 0 },
		{ key: "high", value: "High", val1: comPer.avg > singlePairData.synastryCategory.high.val1 ? comPer.avg : 0, val2: conPer.avg > singlePairData.synastryCategory.high.val1 ? conPer.avg : 0, val3: energyPer.avg > singlePairData.synastryCategory.high.val1 ? energyPer.avg : 0, val4: stabilityPer.avg > singlePairData.synastryCategory.high.val1 ? stabilityPer.avg : 0 },
		{ key: "medium", value: "Medium", val1: comPer.avg > singlePairData.synastryCategory.medium.val1 ? comPer.avg : 0, val2: conPer.avg > singlePairData.synastryCategory.medium.val1 ? conPer.avg : 0, val3: energyPer.avg > singlePairData.synastryCategory.medium.val1 ? energyPer.avg : 0, val4: stabilityPer.avg > singlePairData.synastryCategory.medium.val1 ? stabilityPer.avg : 0 },
		{ key: "low", value: "Low", val1: comPer.avg > singlePairData.synastryCategory.low.val1 ? comPer.avg : 0, val2: conPer.avg > singlePairData.synastryCategory.low.val1 ? conPer.avg : 0, val3: energyPer.avg > singlePairData.synastryCategory.low.val1 ? energyPer.avg : 0, val4: stabilityPer.avg > singlePairData.synastryCategory.low.val1 ? stabilityPer.avg : 0 },
		{ key: "veryLow", value: "Very Low", val1: veryLowVal1, val2: veryLowVal2, val3: veryLowVal3, val4: veryLowVal4 },
	];

	const synastryHeartAnimation = [
		{ key: "veryHigh", value: "Very high", val1: singlePairData.heartLogo.veryHigh.val1 < totalScore && singlePairData.heartLogo.veryHigh.val2 > totalScore ? 1 : 0 },
		{ key: "high", value: "High", val1: singlePairData.heartLogo.high.val1 < totalScore && singlePairData.heartLogo.high.val2 > totalScore ? 1 : 0 },
		{ key: "medium", value: "Medium", val1: singlePairData.heartLogo.medium.val1 < totalScore && singlePairData.heartLogo.medium.val2 > totalScore ? 1 : 0 },
		{ key: "veryLow", value: "Very Low", val1: singlePairData.heartLogo.veryLow.val1 < totalScore && singlePairData.heartLogo.veryLow.val2 > totalScore ? 1 : 0 },
	];
</script>

<style scoped>
	h4 {
		margin-top: 5px;
		margin-bottom: 0px;
	}
	.synastry-table tbody tr td {
		padding: 5px 15px;
	}

	.synastry-table {
		width: 100%;
		margin-left: "auto";
	}

	.synastry-table tbody tr td:first-child {
		border: 0px;
		text-align: end;
	}
	.mainDiv {
		border: 2px solid rgb(190, 190, 190);
		position: relative;
		border-radius: 8px;
		padding: 15px;
	}
	.last-table tr td {
		border: 2px solid rgb(190, 190, 190);
	}
	.subDiv {
		display: flex;
		position: absolute;
		top: -12px;
		background: #ffff;
		margin-left: 20px;
		padding: 0 5px;
		font-weight: 700;
	}

	.heading {
		font-weight: 700;
		text-align: center;
	}
</style>
