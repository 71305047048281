<template>
	<a-layout-header>
		<div :style="{ display: 'flex', background: '#f5f5f5', height: '100%' }">
			<div
				:style="{
					width: '201.6px',
					background: 'white',
					borderRadius: '0 0 8px 0',
					// height: '70px',
					display: 'grid',
					justifyContent: 'center',
				}"
			>
				<img src="../assets/fy-logo.svg" :style="{ width: '130px', paddingTop: '15px' }" />
				<!-- <div
					:style="{
						fontWeight: 600,
						color: '#44142a',
						fontSize: '15px',
						paddingLeft: '20px',
					}"
					>
					Admin Panel
				</div> -->
			</div>

			<div
				:style="{
					marginLeft: '5px',
					background: 'white',
					borderRadius: '0 0 0 8px',
					width: 'calc(100vw - 200px)',
					display: 'flex',
					justifyContent: 'space-between',
					padding: '10px',
				}"
			>
				<div :style="{ marginLeft: 'auto', display: 'flex', justifyContent: 'center' }">
					<h4 :style="{ fontWeight: '700', paddingTop: '9px', marginRight: '10px' }">App Mode :</h4>
					<a-select v-model:value="v1" :style="{ width: '130px' }">
						<a-select-option value="d" @click="redirectOnDevlopment"> Development</a-select-option>
						<a-select-option value="l" @click="redirectOnLive"> Live</a-select-option>
					</a-select>
				</div>
				<div :style="{ marginLeft: 'auto', display: 'flex', justifyContent: 'space-between' }">
					<a-avatar :style="{ color: '#f56a00', backgroundColor: '#fde3cf' }">{{ firstLetter }}</a-avatar>
					<div :style="{ paddingTop: '9px', marginLeft: '10px' }">{{ userName }}</div>
				</div>
				<div :style="{ marginLeft: '20px', marginRight: '1rem' }">
					<a-button type="primary" @click="logout">Logout</a-button>
				</div>
			</div>
		</div>
	</a-layout-header>
</template>

<script lang="ts" setup>
	import router from "@/router";
	import { ref } from "vue";
	const v1 = ref();
	const firstLetter = ref("");
	let userName = "";
	const userData: any = localStorage.getItem("user");
	if (userData) {
		const toJson = JSON.parse(userData);
		firstLetter.value = toJson.fullName.charAt(0).toUpperCase();
		userName = toJson.fullName;
	}

	const logout = async () => {
		localStorage.removeItem("user");
		localStorage.removeItem("columns");
		localStorage.removeItem("ids");
		localStorage.removeItem("reportedIds");
		localStorage.removeItem("userListFilter");
		await router.push("/login");
	};

	const setDefaultOption = () => {
		const url = window.location.href;
		if (url.includes("admin-dev")) {
			v1.value = "d";
		} else if (url.includes("localhost")) {
			v1.value = "d";
		} else {
			v1.value = "l";
		}
	};
	setDefaultOption();

	const redirectOnLive = async () => {
		window.location.href = "https://admin.findingyou.co";
	};

	const redirectOnDevlopment = async () => {
		window.location.href = "https://admin-dev.findingyou.co";
	};
</script>
<style scoped></style>
