<template>
	<div :style="{ fontSize: '25px', margin: '10px 10px 15px 10px', display: 'flex', justifyContent: 'space-between' }">
		<a-breadcrumb class="heading" separator="|">
			<a-breadcrumb-item :style="{ color: '#44142a', cursor: 'pointer', fontSize: '16px', fontWeight: '700' }" @click="closeCelebritiesDrawer"><StarOutlined /> Celebrities</a-breadcrumb-item>
		</a-breadcrumb>
		<CloseCircleOutlined @click="closeCelebritiesDrawer" />
	</div>
	<a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol" layout="horizontal" @finish="onFinish" @finishFailed="onFinishFailed">
		<div :style="{ display: 'grid', justifyContent: 'center', marginTop: '70px' }">
			<div :style="{ display: 'flex', padding: '5px' }">
				<CelebritiesProfile :formData="formState"></CelebritiesProfile>
				<CelebritiesNotification :deleteFile="deleteFile" :newUploads="newUploads" :formData="formState"></CelebritiesNotification>
			</div>
			<div :style="{ display: 'grid', justifyContent: 'center', marginTop: '50px' }">
				<a-button size="large" html-type="submit" type="primary" :style="{ width: '200px' }">Save</a-button>
			</div>
		</div>
		<!-- <div :style="{ display: 'flex', justifyContent: 'space-between', margin: '10px' }">
			<div :style="{ display: 'flex', alignItems: 'center', cursor: 'pointer' }">
				<LeftCircleOutlined :style="{ fontSize: '40px', color: '#44142a' }" @click="previousDetails" />
				<div :style="{ marginLeft: '10px', fontSize: '16px', fontWeight: 700, color: '#44142a' }">Previous<br />User</div>
			</div>
			<div>
				<a-button size="large" @click="submitUserDetails" type="primary" :style="{ width: '200px', marginTop: '5px' }">Save</a-button>
			</div>
			<div :style="{ display: 'flex', alignItems: 'center', cursor: 'pointer' }">
				<div :style="{ marginRight: '10px', fontSize: '16px', fontWeight: 700, color: '#44142a' }">Next<br />User</div>
				<RightCircleOutlined :style="{ fontSize: '40px', color: '#44142a' }" @click="nextDetails" />
			</div>
		</div> -->
	</a-form>
</template>

<script lang="ts" setup>
	import { CloseCircleOutlined, LeftCircleOutlined, RightCircleOutlined, StarOutlined } from "@ant-design/icons-vue";
	import { reactive, ref } from "vue";
	import CelebritiesProfile from "@/components/CelebritiesDetails/CelebritiesProfile.vue";
	import CelebritiesNotification from "@/components/CelebritiesDetails/CelebritiesNotification.vue";
	import { addUserApi, saveUserChart, updateUserApi } from "@/services/api/auth";
	import dayjs from "dayjs";
	import { openNotificationWithIcon } from "@/utils";
	import { deleteImage, uploadImage } from "@/services/api/user";
	const { closeCelebritiesDrawer, data } = defineProps(["closeCelebritiesDrawer", "data"]);
	const labelCol = { style: { width: "200px" } };
	const wrapperCol = { span: 14 };
	const newUploads = ref([]);
	const deleteFile = ref([]);

	const formState = reactive<any>({
		fullName: "",
		identifier: "",
		gender: "",
		profession: "",
		rodden: "",
		public: "",
		selectDob: "",
		pob: "",
		tob: "",
		geoCordinate: "",
		credit: "",
		jungian_type: "",
		publicProfileText: "",
		socioregions: "",
		celebrityRegion: "",
	});

	const onFinish = async () => {
		formState.roles = ["demo"];
		formState.dob = `${formState.selectDob.format("YYYY-MM-DD")}T${formState.tob.format("HH:mm:ss.SSS[Z]")}`;
		formState.height = Number((formState.height / 100).toFixed(2));
		const preferences: any = [];
		for (const key in formState) {
			const element = formState[key];
			if (key == "rodden" || key == "public" || key == "profession" || key == "georegion" || key == "height" || key == "jungian_type" || key == "socioregions") {
				preferences.push({ key, value: element, type: typeof element });
				if (key == "socioregions") {
					preferences.push({ type: typeof element, key: "georegion", value: element });
				}
			}
		}
		formState.fullName = formState.fullName.trim();
		const timeStamp = new Date().getTime();
		formState.identifier = `${formState.fullName.replace(/\s/g, "")}${timeStamp}@g.com`;
		const publicCaptions = [formState.credit];
		formState.nickName = formState.fullName;
		await addUserApi({ ...formState, preferences })
			.then(async (res) => {
				newUploads.value.map(async (file: any) => {
					const formDetails = new FormData();
					formDetails.set("file", file);
					await uploadImage(res?.user?._id, formState.credit, formDetails);
				});
				await updateUserApi(res?.user?._id, { publicCaptions, geo: formState.geo, preferences, publicProfileText: formState.publicProfileText });
				const dataForChart = {
					name: formState.nickName,
					gender: formState.gender,
					user: res?.user?._id,
					lat: Number(formState.geo.lat),
					lng: Number(formState.geo.lng),
					alt: 0,
					isDefaultBirthChart: true,
					datetime: formState.dob,
					tzOffset: 0,
					roddenValue: 1000,
					type: "person",
					eventType: "birth",
					_id: "",
					pob: formState.pob,
					celebrityRegion: formState.celebrityRegion,
				};
				await saveUserChart(dataForChart);
				if (res?.message == "User has been created successfully") {
					openNotificationWithIcon("success", res?.message);
					closeCelebritiesDrawer();
				}
			})
			.catch((error) => {});
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log("Failed:", errorInfo);
	};
</script>
